import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiMatchMakeUser = async (userId, authToken) => {
    try {
        const url = `${REACT_APP_BACKEND_API_URL}/match-make/match-make-user`;
        const response = await axios.post(url, { uid: userId }, { headers: { Authorization: `Bearer ${authToken}` } });
        return response?.data;
    } catch (e) {
        throw e?.response?.data ? e?.response?.data : e;
    }
};
