import { UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { apiCompanyOverview } from "api/account-setup/companyOverview";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const CompanyOverviewForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { companyName, setCompanyName, founderRole, setFounderRole } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyOverviewUpdateLoading, setCompanyOverviewUpdateLoading] = useState(false); 
  const [noCompanyName, setNoCompanyName] = useState(false);
  let inputCompanyName = ( companyName ? !noCompanyName : noCompanyName );

  const founderRoleChanged = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderRole(updatedValue);
  };

  const updateCompanyOverview = async () => {
    try {
      setCompanyOverviewUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        company_name: companyName,
        company_role: founderRole
      };
      await apiCompanyOverview(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyOverviewUpdateLoading(false);
    }
  };

  return (
    <>
    <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
    <div className={styles.accountSetupMainTitleContainer}>
      <div className={styles.title}> Company Overview </div>
    </div> 

    <ProgressBar currentForm="02"/>

    <div className={styles.accountSetupMainFormContainer}>
      <div className={styles.subtitle}> What is the name of your company? </div>
      <div className={styles.inputContainer}>
          <Input
            value={companyName}
            onChange={(e) => setCompanyName(e?.target?.value)}
            type="text"
            size="large"
            placeholder="Company Inc."
            prefix={< UserOutlined />}
          />
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          onChange={(e) => setNoCompanyName(e?.target?.checked)}
          checked={noCompanyName}
        />
      </div>
      <div className={styles.checkBoxText}>I do not currently own a company</div>
      <div className={styles.subtitle}> What Is Your Role? </div>
      <div className={styles.instructionText} style={{marginTop:"5px"}}>Check all that apply.</div>
      <br></br>
      <Checkbox.Group style={{ display: 'block', width: "100%" , padding: 5}} onChange={founderRoleChanged} value={[founderRole]}> 
        <Row>
          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Founder" autoFocus={true} ></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Founder</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Co-Founder" autoFocus={true} ></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Co-Founder</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Chief Executive Officer" autoFocus={true} ></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Chief Executive Officer</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Chief Technology Officer" autoFocus={true} ></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Chief Technology Officer</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Other" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Other:</span>
          </Col>
          <Col span={1}></Col>
        </Row>
      </Checkbox.Group>
      
      <br></br>
      <div className={styles.inputContainer}style={{marginLeft:"15%"}}>
          <Input
            onChange={(e) => setFounderRole(e?.target?.value)}
            type="email"
            size="large"
            placeholder=""
            prefix={null}
          />
          {/* <div>{emailError}</div> */}
      </div>
      <div style={{height: "150px", marginTop: "40px"}}></div>
      <Button
        type="primary"
        size="large"
        onClick={updateCompanyOverview}
        loading={companyOverviewUpdateLoading}
        disabled={companyOverviewUpdateLoading || !inputCompanyName || !founderRole}
        style={{height:"70px", width:"50vw", position: "absolute", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
      >
        <div className={styles.buttonText}> Next </div>
      </Button>
    </div>
    </>
  );
};

export default CompanyOverviewForm;
