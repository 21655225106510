import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';

const ResponseWrapper = styled.div`
  height: 56px;
  width: 360px;
  margin: 5px;
  position: relative;
`;

const Text = styled.span`
  color: ${theme.primary};
  position: relative;
  top: 25px;
`;
const useStyles = makeStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: '#b9b9b9',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#b9b9b9',
    },
    height: '30px',
    width: '340px',
    backgroundColor: theme.background,
    color: '#b9b9b9',
  },
}));

function MultipleInput({ maxLength }) {
  const { setHomeBaseSendToggle, setHomeBaseMessage } = useContext(Context);

  const classes = useStyles();

  const [lists, setLists] = useState(['']);

  const _onAddList = () => {
    let newLists = [...lists, ''];
    setLists(newLists);
    setTimeout(() => {
      document.getElementById(newLists.length - 1).focus();
    }, 10);
  };

  const _onRemoveList = () => {
    let newLists = lists.slice(0, lists.length - 1);
    setLists(newLists);
    setTimeout(() => {
      document.getElementById(newLists.length - 1).focus();
    }, 10);
  };

  const handleChange = (e) => {
    let temp = [...lists];
    temp[parseInt(e.target.name)] = e.target.value;
    setLists(temp);
    if (temp.filter((x) => x === '').length === 0) {
      setHomeBaseSendToggle(true);
    }
    setHomeBaseMessage({ contents: JSON.stringify(lists), type: 'List' });
  };

  useEffect(() => {
    document.getElementById('0').focus();
  }, []);

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {lists.map((content, i) => {
          return (
            <ResponseWrapper key={i}>
              <Text
                style={{
                  color: theme.primary,
                  position: 'relative',
                  top: '25px',
                }}
              >
                {i + 1}
              </Text>
              &nbsp;
              <TextField
                label="Write your response"
                onChange={handleChange}
                name={i}
                id={i}
                type="text"
                value={content}
                classes={classes}
              />
              {i === lists.length - 1 && lists.length > 1 ? (
                <IconButton
                  onClick={_onRemoveList}
                  style={{ position: 'absolute', top: '5px', right: '0px' }}
                >
                  <RemoveCircleOutlineIcon style={{ color: theme.primary }} />
                </IconButton>
              ) : null}
            </ResponseWrapper>
          );
        })}
        {maxLength !== lists.length ? (
          <IconButton onClick={_onAddList}>
            <AddCircleOutlineIcon style={{ color: theme.primary }} />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
}

export default MultipleInput;
