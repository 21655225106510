import FormFreeText from './FormFreeText';
import FormToggleButtonMultiple from './FormToggleButtonMultiple';
import data from './Questions.json';
import { BoldFont, HorizontalDivider, LeftCenterContainer, Paragraph, Question, TitleLine2 } from "./style";

const FormQuestion = ({ stepNumber, flag, setFlag }) => {
  const category = data.Questions[stepNumber - 1].category
  return (
    <LeftCenterContainer>
      {category !== "Legal" && <BoldFont><TitleLine2> {category} </TitleLine2></BoldFont>}
      {category === "Legal" && <BoldFont><TitleLine2> Legal </TitleLine2></BoldFont>}
      <br />
      {data?.Questions?.[stepNumber - 1]?.title ?
        <Paragraph>{data.Questions[stepNumber - 1].title}</Paragraph> :
        <Paragraph>Please respond to the questions in relation to your company's {category} so we can conduct an analysis.</Paragraph>
      }
      <br />
      <HorizontalDivider />
      <br />
      <br />
      <Question> {data.Questions[stepNumber - 1].body} </Question>
      <br />
      <br />
      {stepNumber < 6 && <FormToggleButtonMultiple choice={data.Questions[stepNumber - 1].choice} flag={flag} setFlag={setFlag} />}
      {stepNumber === 6 && <FormFreeText flag={flag} setFlag={setFlag} />}
    </LeftCenterContainer>
  );
}

export default FormQuestion;
