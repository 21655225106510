import styled from 'styled-components/macro';
import ManagingTaxesCase1 from "../../../assets/image/diagnostic_welcome/ManagingTaxesCase1.svg";
import ManagingTaxesCase2 from "../../../assets/image/diagnostic_welcome/ManagingTaxesCase2.svg";
import ManagingTaxesCase3 from "../../../assets/image/diagnostic_welcome/ManagingTaxesCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Finance";
const DiagnosticSub = "(Managing Taxes)";

const DiagnosticTime = "7";

const DiagnosticURL = "";

const VideoURL = "https://www.loom.com/embed/0717a14d1fa54920b45b7550dcd256c4?sid=00ecae7e-91f2-4e91-8fb5-ef14ea9123c0";

const QuoteContent = "\"I feel like I need to plan for taxes each quarter. I don’t need to wait until the end of the year, I have to get ahead of things and should be meeting with my accountant more regularly letting her know what our expectations on income are and how we need to allocate that income to minimize our tax liability at the end of the year.\"";

const ScrollTitleText = "Managing Taxes";

const ScrollContent = "Managing taxes shouldn’t be tough, but it can be and more times than not, it is. The best way to be prepared is to stay prepared. This Diagnostic walks you through different considerations that you may or may not have considered in relation to taxes and tax planning.\n\n\
Should it be to connect them to accountants?\n\n\
Should it be to list out documents they don’t have and educate them on them?\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const TaxPlanning = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const TaxAdvantages = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const TaxFramework = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how pre-emptive tax planning can help businesses.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='ManagingTaxesCase1' src={ManagingTaxesCase1} width={100} style={{margin: "auto"}}/>
          <a href={TaxPlanning}>Download <br/> 'Tax Planning'</a>
        </Case>
        <Case>
          <img alt='ManagingTaxesCase2' src={ManagingTaxesCase2} width={100} style={{margin: "auto"}}/>
          <a href={TaxAdvantages}>Download <br/> 'Tax Advantages'</a>
        </Case>
        <Case>
          <img alt='ManagingTaxesCase3' src={ManagingTaxesCase3} width={100} style={{margin: "auto"}}/>
          <a href={TaxFramework}>Download <br/> 'Tax Framework'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function FinanceManagingTaxesDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName}
    DiagnosticSub = {DiagnosticSub}
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollTitleText={ScrollTitleText} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
