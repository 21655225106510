import styles from "./index.module.scss";

interface IProps {
  agendas: string[];
  currentSelectedAgendaIndex: number;
  updateCurrentSelectedAgendaIndex: (selectedAgenda: number) => void;
  isHost: boolean;
}

const AgendaProgressBarComponent = ({ agendas, currentSelectedAgendaIndex, updateCurrentSelectedAgendaIndex, isHost }: IProps) => {
  return (
    <div className={styles.accountSetupMenu}>
      <div className={styles.accountSetupMenuContainer}>
        {agendas.map((currentAgenda, index) =>
          <div key={`agenda-${index}`}
            style={{ gap: 25, cursor: `${isHost ? 'pointer' : 'not-allowed'}` }}
            className={`f-d-flex f-py-8 ${styles.accountSetupMenuItem} ${currentSelectedAgendaIndex >= index && styles.active}`}
            onClick={() => updateCurrentSelectedAgendaIndex(index)}
          >
            <span className={`${styles.accountSetupMenuDot}`}>&nbsp;</span>
            <span className={styles.accountSetupMenuTitle}>
              {currentAgenda}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgendaProgressBarComponent;
