import React, { useContext } from 'react';
import { Context } from '../../../providers/Context';
import theme from '../../../core/theme';
import * as font from '../../../core/font';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DeliverablesSVG } from '../../../assets/image/deliverablesIcon.svg'
import { ReactComponent as LinkArrowSmallSVG } from '../../../assets/image/linkArrowSmall.svg'
import { StartButton } from '../../../GlobalComponents'


const Video = styled.video`
    border-radius: 10px;
    width: 500px;
`

const Subtitle = styled.h3`
    font-family: ${font.WorkSansSemiBold};
    font-weight: bold;
    font-size: 16;
`

const DeliverablesTitle = styled.h2`
    font-family: ${font.WorkSansSemiBold};
    font-size: 18;
    opacity: 31%;
    margin: 15px;
`

const Content = styled.p`
    font-family: ${font.WorkSansMedium};
    font-size: 16;
    color: ${theme.primary};
`

const DeliverablesContent = styled.p`
    font-family: ${font.CircularTTMedium};
    font-size: 12;
    opacity: 80%;
`

const DeliverablesUl = styled.ul`
    margin-left: 10px;
`

const Li = styled.li`
font-family: ${font.CircularTTMedium};
font-size: 14;
margin: 10px;
color: ${theme.primary};
`

const StyledLink = styled(NavLink)`
color: ${theme.primary};
&:hover {
    color: ${theme.primary}
}
`

const LinkArrowSmallIcon = styled(LinkArrowSmallSVG)`
    position: relative;
    top: 1px;
    left: 10px;
`

const DeliverablesLi = ({ children, url, infoState }) => {




    return (
        <Li><StyledLink to={{ pathname: url, state: infoState }} isActive={(match, location) => {
            if (!match) return false;
            if (location.state?.deliverable === infoState.deliverable) {
                return true
            } else {
                return false
            }
        }}
            replace
            activeStyle={{ color: theme.disabled }}
        >{children}<LinkArrowSmallIcon /></StyledLink></Li>
    )
}

const InfoLink = styled(NavLink)`
    font-family: ${font.WorkSansSemiBold};
    font-size: 14;
    color: black;
    &:hover {
        color: black
    }
`

const FAQ = styled.span`
    font-family: ${font.WorkSansSemiBold};
    font-weight: bold;
    font-size: 16;
    color: ${theme.disabled};
`
const Divider = styled.div`
  border-top: 1px solid #b9b9b9;
  margin: 15px;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export default function InfoPane({ workshopData }) {
    // roadmap in context to preserve the state of the view before pane switch 
    const { setRoadmapPathPushHistory } = useContext(Context)

    function startInvite() {
        setRoadmapPathPushHistory("/office/roadmap/workshop/invite")
    }

    return (
        <>
            <Video controls src={workshopData.videoURL} />
            <Subtitle>Average Time</Subtitle>
            <Content>{workshopData.averageTime}</Content>
            <Subtitle>Average Feeling</Subtitle>
            <Content>{workshopData.averageFeeling}</Content>
            <Divider />
            <FlexContainer>
                <DeliverablesSVG />
                <DeliverablesTitle>Deliverables</DeliverablesTitle>
            </FlexContainer>
            <DeliverablesContent>{workshopData.deliverables.text}</DeliverablesContent>
            <DeliverablesUl>
                {workshopData.deliverables.links.map((link, i) => {
                    return (
                        <DeliverablesLi key={"deliverables" + i} url={link.link} infoState={link.fullInfo}>{link.text}</DeliverablesLi>
                    )
                })}

            </DeliverablesUl>
            <Divider />
            <InfoLink to={{ pathname: "/office/roadmap/workshop/infopane/FAQ", state: workshopData.FAQ }} activeStyle={{ color: theme.disabled }} replace>See the workshop FAQ</InfoLink>
            <br />
            <div >

                <FAQ style={{ position: "relative", top: "35px", left: "20%" }}>Get started and invite other team members in for the workshop</FAQ>

                <StartButton onClick={startInvite} />
            </div>
        </>
    )
}