import { Button } from "antd";
import { IFormStep } from "interface";
import congratsSignPage from '../../../../assets/image/congratsSignPage.png';
import logoDark from '../../../../assets/image/logoDark.png';
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
}

const CongratulationFormComplete = ({ currentForm }: IProps) => {
  let url = "https://testflight.apple.com/join/r8xeaA9l"

  return (
    <>
    <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
    <img src={congratsSignPage} alt="fixme" style={{marginLeft: "3%", width: "95%"}}/>
    <div className={styles.accountSetupMainFormContainer}>
      <br></br>
      <Button
        type="primary"
        size="large"
        onClick={() => { window.location.href = url; } }
        style={{height:"70px", width:"308px", marginTop:"50px", marginLeft:"10%", marginBottom:"8%", borderRadius:"18px"}}
      >
        <div className={styles.buttonText}> Download Mobile App </div>
      </Button>
    </div>
    </>
  );
};

export default CongratulationFormComplete;
