import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import { auth, database } from '../../../firebase/firebase';
import { Context } from '../../../providers/Context';
import jsonQuestions from '../../../questions/beta.json';
import MsgGroup from '../Components/MsgGroup';
import ActionButtons from './ActionButtons';
import InvitePartner from './InvitePartner';
import MakeRecording from './MakeRecording';
import MessagesContainer from './MessagesContainer';
import MessengerInput from './MessengerInput';

const Header = styled.div`
  background-color: ${theme.background};
  margin: 0 -20px;
  padding: 5px;
`;

const MsgContainer = styled.div`
  padding: 0px 20px 20px 20px;
  position: relative;
`;

const Inputs = styled.div``;

const TopFade = styled.div`
  width: 90%;
  height: 30px;
  position: absolute;
  top: 68.99px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
`;

const BottomFade = styled.div`
  width: 90%;
  height: 30px;
  position: absolute;
  bottom: 98px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
`;

export default function MessengerPane() {
  const { messengerState } = useContext(Context);
  const [inputType, setInputType] = useState('text'); // text | audio | video | attachment | picture
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayInvitePartner, setDisplayInvitePartner] = useState(false);

  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const [curQID, setCurQID] = useState(-1);
  const [options, setOptions] = useState([]);
  const [maxLength, setMaxLength] = useState(-1);
  const [respType, setRespType] = useState('');
  const [category, setCategory] = useState('Onboarding');
  const [goal1, setGoal1] = useState('');
  const [goal2, setGoal2] = useState('');
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoading(true);
        // Firebase call, not connected yet...
        let _msgs = [];
        const snapshot = await database
          .ref('/Homebase/' + auth.currentUser.uid)
          .orderByChild('SentDate')
          .once('value');
        snapshot.forEach((msg) => {
          _msgs.push({ id: msg.key, data: msg.val() });
        });
        console.log('Messages from Firebase', _msgs);

        // Set goals if stored from previous messenger flow
        //TODO: This needs to be reworked and stored in Firebase instead. Both here and in mobile, so that people can switch between platforms
        const value1 = await localStorage.getItem('@Input1');
        if (value1 !== null && componentIsMounted.current) {
          setGoal1(value1);
        }
        const value2 = await localStorage.getItem('@Input2');
        if (value2 !== null && componentIsMounted.current) {
          setGoal2(value2);
        }

        // if (componentIsMounted.current) {
        setQuestions(jsonQuestions.questions);
        setMessages(_msgs);
        setIsLoading(false);
        // }
      } catch (e) {
        alert('Framewrk', 'An error is occured. Please contact the developer.');
        console.log(e);
      }
    };

    loadInitialData();
  }, []);

  const getQuestion = (id) => {
    console.log('Question ID', id);
    let question;
    if (questions.length) {
      question = questions.filter((ele) => ele.ID === id)[0];
    } else {
      question = jsonQuestions.questions.filter((ele) => ele.ID === id)[0];
    }
    if (id === 43) {
      question.Question = goal1 + question.Question;
    } else if (id === 56) {
      question.Question = question.Question + goal2;
    }
    return question;
  };
  const getNextQuestion = (id, response) => {
    let currentQuestion = getQuestion(id);
    let nextQuestionIDs = currentQuestion.NextQuestionID;
    if (nextQuestionIDs.length === 1) {
      return getQuestion(nextQuestionIDs[0]);
    } else {
      let i = currentQuestion.Options.findIndex((ele) => ele === response);
      return getQuestion(nextQuestionIDs[i]);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (messages.length === 0) {
        askQuestion(getQuestion(1));
      } else {
        console.log('In UseEffect... Messages:', messages);
        const lastMsg = messages[messages.length - 1].data;
        console.log('Last Message:', lastMsg);
        if (lastMsg?.Sender === 'Framewrk') {
          if (lastMsg.Category !== undefined) {
            setCategory(lastMsg.Category);
          }
          if (
            lastMsg.MessageType === undefined ||
            lastMsg.MessageType === 'Video'
          ) {
            let question = getNextQuestion(lastMsg.ID, 0);
            askQuestion(question);
          } else if (lastMsg.MessageType === 'End') {
            const currentQuestion = getQuestion(lastMsg.ID);
            if (currentQuestion.Delay !== undefined) {
              const nextQuestion = getNextQuestion(currentQuestion.ID, 0);
              setTimeout(
                () => askQuestion(nextQuestion),
                currentQuestion.Delay * 60 * 1000
              );
            }
          } else {
            let originalQuestion = getQuestion(lastMsg.ID);
            if (originalQuestion !== undefined) {
              setCurQID(originalQuestion.ID);
              setOptions(originalQuestion.Options);
              setRespType(originalQuestion.ResponseType);
              setMaxLength(originalQuestion.MaxLength);
            }
          }
        } else {
          let question = getNextQuestion(lastMsg.ID, lastMsg.Content);
          askQuestion(question);
        }
      }
    }
  }, [messages, isLoading]);

  const askQuestion = (question) => {
    if (question === undefined) {
      return;
    }

    console.log('Asking Question:', question);

    if (question.Category !== undefined && questions.Category !== category) {
      setCategory(question.Category);
    }

    setIsTyping(true);
    setTimeout(() => {
      let body = {
        ID: question.ID,
        Content: question.Question,
        MessageType: question.ResponseType,
        VideoURL: question.VideoURL,
        Category: question.Category,
        Sender: 'Framewrk',
        SentDate: Date.now(),
      };

      let msgID = _addMessageToDatabase(body);
      setCurQID(question.ID);
      setRespType(question.ResponseType);
      setOptions(question.Options);
      setIsTyping(false);
      setMessages((prev) => prev.concat({ id: msgID, data: body }));
    }, 2500);
  };

  const _addMessageToDatabase = (body) => {
    // TODO: Comment in once we have Firebase
    if (!auth.currentUser) {
      return;
    }
    if (body.Users === undefined) {
      delete body.Users;
    }
    if (body.MessageType === undefined) {
      delete body.MessageType;
    }
    if (body.Category === undefined) {
      delete body.Category;
    }
    if (body.VideoURL === undefined) {
      delete body.VideoURL;
    }
    const msgRef = database.ref('/Homebase/' + auth.currentUser.uid).push(body);
    return msgRef.key;

    // TODO: Delete this line once we have Firebase
    // return body.ID;
  };

  const _onSendMessage = (id, text, responseType) => {
    const cur = getQuestion(id);
    // TODO: Switch to commented out code once we have Firebase auth
    let body = {
      ID: id,
      Content: text,
      MessageType: responseType,
      Sender: auth.currentUser.uid,
      Users: {
        [auth.currentUser.uid]: {
          time: Date.now(),
        },
      },

      // TODO: Delete once we have Firebase
      // Sender: 3,
      // Users: {
      //   3: {
      //     time: Date.now(),
      //   },
      // },
      // ====
      SentDate: Date.now(),
      Category: cur.Category,
    };
    if (id === 41) {
      // save input 1, and input 2
      const projects = JSON.parse(text);
      try {
        localStorage.setItem('@Input1', projects[0]);
        localStorage.setItem('@Input2', projects[1]);
        setGoal1(projects[0]);
        setGoal2(projects[1]);
      } catch (e) {
        console.log('HomebaseContent.jsx - setGoal to localStorage', e);
      }
    } else if (id === 53 && text === 'Invite Now') {
      setDisplayInvitePartner(true);
    }
    let msgID = _addMessageToDatabase(body);
    setMessages((prev) => prev.concat({ id: msgID, data: body }));
  };

  // Leaving this here in case we want to add in a feature to view your progress like in the mobile app
  const getProgress = () => {
    let total = questions.filter((ele) => ele.Category === category).length;
    let cur = messages.filter(
      (ele) => ele.data.Category === category && ele.data.Sender === 'Framewrk'
    ).length;
    return Math.min(Math.round((cur * 100) / total), 100);
  };

  const MessagesOrMultiMediaInput = (inputType) => {
    switch (inputType) {
      case 'audio':
        return (
          <MakeRecording
            messages={messages}
            setMessages={setMessages}
            setInputType={setInputType}
          />
        );
      default:
        return (
          <MessagesContainer
            isTyping={isTyping}
            userID={auth?.currentUser?.uid}
            messages={messages}
            options={options}
            _onSendMessage={_onSendMessage}
            id={curQID}
            maxLength={
              maxLength === undefined ? Number.MAX_SAFE_INTEGER : maxLength
            }
            users={[auth?.currentUser]}
          />
        );
    }
  };

  return (
    <MsgContainer>
      <Header>
        <MsgGroup {...messengerState} pointer={false} />
      </Header>
      <TopFade />
      {MessagesOrMultiMediaInput(inputType)}
      <BottomFade />
      <Inputs>
        <MessengerInput
          setInputType={setInputType}
          _onSendMessage={_onSendMessage}
          id={curQID}
          responseType={respType}
        />
        <ActionButtons inputType={inputType} setInputType={setInputType} />
      </Inputs>
      {displayInvitePartner && (
        <InvitePartner setDisplayInvitePartner={setDisplayInvitePartner} />
      )}
    </MsgContainer>
  );
}
