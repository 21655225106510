import { ToggleButtonContainerMultiple, ToggleButtonTextMultiple, ToggleJoinButtonMultiple } from "./style";

const FormMultipleToggleButton = ({ choice, flag, setFlag }) => {
  return (  
    <ToggleButtonContainerMultiple>
        { choice.map((option, i) => (
          <ToggleJoinButtonMultiple onClick={() => setFlag(i)} selected={flag===i}>
            <ToggleButtonTextMultiple selected={flag===i}>
              { option }
            </ToggleButtonTextMultiple>
          </ToggleJoinButtonMultiple>
        ))}
      </ToggleButtonContainerMultiple>
  );
}
 
export default FormMultipleToggleButton;
