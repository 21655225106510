import { Carousel } from 'antd';
import SignInPhone1 from 'assets/image/signin-phone1.png';
import SignInPhone2 from 'assets/image/signin-phone2.png';
import SignInPhone3 from 'assets/image/signin-phone3.png';
import SignInPhone4 from 'assets/image/signin-phone4.png';
import styles from './index.module.scss';

const LeftSideDetailComponent = () => {
  return (
    <div className={styles.leftSideDetailComponent}>
      <Carousel dotPosition='bottom' autoplay={true} autoplaySpeed={20000} dots={{ className: styles.sliderDots }}>
        <div>
          <div className={styles.sliderCardContainer}>
            <img src={SignInPhone1} alt='phone1' className={styles.sliderCardImage} />
            <div className={styles.sliderCardMainTitle}>Knowledge Community <br /> and Discussion Forums</div>
            <div className={styles.sliderCardDesc}>
              Connect with an exclusive community of <br /> like-minded founders, where you brainstorm solutions, <br />share experiences, and forge lasting connections.
            </div>
          </div>
        </div>

        <div>
          <div className={styles.sliderCardContainer}>
            <img src={SignInPhone2} alt='phone2' className={styles.sliderCardImage} />
            <div className={styles.sliderCardMainTitle}>Mastermind Groups and <br /> Weekly Meetings</div>
            <div className={styles.sliderCardDesc}>
              Consistency is key. Our Mastermind Groups connects you <br /> with other entrepreneurs to meet weekly via video.
            </div>
          </div>
        </div>

        <div>
          <div className={styles.sliderCardContainer}>
            <img src={SignInPhone3} alt='phone3' className={styles.sliderCardImage} />
            <div className={styles.sliderCardMainTitle}>Business <br /> Diagnostics</div>
            <div className={styles.sliderCardDesc}>
              Uncover your startup's weaknesses and unleash your strengths <br /> through nine different themed diagnostics from Marketing to Legal. These <br /> audits assess your business and provide specific suggestions to help you grow.
            </div>
          </div>
        </div>

        <div>
          <div className={styles.sliderCardContainer}>
            <img src={SignInPhone4} alt='phone4' className={styles.sliderCardImage} />
            <div className={styles.sliderCardMainTitle}>AI-Powered Consultant, AVA</div>
            <div className={styles.sliderCardDesc}>
              Tap into the power of your very own AI consultant, dedicated <br /> specifically to your business and goals; offering personalized <br /> advice, providing recommendations, and resources.
            </div>
          </div>
        </div>
      </Carousel>
    </div >
  );
};

export default LeftSideDetailComponent;
