import { Button, Checkbox, Col, Row } from "antd";
import { apiCompanyStage } from "api/account-setup/companyStage";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const CompanyStageForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { companyStage, setCompanyStage } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyStageUpdateLoading, setCompanyStageUpdateLoading] = useState(false);

  const companyStageChanged = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setCompanyStage(updatedValue);
  };

  const updateCompanyStage = async () => {
    try {
      setCompanyStageUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        stages: companyStage,
      };
      await apiCompanyStage(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyStageUpdateLoading(false);
    }
  };

  return (
    <>
    <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
    <div className={styles.accountSetupMainTitleContainer}>
      <div className={styles.title}> Company Stage </div>
    </div>

    <ProgressBar currentForm="03"/>

    <div className={styles.accountSetupMainFormContainer}>
      <div className={styles.subtitle}> What stage is your company? </div>
      <div className={styles.instructionText} style={{marginTop:"5px"}}> We use this information to create your Mastermind Group! </div>
      <br></br>
      <Checkbox.Group style={{ display: 'block', width: "100%" , padding: 5}} onChange={companyStageChanged} value={[companyStage]}> 
        <Row>
          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Ideation" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Ideation</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Validation" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Validation</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Operational" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Operational</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Growth" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Growth</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Establishing" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Establishing</span>
          </Col>
          <Col span={1}></Col>

          <Col span={1}>
            <div className={styles.checkBoxContainer}>
              <Checkbox value="Exiting" autoFocus={true}></Checkbox>
            </div>
          </Col>
          <Col span={22}>
              <span className={styles.checkBoxText}>Exiting</span>
          </Col>
          <Col span={1}></Col>
        </Row>
      </Checkbox.Group>

      <div style={{height: "150px", marginTop: "40px"}}>
      <Button
        type="primary"
        size="large"
        onClick={updateCompanyStage}
        loading={companyStageUpdateLoading}
        disabled={companyStageUpdateLoading || !companyStage}
        style={{height:"70px", width:"50vw", position: "absolute", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
      >
        <div className={styles.buttonText}> Next </div>
      </Button>
      </div>
    </div>
    </>
  );
};

export default CompanyStageForm;
