import React from 'react';
import styled from 'styled-components/macro';
import theme from '../../core/theme';

const ProgressLineContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  width: 100%;
`

const ProgressLine = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  width: 90%;
  background: #DCD7C8;
  height: 5px;
  z-index: -1;
  display: flex;
  justify-content: space-between;
`

const Status = styled.div`
  position: relative;
  top: -9.5px;
`

const ProgressNext = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #DCD7C8;
  margin-top: 5px;
`

const ProgressCurrent = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-top: 4px;
  background: white;
  border: 5px solid ${theme.primary};
`

const ProgressCompleted = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-top: 4px;
  background: ${theme.primary};
`

export function Progress(stepNumber, totalSteps) {

  const getProgressLine = () => {
    const percent = Math.min((stepNumber*100/(totalSteps-1)), 100).toString()+'%'
    const Progress = styled.div`
      position: absolute;
      width: ${percent};
      height: 100%;
      margin-left: 2px;
      background: ${theme.primary};
      transition: width 1s ease-in-out;
    `
    return <Progress />
  }

  const ProgressPoints = () => {
    return [...Array(totalSteps)].map((_, i) => {
      return <Status>
        {ProgressPoint(i)}
      </Status>
    })
  }

  const ProgressPoint = (checkPointNumber) => {
    if (stepNumber > checkPointNumber) {
      return <ProgressCompleted />
    } 
    else if (stepNumber === checkPointNumber) {
      return <ProgressCurrent/>
    }
    return <ProgressNext/>
  }

  return <ProgressLineContainer>
    <ProgressLine >
    {getProgressLine()}

    {ProgressPoints()}
  </ProgressLine>
</ProgressLineContainer>
} 
