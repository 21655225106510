import { Button, Checkbox, Col, Input, Row } from "antd";
import { apiCompanyDetails } from "api/account-setup/companyDetail";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import GenericProgressBar from "screens/GenericProgressBar";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const CompanyOverviewForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { companyName, setCompanyName, companyRole, setCompanyRole, othersCompanyRole, setOthersCompanyRole } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderCompanyDataUpdateLoading, setFounderCompanyDataUpdateLoading] = useState(false);
  const [isOtherChecked, setIsOtherChecked] = useState(false);

  const founderRoleUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues;
    if (updatedValue[updatedValue.length-1]==='Other') {
      setIsOtherChecked(true)
    }
    setCompanyRole(updatedValue);    
  };

  const updateCompanyDetails = async () => {
    try {
      setFounderCompanyDataUpdateLoading(true);
      if (othersCompanyRole) {
        companyRole.pop()
        companyRole.push(othersCompanyRole)
      }
      const payload = {
        uid: currentUser?.uid,
        company_role: companyRole,
        company_name: companyName
      };
      await apiCompanyDetails(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setFounderCompanyDataUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainFormContainer}>
        <div className={`${styles.formInputContainer} ${styles.formTextInput} ${styles.formCheckboxInput}`}>
          <GenericProgressBar currentStep={2} totalSteps={9} pageTitle="Company Overview" />
          <div className={styles.formQuestionText}>What is the name of your company?</div>
          <Input
            className={`${styles.companyNameInputBox}`}
            placeholder="Write your response here"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e?.target?.value)
            }}
            autoFocus={true}
          />

          <div className={styles.formQuestionText}>What is your role?</div>
          <div className={styles.formQuestionDetail}>Check all that apply.</div>
          <Checkbox.Group style={{ width: "100%" }} value={companyRole} onChange={founderRoleUpdated}>
            <Row>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Solo Founder" autoFocus={true}></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Solo Founder</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Co-Founder"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Co-Founder</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Chief Executive Officer"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Chief Executive Officer</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Chief Technology Officer"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Chief Technology Officer</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Other"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Other</span>
                <Input
                  className={`${styles.companyNameInputBox}`}
                  placeholder="Write your response here"
                  disabled={!isOtherChecked}
                  value={othersCompanyRole}
                  onChange={(e) => {
                    setOthersCompanyRole(e?.target?.value)
                  }}
                />
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateCompanyDetails}
            loading={founderCompanyDataUpdateLoading}
            disabled={!companyName || companyRole.length===0 || founderCompanyDataUpdateLoading}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>

          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigateToFormStep(currentForm, "next")}
            loading={founderCompanyDataUpdateLoading}
            disabled={founderCompanyDataUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyOverviewForm;
