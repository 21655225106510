import { BoldFont, GreenFont, HorizontalDivider, TitleLine1, TitleLine2 } from './style';

const ThankYouPage = () => {
  return (
    <>
      <BoldFont>
        <GreenFont> <TitleLine1> Thank You! </TitleLine1> </GreenFont>
        <TitleLine2> Competitors Diagnostic </TitleLine2>
        <br />
      </BoldFont>
      <text>
        Our team of consultants is about to dive into your responses, analyze your business, what makes you different, and your competitors. This all for the sake of making sure your business can stand the test of time against the others. We'll be in touch asap so in the meantime, please be sure to complete the other Diagnostics and engage in the Discussion Forums.
      </text>
      <br />
      <br />
      <text>
        Then we'll see what legal counsel we can refer you to if you'd like to have documentation reviewed or if you need support on more direct legal matters.
      </text>
      <br />
      <br />
      <HorizontalDivider></HorizontalDivider>
    </>
  );
}

export default ThankYouPage;
