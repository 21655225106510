import styled from "styled-components/macro";
import contractIcon from "../../../assets/image/Contract.svg";
import expandIcon from "../../../assets/image/Expand.svg";
import dmIcon from "../../../assets/image/dmIcon.svg";
import MsgGroup from "./MsgGroup";

const FoldoutGroup = styled.div`
  height: 60px;
  padding-left: 30px;
  padding-top: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  &:hover {
    background-color: #faf2e1;
    cursor: pointer;
    transition-delay: 0.08s;
  }
`;

const Icons = styled.img`
  margin-right: 10px;
  height: auto;
  width: 30px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
`;

const DirectMessagesContainer = styled.div`
  margin-left: 80px;
`;

const TitleText = styled.div`
    padding-top: 8px;
    margin-top: 6px;
`;

function DirectMessages({ messages, expanded, setExpanded }) {
  return (
    <div>
      <FoldoutGroup onClick={() => setExpanded(!expanded)}>
        <Title>
          <Icons src={dmIcon} />
          <TitleText>
            <h3>Direct Message</h3>
            <p>Message consultants or other clients.</p>
          </TitleText>
        </Title>
        <Icons src={expanded ? contractIcon : expandIcon} />
      </FoldoutGroup>
      <DirectMessagesContainer>
        {expanded
          ? messages.map((ele, i) =>
            <MsgGroup id={ele.id}
              users={ele.data.uIDs}
              roomColor={ele.data.RoomColor}
              chatType={ele.data.ChatType}
              key={"msgGroup" + i}
            />)
          : null}
      </DirectMessagesContainer>
    </div>
  );
}

export default DirectMessages;
