import { IFormStep } from "interface";
import { useState } from "react";
import { SignUpForms } from "utils/constants";
import BirthdateForm from "./SignUpForms/BirthdateForm";
import CompanyIndustryForm from "./SignUpForms/CompanyIndustryForm";
import CompanyOverviewForm from "./SignUpForms/CompanyOverviewForm";
import CompanyStageForm from "./SignUpForms/CompanyStageForm";
import CongratsComplete from "./SignUpForms/CongratsComplete";
import GenderIdentityForm from "./SignUpForms/GenderIdentityForm";
import MailingAddressForm from "./SignUpForms/MailingAddressForm";
import RaceEthnicityForm from "./SignUpForms/RaceEthnicityForm";
import SignUp from "./SignUpForms/SignUp";
import styles from "./index.module.scss";

const SignUpMobile = () => {
  const [currentStep, setCurrentStep] = useState<IFormStep>(SignUpForms.STEP1);
 
  const navigateToFormStep = (currentForm: IFormStep, direction: "next" | "prev") => {
    if (direction === "next" && currentForm?.nextStep) {
      const formValueArrary = Object.values(SignUpForms);
      const nextStep = formValueArrary.find((value: IFormStep) => value?.index === currentForm?.nextStep);
      setCurrentStep(nextStep);
    } else if (direction === "prev" && currentForm?.prevStep) {
      const formValueArrary = Object.values(SignUpForms);
      const prevStep = formValueArrary.find((value: IFormStep) => value?.index === currentForm?.prevStep);
      setCurrentStep(prevStep);
    }
  };

  return (
    <div className={styles.accountSetup}>
      <div className={styles.accountSetupMainSection}>
        {/* STEP1 */}
        {currentStep?.index === "01" && (
          <SignUp currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP2 */}
        {currentStep?.index === "02" && (
          <CompanyOverviewForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )} 

        {/* STEP3 */}
        {currentStep?.index === "03" && (
          <CompanyStageForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP4 */}
        {currentStep?.index === "04" && (
          <CompanyIndustryForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP5 */}
        {currentStep?.index === "05" && (
          <GenderIdentityForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP6 */}
        {currentStep?.index === "06" && (
          <RaceEthnicityForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP7 */}
        {currentStep?.index === "07" && (
          <BirthdateForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP8 */}
        {currentStep?.index === "08" && (
          <MailingAddressForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
        )}

        {/* STEP9 */}
        {currentStep?.index === "09" && (
        <CongratsComplete currentForm={currentStep} />
        )}
      </div>
    </div> 
  );
};

export default SignUpMobile;
