import { useDrag } from 'react-dnd';
import styled from 'styled-components/macro';
import { ReactComponent as CloseSVG } from '../../../assets/image/close.svg';
import theme from '../../../core/theme';

const OuterSubDivisionBox = styled.div`
  width: 120px;
  height: 120px;
  background: ${theme.backgroundColor};
  display: inline-flex;
  position: relative;
`;

const SubDivisionBox = styled.form`
  width: 100px;
  height: 100px;
  background: white;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export default function SubDivision({ subDivisions, setSubDivisions, subDivisionKey }) {
  const [, drag] = useDrag(() => ({
    type: 'Sub Divisions',
    item: { 'memberKey': subDivisionKey },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const handleChange = (key, event) => {
    setSubDivisions({ ...subDivisions, [key]: event.target.value })
  }
  const handleClick = (key) => {
    let newSubDivisions = { ...subDivisions }
    delete newSubDivisions[key]
    setSubDivisions(newSubDivisions)
  }
  return <OuterSubDivisionBox >
    <SubDivisionBox ref ={drag}>
      <textarea placeholder='Type sub-division name here' value={subDivisions[subDivisionKey]} onChange={(event) => handleChange(subDivisionKey, event)} />
    </SubDivisionBox>
    <button style={{ backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: '0px', right: '20px' }} type="button" onClick={() => {
      handleClick(subDivisionKey)
    }}> <CloseSVG /> </button>
  </OuterSubDivisionBox>
}
