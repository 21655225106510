import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as sendSvg } from '../../../assets/image/sendIcon.svg';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';

const SendIcon = styled(sendSvg)`
  & #Rectangle_46 {
    fill: ${(props) => (props.enabled ? theme.secondary : theme.disabled)};
    ${(props) => (props.enabled ? null : `opacity: 38%`)};
  }
  ${(props) =>
    props.enabled
      ? `filter: drop-shadow(0px 0px 5px ${theme.secondary})`
      : null};
`;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '36px',
    alignItems: 'center',
    borderRadius: '20px',
    width: '93%',
    backgroundColor: theme.background,
    margin: '10px auto',
  },
  input: {
    marginLeft: '15px',
    flex: 1,
    backgroundColor: theme.background,
    fontFamily: font.CircularTTMedium,
    fontSize: '16px',
  },
  iconButton: {
    padding: 0,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function MessengerInput({
  setInputType,
  _onSendMessage,
  responseType,
  id,
}) {
  const {
    homeBaseTextInputToggle,
    homeBaseSendToggle,
    setHomeBaseSendToggle,
    homeBaseMessage,
    setHomeBaseMessage,
  } = useContext(Context);
  const classes = useStyles();

  const [respType, setRespType] = useState();

  const [input, setInput] = useState('');

  function handleChange(event) {
    let value = event.target.value;
    setHomeBaseSendToggle(value !== '' ? true : false);
    setInput(value);
  }

  useEffect(() => {
    setRespType(responseType);
    if (
      responseType === 'FreeWrite' ||
      JSON.stringify(responseType) === JSON.stringify(['FreeWrite', 'Audio'])
    ) {
      // textRef?.current?.focus();
    }
    // setLists([]);
    // setCurrentList('');
  }, [responseType]);

  function handleSubmit(event) {
    event.preventDefault();

    let message = input || homeBaseMessage.contents;
    let messageType = homeBaseMessage.type === 'Audio' ? 'Audio' : respType;

    _onSendMessage(id, message, messageType);

    setInput('');
    setHomeBaseMessage({ contents: '', type: null });
    setHomeBaseSendToggle(true);
    setInputType('text');
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <InputBase
        disabled={!homeBaseTextInputToggle}
        className={classes.input}
        placeholder="Type Response"
        inputProps={{ 'aria-label': 'Type Response' }}
        value={input}
        onChange={handleChange}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        disabled={(!input && !homeBaseSendToggle) || !homeBaseSendToggle}
        aria-label="send"
      >
        <SendIcon enabled={input || homeBaseSendToggle} />
      </IconButton>
    </form>
  );
}
