export const Reports = () => {

    return <div>
        <h1> Predictive Index Reports </h1>
            <h3> Please check the PDF results below: </h3>
            <ul style={{ marginLeft: '30px'}}>
                <li>Individual Report</li>
                <li>Team Report</li>
                <li>Leadership Report</li>
            </ul>
    </div>
}
