import "@whereby.com/browser-sdk";
import './index.scss';

const WherebyEmbed = (props) => {
    return (
        <whereby-embed {...props} class={`f-video-meeting-whereby-component`} />
    )
}

export default WherebyEmbed
