import styled from 'styled-components/macro';
import theme from '../../../core/theme';

const Background = styled.div`
  background-color: ${theme.background};
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -10;
`;

export default Background;
