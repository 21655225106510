import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from 'styled-components/macro';
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Operations";

const DiagnosticTime = "6";

const DiagnosticURL = "/office/diagnostic/operations/questionnaire";

const VideoURL = "https://www.loom.com/embed/64f9179ce9534993b5c13dcc19f97c25?sid=3fc333c5-3962-46a5-ac37-cd414155a9ea"

const QuoteContent = "\"I feel less chaotic and more organized. While there’s a lot of work to do for my business, I’m more relaxed and better able to manage weekly priorities. Everything feels a bit clearer.\"";

const ScrollContent = "What is the Operations Diagnostic?\n\n\
No matter which stage your business is at, there are three elements that, when they’re mapped out, will give you a better foundation: divisions, processes, and technologies.\n\n\
The Operations Diagnostic walks through each of these areas to get an understanding of how to help you better organize your company’s structure and your workflows so every milestone is a bit easier to reach.";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const AccordionContainer = styled.div`
  width: 80%;
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 60px;
  border-radius: 8px;
  box-shadow: 0px 3px 2px -1px #ded9cc;
`;

const styles = {
  upper: {
    backgroundColor: "#fbf8f0",
    margin: "auto", 
    boxShadow: 'none',
    summary: {
      fontWeight: '600',
      boxShadow: "0px 3px 2px -1px #ded9cc"
    },
    details: {
      fontSize: "13px", 
      fontWeight: "300"
    }
  },
  middle: {
    backgroundColor: "#ffffff",
    margin: "auto", 
    boxShadow: 'none',
    summary: {
      fontWeight: '600',
      boxShadow: "0px 3px 2px -1px #ded9cc"
    },
    details: {
      fontSize: "13px", 
      fontWeight: "300"
    }
  },
  footer: {
    backgroundColor: "#fbf8f0",
    margin: "auto", 
    boxShadow: 'none',
    summary: {
      fontWeight: '600',
      boxShadow: "0px 7px 2px -4px #ded9cc"
    },
    details: {
      fontSize: "13px", 
      fontWeight: "300"
    }
  }
};

function RightBottomComponent () {
  return (
    <AccordionContainer>
      <Accordion style={styles.upper}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.upper.summary}>
          Divisions
        </AccordionSummary>
        <AccordionDetails style={styles.upper.details}>
          Running a business often means having different roles. You’re pulled in so many different directions leading the marketing, hiring team members, managing team members, coordinating the finances, and more. That gets exhausting and without the right structure, it can lead to more and more challenges bubbling up. No matter what stage your business is at, having clear divisions like marketing or finance or legal within your company and seeing how each of them interact with one another creates more organization. This Diagnostic maps out each of your company’s current divisions and will offer suggestions on managing them, help you develop key priorities within them, and support a strategy for how they expand over time.
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.middle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.middle.summary}>
          Processes
        </AccordionSummary>
        <AccordionDetails style={styles.middle.details}>
          Each week comes with its own set of goals to accomplish across different divisions. Oftentimes, by not structuring our weeks and having regular processes, we can get overwhelmed or chaotic and then everything feels hectic. This Diagnostic identifies which weekly processes you have and will make suggestions for either improving those processes or improving how you manage those processes to keep you consistent and reaching milestones.
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.footer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.footer.summary}>
          Technologies
        </AccordionSummary>
        <AccordionDetails style={styles.footer.details}>
          It’s easy to feel the burden of doing “too much.” Technologies help to offset that burden and improve your efficiency. This Diagnostic identifies which technologies you’re using in different categories like communication technologies, productivity technologies, project management technologies, and more. After completing it, Framewrk will make recommendations on new technologies that could improve your workflows or suggest ways to better use the technologies you currently have.
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  );
}

export default function OperationsDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
