import styled from 'styled-components/macro';
import * as font from '../../../../core/font';
import theme from '../../../../core/theme';

const Text = styled.span``

const Step = styled.h3`
  color: ${theme.primary};
  font-size: 20px;
`

const Intro = styled.div`
  width: 100%;
`

const Title = styled.span`
  font-family: ${font.WorkSansSemiBold};
  font-size: 30px;
  padding: 10px 0px;
`

const Step2 = () => {
  const steps = [
    'We find 3-4 other people with the right stuff.',
    'We create a group between everyone.',
    'You Mastermind together, sharing tips to build something great.',
  ];
  return (
    <div>
      <Intro>
        <Step ><strong>2/2</strong> Overview</Step>
        <Title>Here's how it works...</Title>
      </Intro>
      {steps.map((ele, index) => (
        <div style={styles.stepContainer} key={index}>
          <Text style={styles.stepNumber}>{index + 1}</Text>
          <br/>
          <Text style={styles.content}>{ele}</Text>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: { backgroundColor: theme.background, flex: 1 },
  content: { color: "grey", fontSize: 18 },
  intro: { marginVertical: 30, width: '100%' },
  step: {
    color: theme.primary,
    fontSize: '20px',
    marginLeft: '25px',
    padding: '5px',
  },
  stepContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    textAlign: 'center'
  },
  stepNumber: { color: theme.primary, fontSize: '30px', paddingBottom: '10px' },
  title: {
    fontFamily: font.WorkSansSemiBold,
    fontSize: 30,
    marginLeft: 30,
  },
};

export default Step2;
