import { Popover } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as AvaPromptSVG } from '../../../assets/image/avaPrompt.svg';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';

const PromptIcon = styled(AvaPromptSVG)`
  height: 40px;
  width: 40px;
`;

const AvaPromptsContainer = styled.div`
  width: 475px;
  padding: 8px;
  top: 10px;
  background: ${theme.background};
  margin: auto;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 18px;
  color: grey;
  font-size: 13px;
`;

const PromptContainer = styled.button`
  padding: 8px;
  margin-top: 8px;
  width: 100%;
  color: ${theme.primary};
  font-size: 13px;
  text-align: left;
  background: white;
  border-radius: 5px;
`;


const AvaPrompts = ({setInputPrompt}) => {
  let { avaPrompts, isAvaPromptsEnable } = useContext(Context)

  const handleClickPrompt = (e) => {
    setInputPrompt(e.target.innerText)
    setAnchorEl(null);
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (  
    <>
      <Button aria-describedby={id} onClick={handleClick} style={{padding: '0px'}}>
        <PromptIcon/>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography >
          { isAvaPromptsEnable && <AvaPromptsContainer>
            <TitleContainer>
              AVA Suggested Prompts
            </TitleContainer>
            { avaPrompts.map((Prompt) => (
              <PromptContainer onClick={handleClickPrompt}>
                {Prompt.prompt}
              </PromptContainer>
            ))}
          </AvaPromptsContainer> }
          { !isAvaPromptsEnable && <AvaPromptsContainer>
            <TitleContainer>
              AVA Suggested Prompts Disabled
            </TitleContainer>
            </AvaPromptsContainer> }
        </Typography>
      </Popover>
    </>

  );
}
 
export default AvaPrompts;
