import { Button, Checkbox, Col, Input, Row } from "antd";
import { apiFounderGender } from "api/account-setup/founderGender";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import GenericProgressBar from "screens/GenericProgressBar";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const FounderGenderForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { founderGender, setFounderGender, othersGender, setOthersGender } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderGenderUpdateLoading, setFounderGenderUpdateLoading] = useState(false);

  const founderGenderUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderGender(updatedValue);
  };

  const updateFounderGender = async () => {
    try {
      setFounderGenderUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        gender: founderGender === "Other" ? othersGender : founderGender,
      };
      await apiFounderGender(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setFounderGenderUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainFormContainer}>
        <GenericProgressBar currentStep={5} totalSteps={9} pageTitle="Gender Identity" />
        <div className={styles.formQuestionText}>Which best descries your gender identity?</div>
        <div className={styles.formQuestionDetail}>
          Check that apply.
        </div>
        <div className={`${styles.formInputContainer} ${styles.formCheckboxInput}`} style={{ height: "300px" }}>
          <Checkbox.Group style={{ width: "100%" }} value={[founderGender]} onChange={founderGenderUpdated}>
            <Row>
              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Man" autoFocus={true}></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Man</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Female"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Female</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Non-binary"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Non-binary</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Transgender"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Transgender</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Prefer not to respond"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Prefer not to respond</span>
              </Col>
              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Other"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Other</span>
                <Input
                  className={`${styles.companyNameInputBox}`}
                  placeholder="Write your response here"
                  value={othersGender}
                  onChange={(e) => setOthersGender(e?.target?.value)}
                />
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateFounderGender}
            loading={founderGenderUpdateLoading}
            disabled={!founderGender || founderGenderUpdateLoading}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>
          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigateToFormStep(currentForm, "next")}
            loading={founderGenderUpdateLoading}
            disabled={founderGenderUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FounderGenderForm;
