import styled from 'styled-components/macro';
import theme from '../../../core/theme';

export const Background = styled.div`
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: ${theme.background};
    z-index: -100;
`

export const BodyContainer = styled.div`
    display: flex;
    min-height: calc(100vh - 50px);
`

export const LeftContainer = styled.div`
    width: 50%;
    margin-left: 69.5px;
    margin-top: 42.25px;
`

export const RightContainer = styled.div`
    width: 48%;
    margin-top: 100px;
`

export const VerticalDivider = styled.div`
    background-color: #b9b9b9;
    width: 1px;
    margin: 15px;
`;

export const BoldFont = styled.div`
    font-weight: bold;
`;

export const TitleLine1 = styled.div`
    font-size: 20px;
`;

export const TitleLine2 = styled.div`
    font-size: 25px;
`;

export const GreenFont = styled.div`
    color: ${theme.primary};
`;

export const ButtonContainer = styled.button`
    background-color: #CFCF22;
    border: none;
    color: #4A4A4A;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 8px;
    font-weight: bold;
    margin-bottom: 80px;
    cursor: pointer;
`;
