import styled from 'styled-components/macro';
import theme from '../../core/theme';
import * as font from '../../core/font';

export const Text = styled.p`
  color: black;
  font-family: ${font.WorkSansMedium};
  font-size: 16px;
  line-height: 19px;
`;

export const Title = styled(Text)`
  font-family: ${font.WorkSansSemiBold};
  font-size: 35px;
  line-height: 42px;
`;

export const Description = styled.span`
  font-family: ${font.WorkSansSemiBold};
  font-size: 18px;
  line-height: 21px;
`;

export const Comment = styled(Text)`
  color: ${theme.disabled};
  font-family: ${font.CircularTTMedium};
  font-size: 16px;
  line-height: 22px;
  padding-top: 1rem;
`;

export const Highlight = styled(Description)`
  color: ${theme.primary};
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 20%;
  margin-left: 20%;
`;

export const GoBack = styled(Text)`
  color: ${theme.disabled};
  font-family: ${font.WorkSansMedium};
`;

export const BackButton = styled.div`
  align-items: center;
  background-color: ${theme.secondary};
  border-radius: 50px;
  box-shadow: 0 0 20px ${theme.secondary};
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  margin-right: 20px;
  width: 70px;
`;
