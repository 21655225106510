import styled from 'styled-components/macro';
import { BoldFont, LeftCenterContainer, Paragraph, TitleLine2 } from "./style";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 30px;
  margin-left: 60px;
  margin-right: 60px;
`;

const FormDisclaimer = () => {
  return (  
    <LeftCenterContainer>
      <BoldFont><TitleLine2>Setting the Stage</TitleLine2></BoldFont>
      <br/>
      <Paragraph>Share as much or as little information as you want throughout this Diagnostic. 
        We keep all responses secure and confidential. The entire intention of this Diagnostic is to help support 
        the human behind the business…you. If you’re not doing well, it’s hard for your business to. 
        We want to enrich you, encourage you, motivate you in those down times, and keep you in balance across different aspects 
        of your life and personality. We all honor you for the time and your responses. Thank you.
      </Paragraph>
      <br/>
      {/* <HorizontalDivider /> */}
      {/* <br/>
      <Paragraph>Clients must accept this before taking the diagnostic.</Paragraph>
      <br/> */}
      <br/>
    </LeftCenterContainer>
  );
}
 
export default FormDisclaimer;
