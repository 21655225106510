import styled from 'styled-components/macro';
import MarketingCase1 from "../../../assets/image/diagnostic_welcome/MarketingCase1.svg";
import MarketingCase2 from "../../../assets/image/diagnostic_welcome/MarketingCase2.svg";
import MarketingCase3 from "../../../assets/image/diagnostic_welcome/MarketingCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Marketing";

const DiagnosticTime = "7";

const DiagnosticURL = "";

const VideoURL = "https://www.loom.com/embed/b2fe660fc37445b585a94302ebc1f586?sid=f29c6c3c-dabb-42dc-a1f0-081751dd5b7b"

const QuoteContent = "\"I feel much more confident about marketing our company. I know there’s a lot of work to do, but learning about the Infinite Loop and how we can get customers to champion our company to others was powerful.\"";

const ScrollContent = "Marketing inspires people to want to explore your business, connect with your products and services, and tell the people they know about it. At all stages of building a company, marketing is about focus. Not doing everything, but the precise things that drive success in terms of getting and retaining more customers. This Diagnostic walks you through areas to direct your focus to create what we call the ‘Infinite Loop’. The Infinite Loop is an experience that anyone who interacts with your company can use to get value, purchase, and champion your company to others.\n\n\
Throughout this Diagnostic, we’ll ask questions about how you’re thinking about the value you create for your customers and about ways to use that value to create great relationships with your customers. Your products or services should be the most powerful marketing aspect of your company. They should turn your customers into champions of your brand who either tell others about your company or introduce you to others who can become customers. That’s what this Diagnostic is about.\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const NetworkEffects = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const ViralMarketing = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const MarketingPlaybook = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how powerful marketing through the Infinite Loop can be.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='MarketingCase1' src={MarketingCase1} width={100} style={{margin: "auto"}}/>
          <a href={NetworkEffects}>Download <br/> 'Network Effects'</a>
        </Case>
        <Case>
          <img alt='MarketingCase2' src={MarketingCase2} width={100} style={{margin: "auto"}}/>
          <a href={ViralMarketing}>Download <br/> 'Viral Marketing'</a>
        </Case>
        <Case>
          <img alt='MarketingCase3' src={MarketingCase3} width={100} style={{margin: "auto"}}/>
          <a href={MarketingPlaybook}>Download <br/> 'Marketing Playbook'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function MarketingDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
