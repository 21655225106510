import React from 'react';
import  theme  from '../../../../core/theme';
import * as font from '../../../../core/font';

const roomDesc =
  "You're about to enter the Mastermind Group with other entrepreneurs. Remember, this is a safe space. Please keep only good energy up in this thang. If more than one member flags you for inappropriate content, we will have to remove you from the room. If you accept, let's get after it!";

const Rules = ({ _onEnter }) => (
  <div style={styles.container}>
    <div>
      <span style={styles.title}>Rules of the room.</span>
      <span style={styles.desc}>{roomDesc}</span>
    </div>
    <button style={styles.buttonContainer} onPress={_onEnter}>
      <span style={styles.button}>Enter</span>
    </button>
  </div>
);


const styles = {
  button: {
    alignSelf: 'center',
    backgroundColor: theme.primary,
    color: 'white',
    fontSize: 18,
    marginTop: 10,
    paddingHorizontal: 10,
    paddingVertical: 10,
    textAlign: 'center',
    width: '50%',
  },
  buttonContainer: {
    borderRadius: 15,
  },
  container: {
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'space-around',
    paddingHorizontal: '10%',
  },
  desc: {
    color: "grey",
    fontFamily: font.CircularTTMedium,
    fontSize: 16,
    marginBottom: 10,
    textAlign: 'center',
  },
  title: {
    fontFamily: font.WorkSansSemiBold,
    fontSize: 20,
    margin: 7,
    textAlign: 'center',
  },
};

export default Rules;
