
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TopNav from 'screens/Office/Components/TopNav';
import styled from 'styled-components/macro';
import { NotificationPopUp } from '../../../GlobalComponents';
import FinancePriority1 from '../../../assets/image/diagnostic_welcome/FinancePriority1.svg';
import FinancePriority2 from '../../../assets/image/diagnostic_welcome/FinancePriority2.svg';
import FinancePriority3 from '../../../assets/image/diagnostic_welcome/FinancePriority3.svg';
import GreenRectangleSvg from '../../../assets/image/diagnostic_welcome/GreenRectangle.svg';
import NextButton from '../../../assets/image/diagnostic_welcome/NextButton.svg';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';
import SideBar from '../../Roadmap/SideBar';

const Background = styled.div`
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: ${theme.background};
  z-index: -100;
`

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 50px);
`

const LeftContainer = styled.div`
  width: 600px;
  min-width: 600px;
  height: 100vh;
  margin-top: 70px;
  border-top: 1px solid #c5c6c5;
`;

const GreenFont = styled.div`
  color: ${theme.primary};
`;

const Title1Container = styled.div`
  display: flex;
  width: 450px;
  margin-top: 30px;
  margin-left: 60px;
  font-size: 25px;
  font-weight: 450;
`;

const Title2Container = styled.div`
  width: 470px;
  margin-top: 50px;
  margin-left: 60px;
  font-size: 16px;
  font-weight: 300;
`;

const DescriptionContainer = styled.div`
  margin-top: 20px;
  margin-left: 60px;
  width: 470px;
  font-size: 13px;
  font-weight: 300;
`;

const PriorityCase = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: 60px;
  width: 470px;
`;

const CaseDescription = styled.div`
  display: grid;
  margin-top: 10px;
  margin-left: 10px;
  width: 320px;
  font-size: 16px;
  font-weight: 500;
`;

const CaseDescriptionText = styled.div`
  margin-top: -10px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
`;

const NextButtonContainer = styled.button`
  margin: auto;
  box-shadow: none;
`;

const RightContainer = styled.div`
  margin-top: 70px;
  width: auto;
  min-width: 600px;
  flex-grow: 1;
  background-color: white;
  border-top: 1px solid #c5c6c5;
  border-left: 1px solid #c5c6c5;
`;

const FinanceChoosePriorityPage = () => {
  const navigate = useNavigate();
  const {
    setExpandHomeBase,
    closeNotification,
    notification,
    notificationOpen,
  } = useContext(Context);

  useEffect(() => {
    setExpandHomeBase(false);
  }, []);

  const handleProfitabilityClick = () => {
    navigate('/office/diagnostic/finance/profitability');
  };

  const handleRaisingMoneyClick = () => {
    navigate('/office/diagnostic/finance/raising-money');
  };

  const handleManagingTaxesClick = () => {
    navigate('/office/diagnostic/finance/managing-taxes');
  };

  return (
    <>
      <Background />
      <Container >
        <Outlet />
        <TopNav />
        <SideBar />
        <NotificationPopUp
          close={closeNotification}
          open={notificationOpen}
          {...notification}
        />
        <LeftContainer>
          <Title1Container>
            <img alt='GreenRectangleSvg' src={GreenRectangleSvg} height={30}/>
            <GreenFont>&nbsp; Welcome to Finance Diagnostic!</GreenFont>
          </Title1Container>
          <DescriptionContainer>
            Finance is a crucial part of every business, and managing it depends on what your priorities are. Below, you can choose an option based on your goals and complete the corresponding Diagnostic section. Don’t worry, you can always complete the other sections whenever you want.
          </DescriptionContainer>
          <Title2Container>
            Which of the following Finance priorities is most important to you?
          </Title2Container>
          <PriorityCase>
            <img alt='FinancePriority1' src={FinancePriority1} width={80}/>
            <CaseDescription>
              Profitability
              <CaseDescriptionText>
                I want to understand how to reach, grow, or scale my company’s profitability.
              </CaseDescriptionText>
            </CaseDescription>
            <NextButtonContainer onClick={handleProfitabilityClick}><img alt='NextButton' src={NextButton}/></NextButtonContainer>
          </PriorityCase>
          <PriorityCase>
            <img alt='FinancePriority2' src={FinancePriority2} width={80}/>
            <CaseDescription>
              Raising Money
              <CaseDescriptionText>
                I want to figure out how likely we are to raise money and how to improve my chances.
              </CaseDescriptionText>
            </CaseDescription>
            <NextButtonContainer onClick={handleRaisingMoneyClick}><img alt='NextButton' src={NextButton}/></NextButtonContainer>
          </PriorityCase>
          <PriorityCase>
            <img alt='FinancePriority3' src={FinancePriority3} width={80}/>
            <CaseDescription>
              Managing Taxes
              <CaseDescriptionText>
                I want to understand how to better prepare for complex taxes that are confusing.
              </CaseDescriptionText>
            </CaseDescription>
            <NextButtonContainer onClick={handleManagingTaxesClick}><img alt='NextButton' src={NextButton}/></NextButtonContainer>
          </PriorityCase>
        </LeftContainer>    
        <RightContainer/>
      </Container>
    </>
  );
}

export default FinanceChoosePriorityPage;
