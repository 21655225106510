import styled from 'styled-components/macro';
import * as font from '../../core/font';
import theme from '../../core/theme';

export const CustomButton = styled.button`
  align-items: center;
  background-color: ${theme.secondary};
  outline: none;
  border-radius: 10px;
  box-shadow: ${(props) =>
    `0 0 ${props.active ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 230px;
`;

export const ButtonText = styled.span`
  color: black;
  opacity: 100%;
  font-family: ${font.WorkSansSemiBold};
  font-size: 22px;
  margin-left: 10px;
  margin-right: 25px;
`;

export const Subtitle = styled.span`
  color: #4EBA9A;
  font-family: ${font.WorkSansSemiBold};
  font-size: 22px;
  line-height: 25px;
`;
