import { Slider } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
`;

export default function HorizontalVolume({ value, onChange }) {
  return (
    <Container>
      <Slider
        value={value}
        onChange={onChange}
        max={100}
        step={1}
        orientation={'vertical'}
      />
    </Container>
  );
}
