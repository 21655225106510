import { Button } from '@mui/material';
import { apiMatchMakeUser } from 'api/match-make';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { toast } from 'utils/toast';
import * as font from '../../../../core/font';
import theme from '../../../../core/theme';
import { StartButton } from '../../../../GlobalComponents';
import { useAuth } from '../../../../providers/AuthContext';
import { useMyContext } from '../../../../providers/Context';
import MatchLoadingScreen from './MatchLoadingScreen';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const Container = styled.div`
  background-color: ${theme.background};
  flex: 1;
  padding: 25px;
  height: 600px;
  border-radius: 0px 0px 20px 20px;
`

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 25px;
  width: 390px;
`

const MastermindFirstVisit = (props) => {
  const { mastermindState, setMastermindState, setMessengerState } = useMyContext();
  const [step, setStep] = useState(1);
  const [step3Flag, setStep3Flag] = useState(true);
  const [displayLoadingScreen, setDisplayLoadingScreen] = useState(false);
  const { currentUser, userAuthToken } = useAuth();

  const _onBack = () => {
    if (step === 3) {
      setStep(2);
    } else if (step === 2) {
      setStep(1);
    } else if (step === 1) {
      setMastermindState({ firstVisit: false });
    }
  };

  const doMatchMake = async () => {
    try {
      setDisplayLoadingScreen(true)
      await apiMatchMakeUser(currentUser?.uid, userAuthToken);
    } catch (e) {
      toast.error(e?.message)
      console.log("Error : ", e);
    } finally {
      setDisplayLoadingScreen(false);
      setMastermindState({ firstVisit: false })
      setMessengerState({ display: false })
    }
  };

  const _onNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      if (step3Flag === true) {
        doMatchMake();
      } else {
        setMastermindState({ firstVisit: false })
        setMessengerState({ display: false })
      }
    }
  };

  return (
    <Container>

      {displayLoadingScreen ? (
        <MatchLoadingScreen setDisplayLoadingScreen={setDisplayLoadingScreen} />
      ) : (
        <>
          {step === 1 && <Step1 groupName={mastermindState.groupName} />}
          {step === 2 && <Step2 />}
          {step === 3 && <Step3 flag={step3Flag} setFlag={setStep3Flag} />}
          <ButtonContainer>
            <Button onClick={_onBack} color='inherit' style={{ fontFamily: font.WorkSansMedium, fontSize: '20px', color: 'grey' }}>
              Back
            </Button>
            <StartButton onClick={_onNext} />
          </ButtonContainer>
        </>
      )}
    </Container>


  );
};


export default MastermindFirstVisit;
