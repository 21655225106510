import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as groupSvg } from '../../../assets/image/groupIcon.svg';
import { ReactComponent as userSvg } from '../../../assets/image/userIcon.svg';
import theme from '../../../core/theme';
import { auth, database } from '../../../firebase/firebase';
import { Context } from '../../../providers/Context';
import OnlineIndicator from './OnlineIndicator';

const StyledUserSvg = styled(userSvg)`
  & #Path_286 {
    fill: ${(props) => props.color};
  }
  margin-right: 10px;
`;

const StyledGroupSvg = styled(groupSvg)`
  & #Ellipse_171 {
    fill: ${(props) => props.color};
  }
  margin-right: 10px;
`;

const Group = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  align-items: center;
  ${(props) => (props.pointer ? 'cursor: pointer;' : null)}
  &:hover {
    background-color: ${theme.background};
    transition-delay: 0.08s;
  }
`;

//For group title will be a concatted list of the user names
function MsgGroup({
  id,
  roomColor,
  chatType,
  pointer = true,
  ...props
}) {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    let uIDs = props.users?.filter((user) => user.uID !== auth.currentUser.uid);
    uIDs && Promise.all(
      uIDs.map((user) => {
        return database
          .ref('/Users/' + user.uID + '/BasicInfo')
          .once('value')
          .then((snap) => snap.val());
      }),
    ).then((result) => {
      setUsers(result)
    });
  }, [props.users]);

  const { setMessengerState } = useContext(Context);

  function openMessenger() {
    setMessengerState({
      id: id,
      display: "mastermind",
      users: props.users,
      roomColor: roomColor,
      chatType: chatType
    });
  }

  return (
    <Group onClick={openMessenger} pointer={pointer}>
      <OnlineIndicator online={true} />
      {chatType !== '1v1Chat' &&
        chatType !== 'StressHub' ? (
        <StyledGroupSvg color={roomColor} />
      ) : (
        <StyledUserSvg color={roomColor} />
      )}
      {chatType === '1v1Chat' ? (
        <h3>{users[0]?.Username || props.name}</h3>
      ) : (
        <div>
          <h3>{chatType} Mastermind</h3>
          <span>{users.length && users.map((ele) => ele.Username).join(', ')}</span>
        </div>
      )}
      <div>
        <h3>{ }</h3>
        <span>{ }</span>
      </div>
    </Group>
  );
}

export default MsgGroup;
