import { ReactComponent as UserSVG } from './../../../../../assets/image/user_yellow.svg';
import styles from "./index.module.scss";

const Step1 = ({ mastermindOnboardingGroups, currentUserData, userDisplayName }: any) => {
  const matchedUsersList = mastermindOnboardingGroups?.[0];

  return (
    <div>
      {currentUserData?.displayName || userDisplayName ?
        <h2>{currentUserData?.displayName ?? userDisplayName}, you have matches!</h2>
        :
        <h2>You have matches!</h2>
      }
      <div className={styles.titlebar}>
        Founder Group
      </div>

      <div style={{ marginTop: "16px" }}>

        {matchedUsersList?.map((usrData: any) => (
          <div className={styles.userListContainer}>
            <div className={styles.userListLeftSide}>
              <div className={styles.greenDot}>&nbsp;</div>
              <UserSVG className={styles.userImage} />
            </div>
            <div className={styles.userListRightSide}>
              <h4>{usrData?.Username}</h4>
              {usrData?.companyName ?
                <p>Founder of <b>{usrData?.companyName}</b></p>
                :
                <p>-</p>
              }
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}

export default Step1;
