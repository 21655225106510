import { Button, Checkbox, Col, Row } from "antd";
import { apiFounderRace } from "api/account-setup/founderRace";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const FounderRaceForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { founderRace, setFounderRace } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderRaceUpdateLoading, setfounderRaceUpdateLoading] = useState(false);

  const updateFounderRace = async () => {
    try {
      setfounderRaceUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        race: founderRace,
      };
      await apiFounderRace(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setfounderRaceUpdateLoading(false);
    }
  };

  const founderRaceUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderRace(updatedValue);
  };

  return (
    <>
      <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.title}> Race/Ethnicity </div>
      </div>

      <ProgressBar currentForm="06"/>

      <div className={`${styles.accountSetupMainFormContainer} ${styles.accountSetupMainWideFrom}`}>
        <div className={styles.subtitle}>Which best describes your race/ethnicity?</div>
        <div className={styles.instructionText} style={{marginBottom: "20px"}}> Check all that apply. </div>
        <Checkbox.Group style={{ display: 'block', width: "100%" , padding: 5}} onChange={founderRaceUpdated} value={[founderRace]}> 
          <Row>
            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Asian or Asian-American" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Asian or Asian-American</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Black or African-American" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Black or African-American</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Hispanic, Latino/a or Latinx/é" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Hispanic, Latino/a or Latinx/é</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Middle Eastern or North African" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Middle Eastern or North African</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Native American or Alaska Native" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Native American or Alaska Native</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="White or Caucasian" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>White or Caucasian</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Two or more races" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Two or more races</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Prefer not to say" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Prefer not to say</span>
            </Col>
            <Col span={1}></Col>
          </Row>
        </Checkbox.Group>
        <div style={{height: "150px", marginTop: "40px"}}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateFounderRace}
            loading={founderRaceUpdateLoading}
            disabled={founderRaceUpdateLoading || !founderRace}
            style={{height:"70px", width:"50vw", position: "absolute", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
          >
            <div className={styles.buttonText}>Next</div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FounderRaceForm;
