import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import theme from '../../../../core/theme';
import { auth, database } from '../../../../firebase/firebase';
import { Context } from '../../../../providers/Context';
import MsgGroup from '../../Components/MsgGroup';
import ActionButtons from '../ActionButtons';
import InvitePartner from '../InvitePartner';
import MessagesContainer from '../MessagesContainer';
import MessengerInput from '../MessengerInput';

const Header = styled.div`
  background-color: ${theme.background};
  margin: 0 -20px;
  padding: 5px;
`;

const MsgContainer = styled.div`
  padding: 0px 20px 20px 20px;
  position: relative;
`;

const Inputs = styled.div``;

const TopFade = styled.div`
  width: 90%;
  height: 30px;
  position: absolute;
  top: 68.99px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
`;

const BottomFade = styled.div`
  width: 90%;
  height: 30px;
  position: absolute;
  bottom: 98px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
`;

export default function Content({ messages, users }) {
  const { messengerState } = useContext(Context);
  const [inputType, setInputType] = useState('text'); // text | audio | video | attachment | picture
  const [displayInvitePartner, setDisplayInvitePartner] = useState(false)

  //   const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const [curQID, setCurQID] = useState(-1);
  const [options, setOptions] = useState([]);
  const [maxLength, setMaxLength] = useState(-1);
  const [respType, setRespType] = useState('');
  const componentIsMounted = useRef(true);


  const _onSendMessage = (id, message, messageType) => {
    // Send to message database
    database.ref('/Chat/' + messengerState.id + '/Messages/').push({
      Content: message,
      Type: messageType,
      Sender: auth.currentUser.uid,
      SentDate: Date.now(),
    });
  }

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);


  const MessagesOrMultiMediaInput = (inputType) => {
    switch (inputType) {
      case 'audio':
      // return (
      //   <MakeRecording
      //     messages={messages}
      //     setMessages={setMessages}
      //     setInputType={setInputType}
      //   />
      // );
      default:
        return (
          <MessagesContainer
            isTyping={isTyping}
            users={users}
            messages={messages}
            options={options}
            _onSendMessage={_onSendMessage}
            id={curQID}
            maxLength={
              maxLength === undefined ? Number.MAX_SAFE_INTEGER : maxLength
            }
          />
        );
    }
  };

  return (
    <MsgContainer>
      <Header>
        <MsgGroup
          {...messengerState}
          pointer={false} />
      </Header>
      <TopFade />
      <MessagesContainer
        isTyping={isTyping}
        userID={3}
        messages={messages}
        users={users}
        options={options}
        _onSendMessage={_onSendMessage}
        id={curQID}
        maxLength={
          maxLength === undefined ? Number.MAX_SAFE_INTEGER : maxLength
        }
      />
      <BottomFade />
      <Inputs>
        <MessengerInput
          setInputType={setInputType}
          _onSendMessage={_onSendMessage}
          id={curQID}
          responseType={respType}
        />
        <ActionButtons inputType={inputType} setInputType={setInputType} />
      </Inputs>
      {displayInvitePartner && <InvitePartner setDisplayInvitePartner={setDisplayInvitePartner} />}
    </MsgContainer>
  );
}
