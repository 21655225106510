import styled from 'styled-components/macro';
import grey from '../../../assets/image/background-grey.png';
import bg from '../../../assets/image/bg.png';
import logo from '../../../assets/image/logo.svg';
//import theme from '../../../core/theme';
import * as font from '../../../core/font';

const Quote = styled.div`
  font-size: 14px;
  color: white;
  position: absolute;
  bottom: 20%;
  left: 15%;
  font-family: ${font.CircularTTMedium};
  inline-size: 35%;
  overflow-wrap: break-word;
`;

const Author = styled.div`
  font-size: 14px;
  color: white;
  position: absolute;
  bottom: 12.5%;
  left: 20%;
  font-family: ${font.CircularTTMedium};
`;

const Left = () => {
    return (
    <>
      <div style={{backgroundImage: `url(${bg})`, backgroundPosition:"center", backgroundSize:"cover", width:"85%"}}></div>
      <img src={grey} alt="fixme" style={{filter: "brightness(30%)", opacity:"45%", position:"absolute", top:"0", left:"0", height:"100%", width:"65.4vw"}}/>
      <img src={logo} alt="fixme" style={{position:"absolute", top:"10%", left:"25%"}}/>
      <div style={{backgroundColor: "white", width: "1px", position:"absolute", top:"25%", bottom:"30%", left:"30%"}}></div>
      <Quote>"If you do not know how to ask the right question, you discover nothing."</Quote>
      <Author>W.Edwards Deming</Author>
      <div style={{backgroundColor: "#CFCF22", width: "62px", height:"5px", position:"absolute", bottom:"11%", left:"20%"}}></div>
    </>
    );
  };

export default Left;
