import styled from 'styled-components/macro';
import FinanceProfitabilityCase1 from "../../../assets/image/diagnostic_welcome/FinanceProfitabilityCase1.svg";
import FinanceProfitabilityCase2 from "../../../assets/image/diagnostic_welcome/FinanceProfitabilityCase2.svg";
import FinanceProfitabilityCase3 from "../../../assets/image/diagnostic_welcome/FinanceProfitabilityCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Finance";
const DiagnosticSub = "(Profitability)";

const DiagnosticTime = "4";

const DiagnosticURL = "";

const VideoURL = "https://www.loom.com/embed/76a7402056f640c6a834d889a391cc23?sid=930524ee-89be-4b99-86dc-3d76fe09f4e5"

const QuoteContent = "\"I feel much clearer on getting to profitability. There were some tough things I had to come to grips with, like not managing our expenses better but I have a good plan in place now. You know, it’s interesting - I typically don’t like to look at this aspect of the business but seeing where my gaps were was actually helpful.\"";

const ScrollTitleText = "Profitability";

const ScrollContent = "When it comes to Finance, most conversations in the startup and small business world are about fundraising. That doesn’t mean that there aren’t conversations about profitability, managing balance sheets, or proper financial stewardship; there are. However, the overwhelming majority of articles and reports from a financial perspective in this ecosystem are about fundraising and how much money was invested into a company at different stages. We’ve lost an emphasis on profitability.\n\n\
This Diagnostic analyzes your financial transactions and provides you with different scenarios and plans under which you can reach or grow profitability. It takes an in depth look at how your company generates income and the expenses that your business incurs. After analyzing those transactions, we dive into categorizing them and identifying ways to create better financial pathways for profitability. All data is verified, authenticated, and protected to prevent cybersecurity attacks and minimize risks.\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const ReachingProfitability = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const GrowingProfitability = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const ScalingProfitability = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how powerful profitability can be.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='FinanceProfitabilityCase1' src={FinanceProfitabilityCase1} width={100} style={{margin: "auto"}}/>
          <a href={ReachingProfitability}>Download <br/> 'Reaching Profitability'</a>
        </Case>
        <Case>
          <img alt='FinanceProfitabilityCase2' src={FinanceProfitabilityCase2} width={100} style={{margin: "auto"}}/>
          <a href={GrowingProfitability}>Download <br/> 'Growing Profitability'</a>
        </Case>
        <Case>
          <img alt='FinanceProfitabilityCase3' src={FinanceProfitabilityCase3} width={100} style={{margin: "auto"}}/>
          <a href={ScalingProfitability}>Download <br/> 'Scaling Profitability'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function FinanceProfitabilityDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName}
    DiagnosticSub = {DiagnosticSub}
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollTitleText={ScrollTitleText} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
