import { IFormSteps, IFormSteps2 } from "interface";

export const AccountSetupForms: IFormSteps = {
  STEP1: {
    prevStep: undefined,
    index: "01",
    nextStep: "02",
    title: "Company Overview"
  },
  STEP2: {
    prevStep: "01",
    index: "02",
    nextStep: "03",
    title: "Company Stage"
  },
  STEP3: {
    prevStep: "02",
    index: "03",
    nextStep: "04",
    title: "Company Industry",
  },
  STEP4: {
    prevStep: "03",
    index: "04",
    nextStep: "05",
    title: "Gender Identity",
  },
  STEP5: {
    prevStep: "04",
    index: "05",
    nextStep: "06",
    title: "Race/Ethnicity",
  },
  STEP6: {
    prevStep: "05",
    index: "06",
    nextStep: "07",
    title: "Birthdate",
  },
  STEP7: {
    prevStep: "06",
    index: "07",
    nextStep: undefined,
    title: "Location",
  },
};

export const SignUpForms: IFormSteps2 = {
  STEP1: {
    prevStep: undefined,
    index: "01",
    nextStep: "02",
    title: "SignUp"
  },
  STEP2: {
    prevStep: "01",
    index: "02",
    nextStep: "03",
    title: "Company Overview",
  },
  STEP3: {
    prevStep: "02",
    index: "03",
    nextStep: "04",
    title: "Company Stage",
  },
  STEP4: {
    prevStep: "03",
    index: "04",
    nextStep: "05",
    title: "Company Industry",
  },
  STEP5: {
    prevStep: "04",
    index: "05",
    nextStep: "06",
    title: "Gender Identity",
  },
  STEP6: {
    prevStep: "05",
    index: "06",
    nextStep: "07",
    title: "Race Ethnicity",
  },
  STEP7: {
    prevStep: "06",
    index: "07",
    nextStep: "08",
    title: "Mailing Address",
  },
  STEP8: {
    prevStep: "07",
    index: "08",
    nextStep: "09",
    title: "Mailing Address",
  },
  STEP9: {
    prevStep: "08",
    index: "09",
    nextStep: undefined,
    title: "Congrats",
  },
};
