export const Q1ColumnOne = [
    {name: "Social"},
    {name: "Neat"},
    {name: "Patient"},
    {name: "Reasonable"},
    {name: "Content"},
    {name: "Persistent"},
    {name: "Realistic"},
    {name: "Relaxed"},
    {name: "Dominant"},
    {name: "Analytical"},
    {name: "Satisfied"},
    {name: "Exciting"},
    {name: "Serene"},
    {name: "Unassumning"},
    {name: "Serious"},
    {name: "Assertive"},
    {name: "Disciplined"},
    {name: "Charming"},
    {name: "Orderly"},
    {name: "Tolerant"},
    {name: "Principled"},
    {name: "Pleasant"},
    {name: "Observant"},
    {name: "Consistent"},
    {name: "Clam"},
    {name: "Tidy"},
    {name: "Judicious"},
    {name: "Meticulous"},
    {name: "Formidable"}
  ]

export const Q1ColumnTwo = [
    {name: "Contemplative"},
    {name: "Constant"},
    {name: "Understanding"},
    {name: "Bold"},
    {name: "Conventional"},
    {name: "Charismatic"},
    {name: "Convincing"},
    {name: "Polished"},
    {name: "Caring"},
    {name: "Formal"},
    {name: "Loyal"},
    {name: "Alert"},
    {name: "Popular"},
    {name: "Comanding"},
    {name: "Sympathetic"},
    {name: "Precise"},
    {name: "Dutiful"},
    {name: "Accurate"},
    {name: "Powerful"},
    {name: "Eager"},
    {name: "Courageous"},
    {name: "Agreeable"},
    {name: "Factual"},
    {name: "Polite"},
    {name: "Determined"},
    {name: "Talkative"},
    {name: "Daring"},
    {name: "Esteemed"}
  ]

export const Q1ColumnThree = [
    {name: "Engaging"},
    {name: "Firm"},
    {name: "Responsive"},
    {name: "Careful"},
    {name: "Aware"},
    {name: "Relentless"},
    {name: "Fascinating"},
    {name: "Rational"},
    {name: "Gentle"},
    {name: "Competitive"},
    {name: "Organized"},
    {name: "Lively"},
    {name: "Logical"},
    {name: "Proper"},
    {name: "Outstanding"},
    {name: "Resolute"},
    {name: "Harmonious"},
    {name: "Earnest"},
    {name: "Nice"},
    {name: "Appealing"},
    {name: "Stable"},
    {name: "Influential"},
    {name: "Dignified"},
    {name: "Outgoing"},
    {name: "Respectful"},
    {name: "Unobstrusive"},
    {name: "Communicative"},
    {name: "Easy going"},
    {name: "Objective"}
  ]
