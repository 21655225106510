import React from 'react';
import { ReactComponent as AudioOffSVG } from '../../../assets/image/audio_off.svg';
import styled from 'styled-components/macro';
import theme from '../../../core/theme';

let AudioOff = styled(AudioOffSVG)`
  & #Rectangle_516 {
    fill: ${(props) => props.active ? `#000000` : `#a7a398`};
    stroke: ${(props) => props.active ? theme.secondary : `#f8f2e1`};
  };
  & #volume-1 {
    opacity: ${(props) => props.active ? `100%` : `32%`};
  }
`

const AudioOffIcon = (props) => (
  <AudioOff active={props.active}/>
);

export default AudioOffIcon;
