import arrowBack from '../../../assets/image/arrow_back@2x.png';
import arrowNext from '../../../assets/image/arrow_next@2x.png';
import { BackArrowContainer, BackButtonContainer, ButtonContainer, LeftBottomContainer, NextArrowContainer, NextButtonContainer } from "./style";

const FormButton = ({ stepNumber, handleBack, handleNext }) => {

  return (
    <LeftBottomContainer>
      <BackButtonContainer>
        <BackArrowContainer onClick={handleBack}>
          <img src={arrowBack} alt={"arrowBack"} width={30} />
        </BackArrowContainer>
      </BackButtonContainer>
      <NextButtonContainer>
        {stepNumber < 1 && <ButtonContainer onClick={handleNext}>Start Diagnostic</ButtonContainer>}
        {stepNumber > 0 && stepNumber < 6 && <NextArrowContainer onClick={handleNext}>
          <img src={arrowNext} alt={"arrowNext"} width={28} />
        </NextArrowContainer>}
        {stepNumber === 6 && <ButtonContainer onClick={handleNext}> Finish </ButtonContainer>}
      </NextButtonContainer>
    </LeftBottomContainer>
  );
}

export default FormButton;
