import { Button, Checkbox, Col, Row } from "antd";
import { apiFounderRace } from "api/account-setup/founderRace";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import GenericProgressBar from "screens/GenericProgressBar";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const FounderRaceForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { founderRace, setFounderRace } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderRaceUpdateLoading, setfounderRaceUpdateLoading] = useState(false);

  const updateFounderRace = async () => {
    try {
      setfounderRaceUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        race: founderRace,
      };
      await apiFounderRace(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setfounderRaceUpdateLoading(false);
    }
  };

  const founderRaceUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderRace(updatedValue);
  };

  return (
    <>
      {/* ${styles.accountSetupMainWideFrom} REMOVE THIS CLASS*/}
      <div className={`${styles.accountSetupMainFormContainer}`}>
        <GenericProgressBar currentStep={6} totalSteps={9} pageTitle="Race/Ethnicity" />
        <div className={styles.formQuestionText}>Which best describes your race/ethnicity?</div>
        <div className={styles.formQuestionDetail}>
          Check that apply.
        </div>
        <div className={`${styles.formInputContainer} ${styles.formCheckboxInput}`} style={{ height: "300px" }}>
          <Checkbox.Group style={{ width: "100%" }} value={[founderRace]} onChange={founderRaceUpdated}>
            <Row>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Asian or Asian-American" autoFocus={true}></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Asian or Asian-American</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Black or African-American"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Black or African-American</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Hispanic, Latino/a or Latinx/é"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Hispanic, Latino/a or Latinx/é</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Middle Eastern or North African"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Middle Eastern or North African</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Native American or Alaska Native"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Native American or Alaska Native</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Native Hawaiian or Pacific Islander"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Native Hawaiian or Pacific Islander</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="White or Caucasian"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>White or Caucasian</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Two or more races"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Two or more races</span>
              </Col>

              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Prefer not to say"></Checkbox>
              </Col>
              <Col span={23}>
                <span className={styles.formCheckboxTitle}>Prefer not to say</span>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateFounderRace}
            loading={founderRaceUpdateLoading}
            disabled={founderRaceUpdateLoading || !founderRace}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>
          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigateToFormStep(currentForm, "next")}
            loading={founderRaceUpdateLoading}
            disabled={founderRaceUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FounderRaceForm;
