// Creates an 80% height container to used inside a parent container that defines dimensions

import styled from 'styled-components/macro';

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80%;
`;

export default SubContainer;