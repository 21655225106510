import { useEffect, useState } from "react";
import styles from "./index.module.scss";

const GenericProgressBar = ({ currentStep, totalSteps, pageTitle }: any) => {
    const [marks, setMarks] = useState({});
    useEffect(() => {
        var marksTemp: any = {};
        for (let i = 0; i < totalSteps; i++) {
            marksTemp[`${i}`] = i;
        }
        setMarks(marksTemp);
        marksTemp = {};

    }, [totalSteps]);

    return (
        <div className={styles.fwrGenProgressBar}>
            <div className={styles.progressPageTitle}>{pageTitle}</div>
            <div className={styles.progressSlider}>
                {
                    [...Array(totalSteps)].map((e, i) => <>
                        <div className={styles.progressSliderDotContainer}>
                            {currentStep <= i ?
                                <>
                                    <div className={styles.progressSliderDot}>&nbsp;</div>
                                    <div className={styles.progressSliderTrack}>&nbsp;</div>
                                </>
                                :
                                <>
                                    <div className={styles.progressSliderDotActive}>
                                        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.83203 3.99414L3.90527 6.11719L7.6875 1.74023" fill="#4EBA9A" />
                                            <path d="M1.83203 3.99414L3.90527 6.11719L7.6875 1.74023" stroke="white" stroke-width="3" stroke-linecap="round" />
                                        </svg>

                                    </div>
                                    <div className={styles.progressSliderTrackActive}>&nbsp;</div>
                                </>

                            }
                        </div>
                    </>)
                }
                {/* <div className={styles.progressSliderDotContainer}>
                    <div className={styles.progressSliderDotActive}>&nbsp;</div>
                    <div className={styles.progressSliderTrackActive}>&nbsp;</div>
                </div>

                <div className={styles.progressSliderDotContainer}>
                    <div className={styles.progressSliderDot}>&nbsp;</div>
                    <div className={styles.progressSliderTrack}>&nbsp;</div>
                </div> */}
            </div>
        </div>
    )
}

export default GenericProgressBar
