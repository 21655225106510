import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as AccountCircleIcon } from '../../../assets/image/accountIcon.svg';
import { ReactComponent as NotificationsSVG } from '../../../assets/image/notificationIcon.svg';
import { ReactComponent as VolumeSVG } from '../../../assets/image/volumeIcon.svg';
import theme from '../../../core/theme';
import { useAuth } from '../../../providers/AuthContext';
import { Context } from '../../../providers/Context';
import VerticalVolume from './VerticalVolume';

//styles
const VolumeIcon = styled(VolumeSVG)`
  ${props => (
    `& #Path_46 {
      stroke: ${window.location.pathname.endsWith('/home') ? '#fff' : '#030303'};
    };
    & #Path_47 {
      stroke: ${window.location.pathname.endsWith('/home') ? '#fff' : '#030303'};
    };`
  )}
`;

const NotificationsIcon = styled(NotificationsSVG, {
  props: {
    home: true,
  },
})`
  ${props => (
    `& #Path_67 {
      stroke: ${window.location.pathname.endsWith('/home') ? '#fff' : '#030303'};
    };
    & #Path_68 {
      stroke: ${window.location.pathname.endsWith('/home') ? '#fff' : '#030303'};
    };`
  )}
`

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  ${props => props.office ? 'background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, .8), rgba(0, 0, 0, 0))' : ''};
`;

const TopNav = () => {
  //constants
  const { isCurrentUserAdmin } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchor, setNotificationAnchor] = useState(null)
  const [displayVolume, setDisplayVolume] = useState(false);
  const [error, setError] = useState('')
  const navigate = useNavigate();

  //context vars
  let {
    volume,
    setVolume,
    notificationArray,
    openNotification,
    closeNotification,
    setNotification,
    removeNotification,
  } = useContext(Context);
  const { Logout } = useAuth()

  //logout logic
  async function handleLogout() {
    setError('')

    try {
      await Logout()
      navigate('/login')
    }
    catch {
      setError("Failed to log out")
      console.log(error)
    }
  }

  //notification menu controller
  const handleNotificationMenuClose = () => {
    setNotificationAnchor(null)
  }

  //notification clicked controller
  const handleNotificationClick = (event) => {
    setNotificationAnchor(event.currentTarget)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  //volume controller
  const handleVolumeChange = (event, value) => {
    setVolume(value);
  };

  const selectNotification = (notification) => {
    setNotification(notification);
    openNotification()
    setNotificationAnchor(null)
  }

  const handleRemoveButtonClick = (e, i) => {
    e.stopPropagation()
    removeNotification(i)
    setNotification({})
    closeNotification()
  }

  return (
    <Container>
      <Box
        padding="0 20px"
        display="flex"
        justifyContent="flex-end"
        alignItems="baseline"
        height="75px"
        width="100%">

        {/* Volume Controller */}
        <Box>
          <IconButton onClick={() => setDisplayVolume(!displayVolume)}>
            <VolumeIcon />
          </IconButton>
          {displayVolume ? (
            <VerticalVolume value={volume} onChange={handleVolumeChange} />
          ) : null}
        </Box>
        <Box>

          {/* Notifications */}
          <IconButton onClick={handleNotificationClick}>
            <Badge badgeContent={notificationArray.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {notificationArray.length ? (
            <Menu
              id="notification-menu"
              anchorEl={notificationAnchor}
              keepMounted
              open={Boolean(notificationAnchor)}
              onClose={handleNotificationMenuClose}>
              {notificationArray.map((notification, i) => (
                <MenuItem onClick={() => selectNotification(notification)} key={i}>
                  <span style={{ marginRight: 'auto' }}>
                    {notification.title}
                  </span>
                  <IconButton onClick={(e) => handleRemoveButtonClick(e, i)}>
                    <RemoveCircleOutlineIcon style={{ color: theme.primary }} />
                  </IconButton>
                </MenuItem>
              ))}
            </Menu>
          ) : null}
        </Box>

        {/* dropdpown */}
        <Box>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={() => navigate('/account')}>My account</MenuItem>
            <MenuItem onClick={() => navigate('/update-profile')}>Update profile</MenuItem>
            {/* only admin can access this page */}
            {isCurrentUserAdmin &&
              <MenuItem onClick={() => navigate('/schedule-weekly-meeting')}>Schedule Weekly Meeting</MenuItem>
            }
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Container>
  );
};

export default TopNav;
