import { BoldFont, GreenFont, HorizontalDivider, TitleLine1, TitleLine2 } from './style';

const ThankYouPage = () => {
  return (  
    <>
      <BoldFont>
        <GreenFont> <TitleLine1> Thank You! </TitleLine1> </GreenFont>
        <TitleLine2> Self Diagnostic </TitleLine2>
      </BoldFont>
      <br/>
      <text>
        Woooh! You just took one of the best steps you could have taken for yourself. As we review your information, we’ll start developing strategies and ways to help keep you in sync. That’s across the mobile app and the web app. 
      </text>
      <br/>
      <br/>
      <text>
        In the meantime, check out The Dice Room in the mobile app and be sure to gift one of your dice to another entrepreneur. If you haven’t received yours yet, let us know!      </text>
      <br/>
      <br/>
      <HorizontalDivider></HorizontalDivider>
    </>
  );
}
 
export default ThankYouPage;
