import LeftSide from './LeftSide';
import RightSide from './RightSide';

import styles from './index.module.scss';

const UpdateProfile = () => {
  return (
    <div className={styles.fwrkLogin}>
      <LeftSide />
      <RightSide />
    </div>
  );
};

export default UpdateProfile;
