import React from 'react';
import styled from 'styled-components/macro';
import backgroundImage from '../../assets/image/bg.png';

const Container = styled.div`
  background: url(${backgroundImage}) no-repeat;
  background-size: cover;
  height: 100%;
  width: 72%;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

function LeftContainer() {
  return <Container />;
}

export default LeftContainer;
