import FramewrkLogo from "assets/image/FwrBlackLineLogo.png";
import LeftSideDetailComponent from "screens/NewAuth/NewLogin/LeftSideDetailComponent";
import RightSideLoginComponent from "./RightSideLoginComponent";
import styles from './index.module.scss';

const AccountSetupNew = () => {
  return (
    <div>
      <div className={styles.fwrAuthHeader}>
        <div className={styles.fwrLogo}>
          <img src={FramewrkLogo} alt="Framewrk Logo" />
        </div>
      </div>
      <div className={styles.fwrkLogin}>
        <LeftSideDetailComponent />
        <RightSideLoginComponent />
      </div>
    </div>
  );
};

export default AccountSetupNew;
