const API = {
  login: function (email, password) {
    if (email === 'user@framewrk.com' && password === 'password') {
      return true;
    } else {
      return false;
    }
  },
  loadMessages: function () {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([
          {
            name: 'Dwight S.',
            title: 'Consultant',
            group: false,
            color: '#EEBF78',
            online: true,
          },
          {
            name: 'Creed, Kevin and Oscar',
            title: 'Financial Mastermind',
            group: true,
            color: '#4EBA9A',
            online: false,
          },
          {
            name: 'Jan',
            title: 'Candle Manufacturing',
            group: false,
            color: '#F5BFAB',
            online: true,
          },
        ]);
      }, 300);
    });
  },
  loadStartWorkshop: function () {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          {
            title: "Brand Positioning Statement + Story",
            videoURL: "https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FPartner%2FClient%20Side%20-%20Partner%20Mode.mp4?alt=media&token=54e5dae0-d4f1-4676-acc9-6f3036a719ad",
            averageTime: "4 to 5 hours (with intermission)",
            averageFeeling: "Expressive",
            deliverables: {
              text: "One of your jobs as an entrepreneur is to de-risk your business as much as possible. While risk is everywhere, it’s also manageable. This diagnostic will measure your ability to respond to likely risk and minimize your exposure to things that might derail your business.",
              links: [{
                text: "Step by step branding roadmap",
                link: "/office/roadmap/workshop/infopane/Deliverable",
                fullInfo: {
                  deliverable: "Brand Positioning Statement + Story",
                  overview: "A Brand Positioning Statement + Story is a clear statement on what your brand means and the story behind your brand. The world operates through story - here’s your chance to express yours.",
                  downloadURL: "Materials/PlaceHolderPDFs/Sample_Client_Roadmap.pdf"
                }
              },
              {
                text: "Brand Guidlines",
                link: "/office/roadmap/workshop/infopane/Deliverable",
                fullInfo: {
                  deliverable: "Brand Guidelines",
                  overview: "Brand Guidelines are a specific formula for how your company’s brand operates. You can take these guidelines to designers who can replicate your brand and help it scale for the future.",
                  downloadURL: "Materials/PlaceHolderPDFs/Sample_Client_Roadmap.pdf"
                }
              },
              ]
            },
            FAQ: [
              {
                Q: "Will this workshop be amazing?",
                A: "Oh yes it will! It’s an adventure that can help you build your business like never before!"
              },
              {
                Q: "Should I know anything beforehand?",
                A: "Just take the pre-workshop survey and you’ll know all you need to know."
              },
              {
                Q: "Who should I invite?",
                A: "Invite your team members but also invite some of your customers who can help you really hone in on a brand position!"
              }
            ]


          }
        )
      }, 10);
    }
    )
  }

};

export default API;
