import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import { apiLoginAndAccountSetupStep } from 'api/auth/login';
import { emailValidator, generalValidator, passwordValidator } from 'core/utils';
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const SignUpForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { Signup, setUserAuthToken } = useAuth();
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [acceptedTermAndCondition, setAcceptedTermAndCondition] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const signUpUser = async () => {
    // validate for malformed or empty inputs
    const nameValidationError = generalValidator(firstName);
    const emailValidationError = emailValidator(email);
    const passwordValidationError = passwordValidator(password);

    setFirstNameError(nameValidationError);
    setEmailError(emailValidationError);
    setPasswordError(passwordValidationError);

    if (
      nameValidationError ||
      emailValidationError ||
      passwordValidationError
    ) {
      return;
    }

    //signup execution
    try {
      setFirstNameError('');
      setEmailError('');
      setPasswordError('');
      setSignUpLoading(true);
      console.log(firstName, email, password);
      await Signup(
        email.toString().trim(),
        password.toString().trim(),
        firstName.toString().trim()
      );

      const loginAndAccountSetupStepRes = await apiLoginAndAccountSetupStep({
        email: email,
        password: password,
      });
      setUserAuthToken(loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
      if (loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken) {
        localStorage.setItem('framewrk_user_token', loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
      }
      navigateToFormStep(currentForm, "next");
    } catch (e) {
      console.error(e);
      toast.error('Failed to create account');
    }

    setSignUpLoading(false);
  };

  return (
    <>
    <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
    <div className={styles.accountSetupMainTitleContainer}>
      <div className={styles.title}> Sign Up </div>
    </div>

    <ProgressBar currentForm="01"/>

    <div className={styles.accountSetupMainFormContainer}>
      <div className={styles.subtitle}> First Name </div>
      <div className={styles.inputContainer}>
        <Input
          type="text"
          size="large"
          placeholder="Enter Name"
          value={firstName}
          onChange={(e) => setFirstName(e?.target?.value)}
          prefix={< UserOutlined />}
        />
        <div>{firstNameError}</div>
      </div>
      <div className={styles.subtitle}> Email </div>
      <div className={styles.inputContainer}>
        <Input
          type="email"
          size="large"
          placeholder="Enter Email"
          prefix={<MailOutlined />}
          value={email}
          onChange={(e) => setEmail(e?.target?.value)}

        />
        <div>{emailError}</div> 
      </div>
      <div className={styles.subtitle}> Create A Password </div>
      <div className={styles.inputContainer}>
        <Input
          type="password"
          size="large"
          placeholder="* * * * * *"
          prefix={< LockOutlined />}
          value={password}
          onChange={(e) => setPassword(e?.target?.value)}
        />
        <div>{passwordError}</div>
      </div>
      <div className={styles.instructionText} style={{marginTop: "40px"}}>Already have an account?</div>
        <Link to="/signup" style={{ textDecoration: "none"}}>
          <div className={styles.linkText}>Sign In</div>
        </Link>
      <br></br>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          onChange={(e) => setAcceptedTermAndCondition(e?.target?.checked)}
          checked={acceptedTermAndCondition}
        />
      </div>
      <div className={styles.checkBoxText}>I agree to all statements included in the</div>
      <br></br>
      <br></br>
      <Link to="/terms-of-use" style={{ textDecoration: "none", marginLeft: "15%"}}>
        <div className={styles.linkText}>Terms of Use</div>
      </Link>
      <br></br>
      <div style={{height: "150px", marginTop: "40px"}}>
      <Button
        type="primary"
        size="large"
        id="signButton"
        name="signButton"
        onClick={signUpUser}
        disabled={signUpLoading || !acceptedTermAndCondition}
        loading={signUpLoading}
        style={{height:"70px", width:"50vw", position: "absolute", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
      >
        <div className={styles.buttonText}> Sign Up </div>
      </Button> 
      </div>
      </div>
    </>
  );
};

export default SignUpForm;
