import { Dialog } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as backArrowSvg } from '../../../assets/image/arrowBackBlack.svg';
import { ReactComponent as attachSvg } from '../../../assets/image/attachmentIcon.svg';
import { ReactComponent as pictureSvg } from '../../../assets/image/attachPictureIcon.svg';
import { ReactComponent as audioSvg } from '../../../assets/image/recordIcon.svg';
import { ReactComponent as videoSvg } from '../../../assets/image/recordVideo.svg';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';
import FeatureUnavailable from './FeatureUnavailable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 93%;
  align-items: center;
  margin: auto;
`;

const BackArrowIcon = styled(backArrowSvg)`
  margin-right: auto;
  cursor: pointer;
`;

const PictureIcon = styled(pictureSvg)`
  & #image > * {
    stroke: ${(props) =>
    props.selected
      ? theme.primary
      : props.disabled
        ? theme.disabled
        : '#000'};
  }
  margin: 0 8px;
  cursor: pointer;
`;
const AudioIcon = styled(audioSvg)`
  & #mic > * {
    stroke: ${(props) =>
    props.selected
      ? theme.primary
      : props.disabled
        ? theme.disabled
        : '#000'};
  }
  margin: 0 8px;
  cursor: pointer;
`;
const VideoIcon = styled(videoSvg)`
  & #video > * {
    stroke: ${(props) =>
    props.selected
      ? theme.primary
      : props.disabled
        ? theme.disabled
        : '#000'};
  }
  margin: 0 8px;
  cursor: pointer;
`;
const AttachIcon = styled(attachSvg)`
  & #paperclip {
    stroke: ${(props) =>
    props.selected
      ? theme.primary
      : props.disabled
        ? theme.disabled
        : '#000'};
  }
  margin: 0 8px;
  cursor: pointer;
`;

export default function ActionButtons({ inputType, setInputType }) {
  const {
    messengerState,
    setMessengerState,
    homeBaseAudioInputToggle,
  } = useContext(Context);
  const [dialogOpen, setDialogOpen] = useState(false);

  function openDialog() {
    setDialogOpen(true);
  }

  function goBack() {
    setMessengerState({ ...messengerState, display: false });
  }

  const StyledIconButton = withStyles({
    root: {
      padding: 0,
      background: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })(IconButton);

  return (
    <Wrapper>
      <BackArrowIcon onClick={goBack} />

      <StyledIconButton
        onClick={openDialog}
        disabled={!homeBaseAudioInputToggle}
      >
        <AttachIcon
          selected={inputType === 'attachment'}
          disabled={!homeBaseAudioInputToggle}
        />
      </StyledIconButton>
      <StyledIconButton
        onClick={openDialog}
        disabled={!homeBaseAudioInputToggle}
      >
        <PictureIcon
          selected={inputType === 'picture'}
          disabled={!homeBaseAudioInputToggle}
        />
      </StyledIconButton>
      <StyledIconButton
        disabled={!homeBaseAudioInputToggle}
        onClick={() => setInputType('audio')}
      >
        <AudioIcon
          selected={inputType === 'audio'}
          disabled={!homeBaseAudioInputToggle}
        />
      </StyledIconButton>
      <StyledIconButton
        onClick={openDialog}
        disabled={!homeBaseAudioInputToggle}
      >
        <VideoIcon
          selected={inputType === 'video'}
          disabled={!homeBaseAudioInputToggle}
        />
      </StyledIconButton>
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <FeatureUnavailable setDialogOpen={setDialogOpen} />
      </Dialog>
    </Wrapper>
  );
}
