import React from 'react';
import styled from 'styled-components/macro';
import LeftContainer from './LeftContainer';
import RightContainer from './RightContainer';

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`;

const Login = () => (
  <Container>
    <LeftContainer />
    <RightContainer />
  </Container>
);

export default Login;
