import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import * as font from '../../../core/font';
import { Context } from '../../../providers/Context';

export const ImageButton = styled.button`
  width: 330px;
  height: 206px;
  position: relative;
  margin: 20px;
  border-radius: 25px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: block;
  transition-delay: 0.05s;
  z-index: 100;
  ${(props) =>
    props.selected
      ? `filter: drop-shadow(0px 3px 15px rgba(207, 207, 34, 1));
    border: solid 2px;
    border-color: rgba(207, 207, 34, 1);
    width: 334px;
    height: 210px;
    margin: 18px;`
      : `
      &:hover {
    filter: brightness(75%);
  }`}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

export const ImageText = styled.span`
  color: white;
  font-family: ${font.WorkSansMedium};
  font-size: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function OfficeImage({
  text,
  source,
  videoSource,
  posterSource,
  audioSource,
  selectOffice,
  selected,
}) {
  const { setBackgroundVideo, setBackgroundStill, setBackgroundAudio } =
    useContext(Context);

  function setBackground() {
    setBackgroundVideo(videoSource);
    setBackgroundStill(posterSource);
    setBackgroundAudio(audioSource);
    localStorage.setItem('backgroundVideo', videoSource);
    localStorage.setItem('backgroundStill', posterSource);
    localStorage.setItem('backgroundAudio', audioSource);
    selectOffice();
  }

  return (
    <ImageButton onClick={setBackground} selected={selected}>
      <Image src={source} alt={text} />
      <ImageText>{text}</ImageText>
    </ImageButton>
  );
}

export default OfficeImage;
