import styled from 'styled-components/macro';
import RiskCase1 from "../../../assets/image/diagnostic_welcome/RiskCase1.svg";
import RiskCase2 from "../../../assets/image/diagnostic_welcome/RiskCase2.svg";
import RiskCase3 from "../../../assets/image/diagnostic_welcome/RiskCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Risk";

const DiagnosticTime = "5";

const DiagnosticURL = "";

const VideoURL = "https://www.loom.com/embed/b2b719ba37c54592bf67149232faa4f1?sid=6f8598f2-0913-40c1-86c0-d428c4f49d74";

const QuoteContent = "\"Honestly, I feel lighter. It took a bit for me to think through some ways that we could lower risks like if our head of technology leaves or if we run out of money, but now we have those plans in place. One of the things I initially thought was that this was going to be a waste of time because I've got shit I'm working on right now. But, I agree that it was important to go through this exercise, even if mentally to keep things in the back of my head for our team to keep rolling if we run out of cash.\"";

const ScrollContent = "In 2008, there was a market crash that forced many businesses to lay off employees, cut spending, and even shut down. In 2023, Silicon Valley Bank collapsed leading to many small businesses being saved by federal governments and larger banking institutions. These were largely out of small businesses' control, but we still felt the pain.\n\n\
Risks are everywhere but understanding how to prepare and plan for them helps turn once unfamiliar areas into comfortable places. One of the best ways to get prepared for risks that your business faces is to focus on documentation…written plans if a particular risk were to materialize. It is very easy to get caught up in the day-to-day flow of work instead of taking the time to sit down and write your plan for things. Documentation is critical to mitigate the impact of risks. This Diagnostic walks through the risks of the highest priority projects you're working on.\n\n\
After completing the Diagnostic, you will have documented 'pre-mortems' or plans for lessening the impact of those risks happening. A pre-mortem is a process where you imagine all of the things that could go wrong with a project that would lead to failure, and put failsafes in place. We will go through each of the divisions of your company, list out the highest priority projects you're working on, and identify the most likely things that could go wrong. For each issue that could happen, the Diagnostic will help craft light plans on how to course correct if that issue does indeed happen.\n\n\
As your company grows, the Diagnostic will evolve over time to incorporate risk management into your company's DNA. It will expand to include different types of risks, deploy reminders for you to manage risks, and help improve how you respond to many things that are out of your control.\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const RecessionProof = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const LocalMarketCrashes = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const EmployeeTurnover = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how powerful risk management strategies can be.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='RiskCase1' src={RiskCase1} width={100} style={{margin: "auto"}}/>
          <a href={RecessionProof}>Download <br/> 'Recession Proof'</a>
        </Case>
        <Case>
          <img alt='RiskCase2' src={RiskCase2} width={100} style={{margin: "auto"}}/>
          <a href={LocalMarketCrashes}>Download <br/> 'Local Market Crashes'</a>
        </Case>
        <Case>
          <img alt='RiskCase3' src={RiskCase3} width={100} style={{margin: "auto"}}/>
          <a href={EmployeeTurnover}>Download <br/> 'Employee Turnover'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function MarketingDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
