import { apiInviteMembers } from 'api/diagnostic/team/inviteMembers';
import { createContext, useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TopNav from 'screens/Office/Components/TopNav';
import { NotificationPopUp } from '../../../GlobalComponents';
import { useAuth } from "../../../providers/AuthContext";
import { Context } from '../../../providers/Context';
import SideBar from '../../Roadmap/SideBar';
import { Progress } from '../Progress';
import { Confirm } from './Confirm';
import { Members } from './Members';
import { PiOptions, PiQ1, PiQ2 } from './PI';
import { Reports } from './Reports';
import { Q1ColumnOne, Q1ColumnThree, Q1ColumnTwo } from "./data";
import { Background, BodyContainer, BoldFont, ButtonContainer, GreenFont, LeftContainer, RightContainer, TitleLine1, TitleLine2, VerticalDivider } from "./style";


export const TeamContext = createContext()

export default function Invitation(props) {
  const {
    closeNotification,
    notification,
    notificationOpen,
  } = useContext(Context);

  const navigate=useNavigate()
  const { userAuthToken } = useAuth();

  // variables
  const [names, setNames] = useState({})
  const [emails, setEmails] = useState({})
  const [IDCounter, setIDCounter] = useState(1)

  const [stepNumber, setStepNumber] = useState(0)
  const totalSteps = 5

  // checked state for pi survey
  // naming convention: ck{question_no}{column_no}
  const [cs11, setCs11] = useState(new Array(Q1ColumnOne.length).fill(false))
  const [cs12, setCs12] = useState(new Array(Q1ColumnTwo.length).fill(false))
  const [cs13, setCs13] = useState(new Array(Q1ColumnThree.length).fill(false))

  const [cs21, setCs21] = useState(new Array(Q1ColumnOne.length).fill(false))
  const [cs22, setCs22] = useState(new Array(Q1ColumnTwo.length).fill(false))
  const [cs23, setCs23] = useState(new Array(Q1ColumnThree.length).fill(false))

  // helper functions
  const inviteMembers = async(payload) => {
    try {
      let response = await apiInviteMembers(userAuthToken, payload)
      console.log(response)
    } catch (e) {
      console.log("Error : ", e);
    } finally {
    }
  }

  const isEmpty = (names, emails) => {
    var isEmpty = false;
    Object.values(names).forEach(val => {
      if (!val) {
        isEmpty = true
        return
      }
    })
    Object.values(emails).forEach(val => {
      if (!val) {
        isEmpty = true
        return
      }
    })

    return isEmpty
  }

  const parseMemberData = (names, emails) => {
    let payload = {"invitees": []}
    let keys = Object.keys(names);
    keys.forEach((key) => {
      const obj = {"Name": names[key], "email": emails[key]}
      payload["invitees"].push(obj)
    });
    return payload
  }

  // buttons
  const nextButton = <ButtonContainer onClick={() => {
    if (isEmpty(names, emails)) {
      alert("Input should not be blank")
    } else {
      setStepNumber(stepNumber+1)
    }
  }}>  Next </ButtonContainer>

  const backButton = <ButtonContainer onClick={() => {
    setStepNumber(stepNumber-1)
  }}>  Back </ButtonContainer>

  const submitButton = <ButtonContainer onClick={() => {
    const payload = parseMemberData(names, emails)
    console.log(payload)
    inviteMembers(payload)
    setStepNumber(stepNumber+1)
  }}>  Submit </ButtonContainer>

  const exitButton = <ButtonContainer onClick={() => {
    navigate('/office/diagnostic/team')
  }}>  Exit </ButtonContainer>

  return (
    <>
        <Background />
        <BodyContainer >
            <Outlet />
            <TopNav />
            <SideBar />
            <NotificationPopUp
                close={closeNotification}
                open={notificationOpen}
                {...notification}
            />
            <LeftContainer>
              <BoldFont>
                <GreenFont> <TitleLine1> Welcome to the </TitleLine1> </GreenFont>
                <TitleLine2> Team Diagnostic </TitleLine2>
              </BoldFont>
              {Progress(stepNumber, totalSteps)}
              { stepNumber === 0 && <TeamContext.Provider value={{names, setNames, emails, setEmails, IDCounter, setIDCounter}}><Members /></TeamContext.Provider>}
              { stepNumber === 1 && <PiQ1 />}
              { stepNumber === 2 && <PiQ2 />}
              { stepNumber === 3 && <Confirm />}
              { stepNumber === 4 && <Reports />}
              <br/><br/><br/>
              { stepNumber === 0 && <center>{nextButton}</center>}
              { stepNumber === 1 && <center>{backButton}&nbsp;&nbsp;&nbsp;&nbsp;{nextButton}</center>}
              { stepNumber === 2 &&  <center>{backButton}&nbsp;&nbsp;&nbsp;&nbsp;{nextButton}</center>}
              { stepNumber === 3 && <center>{backButton}&nbsp;&nbsp;&nbsp;&nbsp;{submitButton}</center>}
              { stepNumber === 4 && <center>{exitButton}</center>}
            </LeftContainer>
            <VerticalDivider />
            <RightContainer>
              { stepNumber === 1 && <TeamContext.Provider value={{ cs11, setCs11, cs12, setCs12, cs13, setCs13,
                                      cs21, setCs21, cs22, setCs22, cs23, setCs23, stepNumber }}><PiOptions /></TeamContext.Provider>}
              { stepNumber === 2 &&  <TeamContext.Provider value={{ cs11, setCs11, cs12, setCs12, cs13, setCs13,
                                      cs21, setCs21, cs22, setCs22, cs23, setCs23, stepNumber }}><PiOptions /></TeamContext.Provider>}
            </RightContainer>
        </BodyContainer>
    </>
)
}
