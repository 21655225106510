
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TopNav from 'screens/Office/Components/TopNav';
import styled from 'styled-components/macro';
import { NotificationPopUp } from '../../GlobalComponents';
import CloseButtonSvg from '../../assets/image/diagnostic_welcome/CloseButton.svg';
import GreenRectangleSvg from '../../assets/image/diagnostic_welcome/GreenRectangle.svg';
import ClockSvg from '../../assets/image/diagnostic_welcome/clock.svg';
import SmileSvg from '../../assets/image/diagnostic_welcome/smile.svg';
import theme from '../../core/theme';
import { Context } from '../../providers/Context';
import SideBar from '../Roadmap/SideBar';

const Background = styled.div`
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: ${theme.background};
  z-index: -100;
`

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 50px);
`

const LeftContainer = styled.div`
  width: 600px;
  min-width: 600px;
  height: 100vh;
  margin-top: 70px;
  border-top: 1px solid #c5c6c5;
`;

const Icon = styled.img`
  height: 20px;
  padding: 6px 0px 2px 0px;
`;

const GreenFont = styled.div`
  color: ${theme.primary};
`;

const Title1Container = styled.div`
  display: flex;
  width: 450px;
  margin-top: 30px;
  margin-left: 60px;
  font-size: 25px;
  font-weight: 450;
`;

const Title2Container = styled.div`
  display: flex;
  width: 400px;
  margin-top: 15px;
  margin-left: 60px;
  font-size: 17px;
  font-weight: 300;
`;

const QuoteContainer = styled.div`
  display: flex;
  width: 390px;
  margin-top: 5px;
  margin-left: 80px;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: ${theme.primary};
`;

const DescriptionContainer = styled.div`
  margin-top: 20px;
  margin-left: 60px;
  width: 470px;
  font-size: 12px;
  font-weight: 300;
`;

const ScrollContainer = styled.div`
  width: 520px;
  margin-left: 40px;
  margin-top: 30px;
  border-radius: 20px;
  background-color: #fbf8f0;
  box-shadow: 6px 6px 10px 7px #c7c2b5;
  padding: 20px 20px 20px 20px;
`;

const ScrollTitle = styled.div`
  display: flex;
  font-size: 17px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  margin-left: auto;
  background-color: transparent;
  box-shadow: none;
`;

const ScrollText = styled.div`
  font-size: 13px;
  font-weight: 300;
  overflow: auto;
  max-height: 200px;
`;

const Divider = styled.div`
  background-color: #b9b9b9;
  height: 1px;
  width: 480px;
  margin-top: 40px;
  margin-left: 60px;
`;

const ButtonContainer = styled.button`
  margin-top: 20px;
  margin-left: 200px;
  margin-bottom: 50px;
  background-color: #CFCF22;
  color: #4A4A4A;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
`;

const RightContainer = styled.div`
  margin-top: 70px;
  width: auto;
  min-width: 600px;
  flex-grow: 1;
  background-color: white;
  border-top: 1px solid #c5c6c5;
  border-left: 1px solid #c5c6c5;
`;

const VideoContainer = styled.div`
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const YoutubeVideoContainer = styled.div`
  margin-top: 10px;
  max-width: 600px;
  width: 80%;
  aspect-ratio: 560/315;
  border-radius: 8px;
`;

const IFrame = styled.iframe`
  border-radius: inherit;
  height: 100%;
  width: 100%;
`;

const ReportContainer = styled.div`
  margin-top: 5px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
`;

const WelcomePage = ({DiagnosticName, DiagnosticSub="", DiagnosticTime, QuoteContent="", DescriptionContent="", ScrollTitleText="", ScrollContent="", DiagnosticURL, VideoURL, ReportLink, RightBottomComponent}) => {
  const navigate = useNavigate();
  const {
    setExpandHomeBase,
    closeNotification,
    notification,
    notificationOpen,
  } = useContext(Context);

  useEffect(() => {
    setExpandHomeBase(false);
  }, []);

  const handleCloseButton = () => {
    navigate('/office/diagnostic/finance');
  };

  return (
    <>
      <Background />
      <Container >
        <Outlet />
        <TopNav />
        <SideBar />
        <NotificationPopUp
          close={closeNotification}
          open={notificationOpen}
          {...notification}
        />
        <LeftContainer>
          <Title1Container>
            <img alt='GreenRectangleSvg' src={GreenRectangleSvg} height={30}/>
            <GreenFont>&nbsp; Welcome to {DiagnosticName} Diagnostic!</GreenFont>
          </Title1Container>        
          <Title2Container>
            <Icon src={ClockSvg}/>
            &nbsp; Average Completion Time: 
            <GreenFont>&nbsp;&nbsp;&nbsp;{DiagnosticTime} minutes</GreenFont>
          </Title2Container>      
          <Title2Container>
            <Icon src={SmileSvg}/>
            &nbsp; Average Feeling after Completion:
          </Title2Container>        
          <QuoteContainer>{QuoteContent.split('\n').map(line => (<p>{line}</p>))}</QuoteContainer>          
          <DescriptionContainer>{DescriptionContent.split('\n').map(line => (<p>{line}</p>))}</DescriptionContainer>
          <ScrollContainer>
            {ScrollTitleText && <ScrollTitle>
              {ScrollTitleText}
              <CloseButton onClick={handleCloseButton}>
                <img alt='CloseButtonSvg' src={CloseButtonSvg} height={20} style={{marginBottom: "5px"}}/>
              </CloseButton>
            </ScrollTitle>}
            <ScrollText>{ScrollContent.split('\n').map(line => (<p>{line}</p>))}</ScrollText>
          </ScrollContainer>
          <Divider/>          
          <ButtonContainer onClick={() => {navigate(DiagnosticURL)}}>
            Start Diagnostic
          </ButtonContainer>
        </LeftContainer>           
        <RightContainer>
          <VideoContainer>
            How the {DiagnosticName} {DiagnosticSub} Diagnostic helps you.
            <center><YoutubeVideoContainer>
              <IFrame title="Framewrk Diagnostic Intro" src={VideoURL}/>
            </YoutubeVideoContainer></center>
          </VideoContainer>
          {ReportLink && <ReportContainer>
            <a href={ReportLink} style={{textDecoration: "underline"}}>Download Sample Report</a>
          </ReportContainer>}       
          <RightBottomComponent/>
        </RightContainer>
      </Container>
    </>
  );
}
 
export default WelcomePage;
