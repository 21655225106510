import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiUserSurveryFilledData = async (authToken: string) => {
  try {
    const url = `${REACT_APP_BACKEND_API_URL}/users/user-pending-survey`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};
