import { BoldFont, LeftCenterContainer, Paragraph, TitleLine2 } from "./style";

const FormDisclaimer = () => {
  return (
    <LeftCenterContainer>
      <BoldFont><TitleLine2>Diagnostic Overview</TitleLine2></BoldFont>
      <br />
      <br />
      <BoldFont>Section I: Customer Understanding</BoldFont>
      <br />
      <Paragraph>
        Here, we explore what your customers value the most. It's important to
        be objective here. Do they value price, customer service, saving time,
        high quality services, etc. We'll go through a ranking process, which will
        help our team build a comprehensive plan for you.
      </Paragraph>
      <br />
      <BoldFont>Section II: Barriers to Entry</BoldFont>
      <br />
      <Paragraph>
        Here, we rank how tough it is to entry the market you are in, or create
        a company similar to what you're creating. We'll start to get an understanding
        of where you fit in the market and how we can really support you
        executing.
      </Paragraph>
      <br />
      <BoldFont>Section III: Input Control</BoldFont>
      <br />
      <Paragraph>
        Here, we rank how tough it is to entry the market you are in, or create
        a company similar to what you're creating. We'll start to get an understanding
        of where you fit in the market and how we can really support you
        executing.
      </Paragraph>
      <br />
    </LeftCenterContainer>
  );
}

export default FormDisclaimer;
