import React, { useEffect, useContext } from 'react';
import { Context } from '../../../providers/Context';
import styled from 'styled-components/macro';
import videoURLS from '../../../assets/videoURLS.json';

const Video = styled.video`
object-fit: cover;
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
left: 0;
`;


export default function BackgroundVideo() {

  const {
    backgroundVideo,
    setBackgroundVideo,
    backgroundStill,
    setBackgroundStill,
    backgroundAudio,
    setBackgroundAudio,
    volume
  } = useContext(Context);


  useEffect(() => {
    //Fixed this logic to get background video data and load it on context for use
    if (!backgroundVideo || !backgroundStill || !backgroundAudio) {
      const localBackgroundVideo = localStorage.getItem('backgroundVideo');
      const localBackgroundStill = localStorage.getItem('backgroundStill');
      const localBackgroundAudio = localStorage.getItem('backgroundAudio');

      const calmForest = videoURLS?.officeBackgrounds?.find((vid) => vid?.text === "Calm Forest");

      (!backgroundVideo)
        ? setBackgroundVideo(localBackgroundVideo)
        : setBackgroundVideo(calmForest?.video);
      (!backgroundStill)
        ? setBackgroundStill(localBackgroundStill)
        : setBackgroundStill(calmForest?.still);
      (!backgroundAudio)
        ? setBackgroundAudio(localBackgroundAudio)
        : setBackgroundAudio(calmForest?.audio)
    }
  });

  useEffect(() => {
    const audioPlayer = document.getElementById("background_audio");
    audioPlayer.volume = volume / 100;
  }, [volume])

  return (
    <>
      <Video autoPlay
        muted
        loop
        playsInline
        src={backgroundVideo}
        poster={backgroundStill}
        id="bgvid" />
      <audio src={backgroundAudio} autoPlay loop id="background_audio" />

    </>
  )
}