import { useContext } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components/macro';
import { Context } from '../../providers/Context';
import ContractController from './Components/ContractController';
import HomebaseBottom from './Components/HomebaseBottom';

const HomebaseCard = styled.div`
  box-shadow: 0px 3px 40px 0px #888888;
  max-width: 441px;
  max-height: 760px;
  padding-right: -5px;
  padding-left: -5px;
  position: absolute;
  right: 40px;
  bottom: 30px;
  transform: translateX(-90%);
  overflow-y: auto;
  overflow-x: hidden;
`;
const HomebaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 15px 25px 10px 10px;
  align-items: center;
  background-color: #ffffff;
  width: 441px;
  border-radius: inherit;
`;

function Homebase() {
  const { expandHomeBase } = useContext(Context);

  return (
    <Draggable>
      <HomebaseCard
        style={{ borderRadius: expandHomeBase ? '20px 20px 0px 0px' : '20px', zIndex: 100 }}
      >
        <HomebaseHeader>
          <h2>
            <strong style={{ marginLeft: "150px"}}>Home Base</strong>
          </h2>
          <ContractController />
        </HomebaseHeader>
        {expandHomeBase ? <HomebaseBottom /> : null}
      </HomebaseCard>
    </Draggable>
  );
}

export default Homebase;
