import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as InfoIcon } from '../../../assets/image/infoIcon.svg';
import { ReactComponent as MegaphoneSVG } from '../../../assets/image/megaphoneIcon.svg';
import API from '../../../core/API';
import * as font from '../../../core/font';
import InfoPane from './InfoPane';
import InvitePane from './InvitePane';

import { Box, IconButton, Tooltip } from '@mui/material';

const Title = styled.h1`
  font-family: ${font.WorkSansSemiBold};
  font-size: 30px;
  color: black;
  margin-left: 15px;
`;

const Subtitle = styled.h2`
  font-family: ${font.CircularTTMedium};
  font-size: 12px;
  opacity: 51%;
  margin-left: 66px;
  margin-bottom: 15px;
  margin-top: -10px;
`;

export default function StartWorkshop(props) {
    const [loaded, setLoaded] = useState(false);
    const [workshopData, setWorkshopData] = useState({});

    useEffect(() => {
        Object.keys(workshopData).length === 0
            ? API.loadStartWorkshop().then((res) => {
                setWorkshopData(res);
                setLoaded(true);
            })
            : setLoaded(true);
    });

    return loaded ? (
        <>
            <Box display="flex" justify="flex-start" alignItems="center">
                <MegaphoneSVG />
                <Title>{workshopData.title}</Title>
                <Tooltip title="Coming Soon!" arrow>
                    <IconButton style={{ marginLeft: 'auto' }}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Subtitle>
                Team up to perfect the image of your company and reach the right people
                to maximize profits
            </Subtitle>
            <Routes>
                {[
                    '/office/roadmap/workshop/infopane',
                    '/office/roadmap/workshop/infopane/deliverable',
                    '/office/roadmap/workshop/infopane/FAQ'
                ].map(path => (
                    <Route
                        key="/office/roadmap/workshop/infopane" // optional: avoid full re-renders on route changes
                        exact
                        path={path}
                        element={<InfoPane workshopData={workshopData} />}
                    />
                ))}
                <Route
                    exact
                    path="/office/roadmap/workshop/invite"
                    element={<InvitePane />}
                />
            </Routes>
        </>
    ) : null;
}
