import { Button, Input } from "antd";
import { apiMailingAddress } from "api/account-setup/mailingAddress";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const MailingAddressForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { mailingAddress, setMailingAddress } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [mailingAddressUpdateLoading, setMailingAddressUpdateLoading] = useState(false);

  const updateMailingAddress = async () => {
    try {
      setMailingAddressUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        mail: mailingAddress,
      };
      await apiMailingAddress(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setMailingAddressUpdateLoading(false);
    }
  };

  return (
    <>
      <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.title}> Location </div>
      </div>

      <ProgressBar currentForm="08"/>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.subtitle}>What is your mailing address?</div>
        <div className={styles.instructionText} style={{marginTop:"5px", marginBottom: "10px"}}> We use this information to send you a personalized gift!</div>
        <div className={styles.inputContainer}>
          <Input
            value={mailingAddress}
            size="large"
            onChange={(e) => setMailingAddress(e?.target?.value)}
            autoFocus={true}
          />
        </div>

        <div style={{height: "370px", marginTop: "40px"}}>
          <Button
            type="primary"
            size="large"
            onClick={updateMailingAddress}
            loading={mailingAddressUpdateLoading}
            disabled={mailingAddressUpdateLoading || !mailingAddress}
            style={{height:"70px", width:"50vw", position: "absolute", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
          >
            <div className={styles.buttonText}>Next</div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default MailingAddressForm;
