import { Button } from "antd";
import { apiMailingAddress } from "api/account-setup/mailingAddress";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericProgressBar from "screens/GenericProgressBar";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";
import AddressInput from "./autocompletion";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const MailingAddressForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { 
    mailingAddress,
    mailingAddressAptNumber,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipcode,
    mailingAddressCountry,
  } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [mailingAddressUpdateLoading, setMailingAddressUpdateLoading] = useState(false);
  const navigate = useNavigate();

  const updateMailingAddress = async () => {
    try {
      setMailingAddressUpdateLoading(true);
      
      const fullAddress = mailingAddressCountry === "United States" && mailingAddressAptNumber ? `${mailingAddress}, ${mailingAddressAptNumber}, ${mailingAddressCity}, ${mailingAddressState} ${mailingAddressZipcode}, ${mailingAddressCountry}` 
      : mailingAddressCountry === "United States" && !mailingAddressAptNumber ? `${mailingAddress}, ${mailingAddressCity}, ${mailingAddressState} ${mailingAddressZipcode}, ${mailingAddressCountry}`
      : mailingAddress;
      
      const payload = {
        uid: currentUser?.uid,
        mail: fullAddress,
      };

      await apiMailingAddress(userAuthToken!, payload);
      navigate('/choose-your-office')
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setMailingAddressUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainFormContainer}>
        <GenericProgressBar currentStep={8} totalSteps={9} pageTitle="Location" />
        <div className={styles.formQuestionText}>What is your mailing address?</div>
        <div className={styles.formQuestionDetail}>
          We use this information to send you a personalized gift!
        </div>

        <AddressInput/>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateMailingAddress}
            loading={mailingAddressUpdateLoading}
            disabled={mailingAddressUpdateLoading || !mailingAddress}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>
          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigate('/choose-your-office')}
            loading={mailingAddressUpdateLoading}
            disabled={mailingAddressUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default MailingAddressForm;
