import { useState, useEffect } from 'react';

function useAudioPlayer({id}) {
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [volume, setVolume] = useState(0.5);

  const handleVolumeChange = (event, value) => {
    setVolume(value);
  };

  useEffect(() => {
    const audio = document.getElementById(id);

    const checkForInfinity = async () => {
      if (audio.duration === Infinity) {
        while (audio.duration === Infinity) {
          await new Promise((r) => setTimeout(r, 500));
          console.log('Duration = Infinity');
          audio.currentTime = 10000000;
        }
        audio.currentTime = 0;
        setDuration(audio.duration);
      }
    };
    checkForInfinity();

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);

    const playbackComplete = () => {
      if (playPromise !== undefined) {
        playPromise
          .then(() => audio.pause())
          .catch(() => console.log('Error Pausing'));
      }
      setPlaying(false);
    };

    // DOM listeners: update React state on DOM events
    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);
    audio.addEventListener('ended', playbackComplete);

    // React state listeners: update DOM on React state changes
    let playPromise = playing ? audio.play() : audio.pause();
    audio.volume = volume;

    if (clickedTime && clickedTime !== currentTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
      audio.removeEventListener('ended', playbackComplete);
    };
  });

  return {
    currentTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    volume,
    handleVolumeChange,
  };
}

export default useAudioPlayer;
