import { useAuth } from 'providers/AuthContext';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { StartButton } from '../../../../GlobalComponents';
import { auth, database } from './../../../../firebase/firebase';
import Step1 from './Step1';
import Step2 from './Step2';

const Container = styled.div`
  flex: 1;
  padding: 25px;
  height: 600px;
  border-radius: 0px 0px 20px 20px;
`

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 100px;
  right: 50%;
  transform: translateX(18%);
  width: 390px;
`

const MastermindOnboardings = ({ }) => {
  const { mastermindOnboardingGroups, setMastermindOnboardingGroups, currentUser } = useAuth();
  const [step, setStep] = useState(1);

  const _onNext = async () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      await database.ref('/Users/' + auth?.currentUser?.uid).update({ "ShowGroupList": null });
      setMastermindOnboardingGroups([]);
    }
  };

  return (
    <Container>
      <>
        {step === 1 && <Step1 mastermindOnboardingGroups={mastermindOnboardingGroups} currentUserData={currentUser} userDisplayName={auth?.currentUser?.displayName} />}
        {step === 2 && <Step2 />}
        <ButtonContainer>
          <StartButton onClick={_onNext}>
            <div style={{ fontSize: "18px", color: "white" }}>
              Open Group
            </div>
          </StartButton>
        </ButtonContainer>
      </>
    </Container>
  );
};


export default MastermindOnboardings;
