export const companyIndustriesList = [
    {
        title: "Accommodation and Food Service",
        subIndustries: [{ title: "Restaurant" }, { title: "Coffee Shop" }]
    },
    {
        title: "Aerospace and Defense",
        subIndustries: []
    },
    {
        title: "Agriculture and Farming",
        subIndustries: []
    },
    {
        title: "Architecture and Planning",
        subIndustries: []
    },
    {
        title: "Automotive and Transportation",
        subIndustries: []
    },
    {
        title: "Banking and Financial Services",
        subIndustries: [{ title: "Fintech" }, { title: "Retail Banking" }]
    },
    {
        title: "Beauty and Personal Care",
        subIndustries: [{ title: "Hair & Beauty" }]
    },
    {
        title: "Biotechnology and Pharmaceuticals",
        subIndustries: []
    },
    {
        title: "Business Services and Consulting",
        subIndustries: []
    },
    {
        title: "Chemicals",
        subIndustries: []
    },
    {
        title: "Construction and Engineering",
        subIndustries: []
    },
    {
        title: "Consumer Goods and Services",
        subIndustries: []
    },
    {
        title: "Education and Training",
        subIndustries: []
    },
    {
        title: "Energy and Utilities",
        subIndustries: []
    },
    {
        title: "Entertainment and Media",
        subIndustries: []
    },
    {
        title: "Environmental Services",
        subIndustries: []
    },
    {
        title: "Food and Beverage",
        subIndustries: []
    },
    {
        title: "Healthcare and Life Sciences",
        subIndustries: []
    },
    {
        title: "Hospitality and Tourism",
        subIndustries: []
    },
    {
        title: "Human Resources and Staffing",
        subIndustries: []
    },
    {
        title: "Information Technology and Services",
        subIndustries: []
    },
    {
        title: "Insurance",
        subIndustries: []
    },
    {
        title: "Legal Services",
        subIndustries: []
    },
    {
        title: "Logistics and Supply Chain",
        subIndustries: []
    },
    {
        title: "Manufacturing",
        subIndustries: []
    },
    {
        title: "Marketing and Advertising",
        subIndustries: []
    },
    {
        title: "Mining and Metals",
        subIndustries: []
    },
    {
        title: "Nonprofit and Social Services",
        subIndustries: []
    },
    {
        title: "Oil and Gas",
        subIndustries: []
    },
    {
        title: "Real Estate",
        subIndustries: []
    },
    {
        title: "Retail and E-commerce",
        subIndustries: []
    },
    {
        title: "Sports and Recreation",
        subIndustries: []
    },
    {
        title: "Telecommunications",
        subIndustries: []
    },
    {
        title: "Transportation and Logistics",
        subIndustries: []
    },
    {
        title: "Waste Management and Recycling",
        subIndustries: []
    },
    {
        title: "Administrative Services",
        subIndustries: []
    },
    {
        title: "Apps",
        subIndustries: []
    },
    {
        title: "Artificial Intelligence",
        subIndustries: []
    },
    {
        title: "Clothing and Apparel",
        subIndustries: []
    },
    {
        title: "Events",
        subIndustries: []
    },
    {
        title: "Community and Lifestyle",
        subIndustries: []
    },
    {
        title: "Commerce and Shopping",
        subIndustries: []
    },
    {
        title: "Consumer Electronics",
        subIndustries: []
    },
    {
        title: "Content and Publishing",
        subIndustries: []
    },
    {
        title: "Data and Analytics",
        subIndustries: []
    },
    {
        title: "Design",
        subIndustries: []
    },
    {
        title: "Gaming",
        subIndustries: []
    },
    {
        title: "Government and Military",
        subIndustries: []
    },
    {
        title: "Hardware",
        subIndustries: []
    },
    {
        title: "Information Technology",
        subIndustries: []
    },
    {
        title: "Internet Services",
        subIndustries: []
    },
    {
        title: "Lending and Investments",
        subIndustries: []
    },
    {
        title: "Mobile",
        subIndustries: []
    },
    {
        title: "Music and Audio",
        subIndustries: []
    },
    {
        title: "Navigation and Mapping",
        subIndustries: []
    },
    {
        title: "Other",
        subIndustries: []
    },
    {
        title: "Payments",
        subIndustries: []
    },
    {
        title: "Privacy and Security",
        subIndustries: []
    },
    {
        title: "Professional Services",
        subIndustries: []
    },
    {
        title: "Software",
        subIndustries: []
    },
];
