import { Button, DatePicker } from "antd";
import { apiBirthDate } from "api/account-setup/birthDate";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import GenericProgressBar from "screens/GenericProgressBar";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const BirthdateForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { birthDate, setBirthDate } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [birthdateUpdateLoading, setBirthdateUpdateLoading] = useState(false);

  const updateMailingAddress = async () => {
    try {
      setBirthdateUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        birthdate: birthDate,
      };
      await apiBirthDate(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setBirthdateUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainFormContainer}>
        <GenericProgressBar currentStep={7} totalSteps={9} pageTitle="Birthdate" />
        <div className={styles.formQuestionText}>What is your date of birth?</div>
        <div className={styles.formQuestionDetail}>
          We use this information to create your Mastermind Group!
        </div>
        <div className={`${styles.formInputContainer} ${styles.formTextInput}`} style={{ height: "300px" }}>
          <DatePicker
            placeholder="Select Birth Date"
            style={{ marginTop: "10px" }}
            className={`${styles.companyMailingAddressInputBox}`}
            onChange={(e: any) => {
              var dateTime = new Date(e);
              setBirthDate(`${dateTime?.getFullYear()}-${dateTime?.getMonth()}-${dateTime?.getDate()}`);
            }}
            autoFocus={true}
            format="MM/DD/YYYY"
          />
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateMailingAddress}
            loading={birthdateUpdateLoading}
            disabled={birthdateUpdateLoading || !birthDate}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>
          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigateToFormStep(currentForm, "next")}
            loading={birthdateUpdateLoading}
            disabled={birthdateUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BirthdateForm;
