import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { Button } from "antd";
import { apiCompanyIndustry } from "api/account-setup/companyIndustry";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useEffect, useState } from "react";
import { companyIndustriesList } from "screens/AccountSetupNew/AccountSetupForms/CompanyIndustryForm/companyIndustriesList";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";

import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

interface ISubIndType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<any>();

const CompanyIndustryForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { companyIndustry, setCompanyIndustry, companySubIndustry, setCompanySubIndustry } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyIndustryUpdateLoading, setCompanyIndustryUpdateLoading] = useState(false);
  const [industryInputValue, setIndustryInputValue] = useState('');
  const [currentSubIndustries, setCurrentSubIndustries] = useState<ISubIndType[]>([]);
  const [value, setValue] = useState<ISubIndType | null>(null);

  useEffect(() => {
    console.log("companyIndustry : ", companyIndustry, "companySubIndustry : ", companySubIndustry);
  }, [companySubIndustry])

  const updateCompanyIndustry = async () => {
    try {
      setCompanyIndustryUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        industries: companyIndustry,
        subIndustry: companySubIndustry
      };
      await apiCompanyIndustry(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyIndustryUpdateLoading(false);
    }
  };

  return (
    <>
      <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.title}> Company Industry </div>
      </div>

      <ProgressBar currentForm="04"/>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.subtitle}>What industry is your company in?</div>
        <div className={styles.instructionText} style={{marginTop:"5px", marginBottom:"15px"}}> We use this information to create your Mastermind Group! </div>

        <div style={{ height: "40px" }}>
          <div className={styles.inputContainer}>
            <Autocomplete
              value={companyIndustry}
              onChange={(event: any, newValue: string | null) => {
                if (newValue) {
                  setCompanyIndustry(newValue);
                  const industrySubArray = companyIndustriesList.find((il) => il?.title === newValue)?.subIndustries;
                  setCurrentSubIndustries(industrySubArray!);
                }
              }}
              size="small"
              id="free-solo-demo"
              freeSolo={true}
              inputValue={industryInputValue}
              onInputChange={(event, newInputValue) => {
                setIndustryInputValue(newInputValue);
              }}
              options={companyIndustriesList.map((companyIndustryOption) => companyIndustryOption?.title)}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>

        <div className={styles.subtitle}>What sub-industry is your company in?</div>
        <div style={{ height: "80px" }}>
          <div className={styles.inputContainer}>
            <Autocomplete
              size="small"
              disabled={!companyIndustry}
              value={value}
              onChange={(event, newValue: any) => {

                if (typeof newValue === 'string') {
                  setValue({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    title: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
                setCompanySubIndustry(newValue?.inputValue ?? newValue?.title)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={currentSubIndustries}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}/>
              )}
            />
          </div>
        </div>
        <div style={{height: "220px", marginTop: "40px"}}>
        <Button
          type="primary"
          size="large"
          className={styles.accoutSetupNextSectionButton}
          onClick={updateCompanyIndustry}
          loading={companyIndustryUpdateLoading}
          disabled={companyIndustryUpdateLoading || !companyIndustry || !companySubIndustry}
          style={{height:"70px", width:"50vw", position: "fixed", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
        >
          <span className={styles.buttonText}>Next</span>
        </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyIndustryForm;
