import { BoldFont, HorizontalDivider, LeftCenterContainer, Paragraph, TitleLine2 } from "./style";

const FormDisclaimer = () => {
  return (  
    <LeftCenterContainer>
      <BoldFont><TitleLine2>Disclaimer</TitleLine2></BoldFont>
      <br/>
      <Paragraph>The information provided on this application through this diagnostic or otherwise does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only. Information on this application may not constitute the most up-to-date legal or other information. This application contains links to other third-party websites.
  Such links are only for the convenience of the reader, user or browser. Framewrk, Inc., its executives and its employees do not recommend or endorse the contents of the third-party sites.</Paragraph>
      <br/>
      <HorizontalDivider />
      <br/>
      <Paragraph>Clients must accept this before taking the diagnostic.</Paragraph>
      <br/>
      <br/>
    </LeftCenterContainer>
  );
}
 
export default FormDisclaimer;
