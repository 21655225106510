import { useAuth } from "providers/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const { Logout } = useAuth();
    const navigate = useNavigate();

    const logoutAndRedirect = async () => {
        await Logout()
        navigate('/login')
    }

    useEffect(() => {
        logoutAndRedirect();
    }, [])


    return (
        <div></div>
    )
}

export default Logout
