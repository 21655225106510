import { useDrag } from 'react-dnd';
import styled from 'styled-components/macro';
import { ReactComponent as CloseSVG } from '../../../assets/image/close.svg';
import { ReactComponent as UserSVG } from '../../../assets/image/user.svg';
import theme from '../../../core/theme';

const OuterTeamMemberBox = styled.div`
  width: 40%;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 20px;
  background: white;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export default function TeamMember({ teamMembers, setTeamMembers, teamMemberKey }) {
  const [, drag] = useDrag(() => ({
    type: 'Team Members',
    item: { 'memberKey': teamMemberKey },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const handleChange = (key, event) => {
    setTeamMembers({ ...teamMembers, [key]: event.target.value })
  }
  const handleClick = (key) => {
    let newTeamMembers = { ...teamMembers }
    delete newTeamMembers[key]
    setTeamMembers(newTeamMembers)
  }
  return <div style={{ display: 'flex' }}>
    <OuterTeamMemberBox ref={drag}>
      <div style={{ marginLeft: '5px', marginTop: '2px' }}>
        <UserSVG />
      </div>
      <form style={{ width: '100%', height: '22px', marginTop: '6px', marginLeft: '8px' }}>
        <textarea placeholder='Type team member name here' value={teamMembers[teamMemberKey]} onChange={(event) => handleChange(teamMemberKey, event)} style={{ width: '100%', height: '100%' }} />
      </form>
    </OuterTeamMemberBox>
    <button style={{ backgroundColor: theme.background, cursor: 'pointer', marginLeft: '5px', marginBottom: '12px' }} type="button" onClick={() => {
      handleClick(teamMemberKey)
    }}> <CloseSVG /> </button>
  </div>
}
