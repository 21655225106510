import { Slider } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import theme from '../../../core/theme';

export default function Seeker({ value, onChange }) {
  const MySlider = withStyles({
    root: {
      height: 2,
      padding: '5px 0px',
      width: '100%',
    },
    thumb: {
      height: 0,
      width: 0,
      marginTop: -3,
      marginLeft: -4,
      boxShadow: 'none',
      '&:focus, &:hover, &$active': {
        boxShadow: 'none',
      },
    },
    active: {},
    track: {
      height: 4,
      marginTop: -0.8,
      borderRadius: 4,
      backgroundColor: theme.secondary,
    },
    rail: {
      height: 3,
      opacity: 1,
      backgroundColor: '#E4DFD1',
      borderRadius: 4,
    },
    mark: {
      backgroundColor: theme.secondary,
      height: 1,
      width: 1,
      marginTop: 0,
    },
    markActive: {
      opacity: 1,
      backgroundColor: theme.background,
    },
  })(Slider);

  return <MySlider value={value} onChange={onChange} />;
}
