export const Confirm = () => {

    return <div>
        <h1> Confirmation </h1>
            <p>Please confrim the data below</p>
            <ul style={{ marginLeft: '30px'}}>
                <li>Team members information</li>
                <li>Predictive Index Answer</li>
            </ul>
            <p>You can go back to the previous pages by <b>Back</b></p>
            <p>Once confirmed, please press <b>Submit</b> to proceed</p>
    </div>
}
