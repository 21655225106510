import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiAvaInitConfig = async (userAuthToken: string) => {
  try {
    const url = `${REACT_APP_BACKEND_API_URL}/ava-prompts/init-config`;
    const response = await axios.post(url, [], { headers: { Authorization: `Bearer ${userAuthToken}` } });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};
