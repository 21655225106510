import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import styled from 'styled-components/macro';
import { ReactComponent as RecordSvg } from '../../../assets/image/record.svg';
import { ReactComponent as StopRecordingSvg } from '../../../assets/image/stopRecording.svg';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';
import AudioPlayer from './AudioPlayer';

const RecordIcon = styled(RecordSvg)`
  &:hover {
    filter: drop-shadow(0px 0px 5px ${theme.primary});
  }
  transition: 0.3s;
`;

const StopRecordingIcon = styled(StopRecordingSvg)`
  &:hover {
    filter: drop-shadow(0px 0px 5px ${theme.primary});
  }
  transition: 0.3s;
`;

const Container = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  color: '#000';
  font-family: ${font.CircularTTMedium};
  font-size: 14;
  opacity: 49%;
`;

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 0,
  },
}));

export default function MakeRecording({ messages, setMessages, setInputType }) {
  const classes = useStyles();
  let [recordingComplete, setRecordingComplete] = useState(false);
  const {
    setHomeBaseAudioInputToggle,
    setHomeBaseSendToggle,
    setHomeBaseTextInputToggle,
  } = useContext(Context);

  function toggleRecording() {
    status === 'recording' ? stopRecording() : startRecording();
  }

  function recordingStopped() {
    setRecordingComplete(true);
    setHomeBaseSendToggle(true);
  }

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({ audio: true, onStop: recordingStopped });

  function deleteRecording() {
    clearBlobUrl();
    setRecordingComplete(false);
  }

  useEffect(() => {
    setHomeBaseAudioInputToggle(false);
    setHomeBaseTextInputToggle(false);
    recordingComplete
      ? setHomeBaseSendToggle(true)
      : setHomeBaseSendToggle(false);
    return () => {
      setHomeBaseSendToggle(true);
      setHomeBaseAudioInputToggle(true);
      setHomeBaseTextInputToggle(true);
    };
  });

  return (
    <Container>
      {recordingComplete ? (
        <AudioPlayer
          uri={mediaBlobUrl}
          onDelete={deleteRecording}
          messages={messages}
          setMessages={setMessages}
          setInputType={setInputType}
        />
      ) : (
        <>
          <IconButton className={classes.iconButton} onClick={toggleRecording}>
            {status === 'recording' ? <StopRecordingIcon /> : <RecordIcon />}
          </IconButton>
          <Text>
            {status === 'recording'
              ? 'Stop recording'
              : 'Press to begin recording...'}
          </Text>
        </>
      )}
    </Container>
  );
}
