import FramewrkLogo from "assets/image/FwrBlackLineLogo.png";
import LeftSideDetailComponent from './LeftSideDetailComponent';
import RightSideSignupComponent from './RightSideSignupComponent';
import styles from './index.module.scss';

const NewSignUp = () => {
  return (
    <div>
      <div className={styles.fwrAuthHeader}>
        <div className={styles.fwrLogo}>
          <img src={FramewrkLogo} alt="Framewrk Logo" />
        </div>
      </div>
      <div className={styles.fwrkSignup}>
        <LeftSideDetailComponent />
        <RightSideSignupComponent />
      </div>
    </div>
  );
};

export default NewSignUp;
