import { apiAddLegalDiagnosticAnswer } from 'api/diagnostic/legal/addLegalDiagnosticAnswer';
import { useAuth } from 'providers/AuthContext';
import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TopNav from 'screens/Office/Components/TopNav';
import { NotificationPopUp } from '../../../GlobalComponents';
import { Context } from '../../../providers/Context';
import SideBar from '../../Roadmap/SideBar';
import FormButton from './FormButton';
import FormDisclaimer from './FormDisclaimer';
import FormQuestion from './FormQuestion';
import FormTitle from './FormTitle';
import data from './Questions.json';
import ThankYouPage from './ThankYouPage';
import { Background, Container, LeftContainer } from './style';

const FormComponent = () => {
  const { userAuthToken } = useAuth()
  const totalSteps = 9
  const [stepNumber, setStepNumber] = useState(0)
  const [step1Flag, setStep1Flag] = useState(0)
  const [step2Flag, setStep2Flag] = useState(0)
  const [step3Flag, setStep3Flag] = useState(0)
  const [step4Flag, setStep4Flag] = useState(0)
  const [step5Flag, setStep5Flag] = useState(0)
  const [step6Flag, setStep6Flag] = useState(0)
  const [step7Flag, setStep7Flag] = useState(0)
  // const [step8Flag, setStep8Flag] = useState("")
  
  const handleBack = () => {
    if (stepNumber > 0) {
      setStepNumber(stepNumber - 1)
    } else {
      setStepNumber(0)
    }
  }

  const handleNext = async () => {
    if (stepNumber < totalSteps - 1) {
      setStepNumber(stepNumber + 1)
    } else {
        let payload = {
        diagnosticType: "legal",
        answer: [
          data.Questions[0].choice[step1Flag],
          data.Questions[1].choice[step2Flag],
          data.Questions[2].choice[step3Flag],
          data.Questions[3].choice[step4Flag],
          data.Questions[4].choice[step5Flag],
          data.Questions[5].choice[step6Flag],
          data.Questions[6].choice[step7Flag],
        ]
      }
      console.log(payload)
      const response = await apiAddLegalDiagnosticAnswer(userAuthToken, payload)
      console.log(response)
      setStepNumber(stepNumber + 1)
    }
  }

  const {
    closeNotification,
    notification,
    notificationOpen,
  } = useContext(Context);

  return ( 
    <>
        <Background />
        <Container >
            <Outlet />
            <TopNav />
            <SideBar />
            <NotificationPopUp
                close={closeNotification}
                open={notificationOpen}
                {...notification}
            />
            { stepNumber < 8 && <LeftContainer>
              <FormTitle stepNumber={stepNumber} totalSteps={totalSteps}/>
              { stepNumber === 0 && <FormDisclaimer />}
              { stepNumber === 1 && <FormQuestion stepNumber={ stepNumber } flag={step1Flag} setFlag={setStep1Flag}/>}
              { stepNumber === 2 && <FormQuestion stepNumber={ stepNumber } flag={step2Flag} setFlag={setStep2Flag}/>}
              { stepNumber === 3 && <FormQuestion stepNumber={ stepNumber } flag={step3Flag} setFlag={setStep3Flag}/>}
              { stepNumber === 4 && <FormQuestion stepNumber={ stepNumber } flag={step4Flag} setFlag={setStep4Flag}/>}
              { stepNumber === 5 && <FormQuestion stepNumber={ stepNumber } flag={step5Flag} setFlag={setStep5Flag}/>}
              { stepNumber === 6 && <FormQuestion stepNumber={ stepNumber } flag={step6Flag} setFlag={setStep6Flag}/>}
              { stepNumber === 7 && <FormQuestion stepNumber={ stepNumber } flag={step7Flag} setFlag={setStep7Flag}/>}
              {/* { stepNumber === 8 && <FormQuestion stepNumber={ stepNumber } flag={step8Flag} setFlag={setStep8Flag}/>} */}
              <br/>
              <FormButton stepNumber={ stepNumber } handleBack={ handleBack } handleNext={ handleNext }/>
              </LeftContainer> }
            { stepNumber === 8 && <LeftContainer><ThankYouPage/></LeftContainer> }
            {/* <VerticalDivider /> */}
            {/* { stepNumber < 9 && 
              <RightContainer>
                <DocumentTracker stepNumber = {stepNumber} stepFlag = {[step1Flag, step2Flag, step3Flag, step4Flag, step5Flag, step6Flag, step7Flag]}/>
              </RightContainer> } */}
        </Container>
    </>
  );
}
 
export default FormComponent;
