import { CaretLeftFilled } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import FramewrkLogo from "assets/image/FramewrkLogo.png";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'utils/toast';
import { auth, database } from './../../firebase/firebase';
import AgendaProgressBarComponent from './AgendaProgressBarComponent';
import WherebyEmbed from './WherebyEmbed';

import './index.scss';

const meetingAgendas = ['Introductions', 'Meeting Structure', 'App Use', 'Weekly Goals', 'Surprise Experience'];

const VideoMeeting = () => {
    const navigate = useNavigate();
    const [isVideoMeetingLoading, setIsVideoMeetingLoading] = useState(false);

    const [currentSelectedAgendaIndex, setCurrentSelectedAgendaIndex] = useState(0);
    const [currentMeetingRoomUrl, setCurrentMeetingRoomUrl] = useState<string | undefined>();
    const [currentMeetingData, setCurrentMeetingData] = useState<any>(undefined);
    const [isShowVideoEndingConfirmation, setIsShowVideoEndingConfirmation] = useState(false);

    useEffect(() => {
        fetchAndGetVideoMeeting();
    }, []);

    useEffect(() => {
        const currentMeetingRoomDBRef = database.ref('/Meeting/' + currentMeetingRoomUrl);

        currentMeetingRoomDBRef.on(
            'value',
            async (snapshot) => {
                const currentMeetingRoomData = snapshot.val();
                if (currentMeetingRoomData !== null) {
                    if (currentMeetingRoomData?.AgendaTextValue) {
                        const indexOfCurrentAgenda = meetingAgendas?.indexOf(currentMeetingRoomData?.AgendaTextValue);
                        setCurrentSelectedAgendaIndex(indexOfCurrentAgenda);
                    }

                    if (currentMeetingRoomData && currentMeetingRoomData?.isMeetingEnded === true) {
                        toast.success("Meeting ended!");
                        endVideoMeeting();
                    }

                } else if (currentMeetingData?.IsModerator === 1) {
                    const uniqueIdFromRoomUrlWithoutQueryParam = currentMeetingData?.RoomURL.split("?")[0];
                    const uniqueIdFromRoomUrl = uniqueIdFromRoomUrlWithoutQueryParam?.substring(uniqueIdFromRoomUrlWithoutQueryParam?.lastIndexOf('/') + 1);

                    const updatePayload = {
                        "MeetingRoom": currentMeetingData?.MeetingRoom,
                        "MeetingRoomId": uniqueIdFromRoomUrl,
                        "AgendaTextValue": meetingAgendas[0],
                    };
                    try {
                        await database.ref('/Meeting/' + currentMeetingRoomUrl).update(updatePayload);
                    } catch (e) {
                        console.log('Error updating meeting data ', e);
                    }
                }
            },
            (e) => console.log('Issue Loading Current Meeting Data : ', e),
        );
        return () => currentMeetingRoomDBRef.off();
    }, [currentMeetingRoomUrl])


    useEffect(() => {
        const id = setInterval(function () {
            if (currentMeetingData?.MeetingEndTime) {
                const date = new Date(currentMeetingData?.MeetingEndTime);
                if (new Date() >= date && currentMeetingRoomUrl) {
                    endVideoMeeting();
                    clearInterval(id);
                }
                else return
            }
        }, 1000);
        return () => clearInterval(id);
    }, [currentMeetingData]);

    const fetchAndGetVideoMeeting = async () => {
        try {
            setIsVideoMeetingLoading(true);
            const currentMeetingData = await database.ref('/Users/' + auth?.currentUser?.uid + '/CurrentMeetingData/').once('value').then((data) => data.val());
            console.log(auth?.currentUser?.uid, currentMeetingData);
            if(currentMeetingData === null) {
                navigate("/home");
            }
            setCurrentMeetingData(currentMeetingData);

            const uniqueIdFromRoomUrlWithoutQueryParam = currentMeetingData?.RoomURL.split("?")[0];
            const uniqueIdFromRoomUrl = uniqueIdFromRoomUrlWithoutQueryParam?.substring(uniqueIdFromRoomUrlWithoutQueryParam?.lastIndexOf('/') + 1);
            const roomUrlGlobalLink = currentMeetingData?.MeetingDateTime + "/" + uniqueIdFromRoomUrl;

            setCurrentMeetingRoomUrl(roomUrlGlobalLink);
        } catch (e: any) {
            console.log("Error : ", e);
            toast.error(e?.message ?? "Something Went Wrong!");
            navigate('/home');
        } finally {
            setIsVideoMeetingLoading(false);
        }
    }

    const updateCurrentSelectedAgendaIndex = async (selectedAgendaIndex: number) => {
        if (currentMeetingData?.IsModerator === 1) {
            const prevIndex = currentSelectedAgendaIndex;
            setCurrentSelectedAgendaIndex(selectedAgendaIndex);
            try {
                await database.ref('/Meeting/' + currentMeetingRoomUrl).update({ "AgendaTextValue": meetingAgendas[selectedAgendaIndex] });
            } catch (e: any) {
                setCurrentSelectedAgendaIndex(prevIndex);
            }
        }
    }

    const endVideoMeeting = async () => {
        try {
            setIsVideoMeetingLoading(true);
            await database.ref('/Meeting/' + currentMeetingRoomUrl).update({ "isMeetingEnded": true });

            const uniqueIdFromRoomUrlWithoutQueryParam = currentMeetingData?.RoomURL.split("?")[0];
            const uniqueIdFromRoomUrl = uniqueIdFromRoomUrlWithoutQueryParam?.substring(uniqueIdFromRoomUrlWithoutQueryParam?.lastIndexOf('/') + 1);            
            navigate(`/post-meeting-survey?id=${currentMeetingData?.MeetingRoom}&wherebyid=${uniqueIdFromRoomUrl}&agenda=${currentMeetingData?.Agenda}`);
        } catch (e: any) {
            console.log('Error ', e);
            toast.error('Error ending meeting!');
        }
    }

    const logout = async () => {
        navigate("/logout");
    }

    return (
        <>
            <div className="f-video-meeting">
                {isVideoMeetingLoading && currentMeetingData === null ? <div style={{ background: 'black', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" />
                </div> :
                    <>
                        <div className={`f-video-meeting-left-sidebar`}>
                            <div className='f-video-meeting-left-sidebar-opened'>
                                <div className="f-video-meeting-left-sidebar-logo">
                                    <img src={FramewrkLogo} alt="Framewrk Logo" />
                                </div>
                                <div className="f-video-meeting-left-sidebar-agenda">
                                    <div className='f-video-meeting-left-sidebar-agenda-title'>
                                        <div>
                                            Agenda
                                        </div>
                                        <div>
                                            <CaretLeftFilled style={{ color: 'white', fontSize: '20px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <AgendaProgressBarComponent
                                        currentSelectedAgendaIndex={currentSelectedAgendaIndex}
                                        agendas={meetingAgendas}
                                        updateCurrentSelectedAgendaIndex={updateCurrentSelectedAgendaIndex}
                                        isHost={currentMeetingData?.IsModerator === 1}
                                    />
                                </div>
                                <div style={{ position: "absolute", bottom: "0", left: "0", width: "inherit" }}>
                                    {currentMeetingData?.IsModerator === 1 &&
                                        <div className="f-video-meeting-left-sidebar-agenda" onClick={() => setIsShowVideoEndingConfirmation(true)}>
                                            End Meeting
                                        </div>
                                    }
                                    <div className="f-video-meeting-left-sidebar-agenda" onClick={() => logout()}>
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </div>
                        {((currentMeetingData?.IsModerator === 1 && currentMeetingData?.HostRoomURL) || currentMeetingData?.RoomURL) &&
                            <div className={`f-video-meeting-main-container`}>
                                <WherebyEmbed
                                    minimal
                                    room={currentMeetingData?.IsModerator === 1 ? currentMeetingData?.HostRoomURL : currentMeetingData?.RoomURL}
                                    displayName={auth?.currentUser?.displayName ?? auth?.currentUser?.email}
                                    chat={'on'} //off will disable chat
                                    leaveButton={'off'}
                                    background={'off'}
                                />
                            </div>
                        }
                    </>}
            </div>
            <Modal title="Do you wants to End Video Meeting?" open={isShowVideoEndingConfirmation} okText={"End"} cancelText={"No"} onOk={endVideoMeeting} onCancel={() => setIsShowVideoEndingConfirmation(false)}>
                <p>Choose confirm to end the meeting right now, this action can't be undone.</p>
            </Modal>
        </>
    )
}

export default VideoMeeting;
