import { useEffect, useState } from 'react';
import { auth, database } from '../../../../firebase/firebase';
import Content from './Content';
import Rules from './Rules';

const Chat = ({ roomID }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isVisited, setIsVisited] = useState(''); // group chat
    const [isStressHubFirstUser, setIsStressHubFirstUser] = useState('');
    const [users, setUsers] = useState([]);
    const [chatType, setChatType] = useState('');
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        setIsVisited('');
        setIsStressHubFirstUser('');
        setUsers([]);
        setChatType('');
        setMessages([]);
    }, [roomID]);

    useEffect(() => {
        console.log(auth.currentUser.uid)
        const checkVisitedRooms = () => {
            let visitedRooms = localStorage.getItem('VisitedRooms')
            setIsVisited(!!JSON.parse(visitedRooms)?.includes(roomID))
        };
        const checkStressHub = () => {
            database
                .ref('/Users/' + auth.currentUser.uid + '/StressHubRoomID/')
                .once('value')
                .then((snapshot) => setIsStressHubFirstUser(snapshot.val() === null));
        };
        if (roomID === undefined) {
            return;
        }
        Promise.all([
            database
                .ref('/Chat/' + roomID + '/ChatType/')
                .once('value')
                .then((res) => res.val())
                .then(setChatType)
                .catch((e) => console.log('Chat/index.jsx - get ChatType:', e)),
            database
                .ref('/Chat/' + roomID + '/Users/uIDs')
                .once('value')
                .then((snapshot) => {
                    const uIDs = [];
                    snapshot.forEach((snap) => {
                        if (snap.val() !== null) {
                            uIDs.push(snap.val()?.uID);
                        }
                    });
                    let promises = uIDs.map((uID) =>
                        database
                            .ref('/Users/' + uID + '/BasicInfo')
                            .once('value')
                            .then((res) => ({
                                id: uID,
                                data: res.val(),
                            })),
                    );
                    Promise.all(promises).then((results) => setUsers(results));
                })
                .catch((e) => console.log('Chat/index.jsx - get UserInfo:', e)),
            checkVisitedRooms(),
            checkStressHub(),
        ]).then(() => setIsLoading(false));
    }, [roomID]);

    useEffect(() => {
        if (roomID === undefined) {
            return;
        }
        const msgRef = database.ref('/Chat/' + roomID + '/Messages');
        msgRef.on(
            'child_added',
            (snapshot) =>
                setMessages((prev) => [
                    ...prev,
                    { key: snapshot.key, data: snapshot.val() },
                ]),
            (e) => console.log('Chat/index.jsx - msgRef listener: ', e),
        );
        return () => msgRef.off();
    }, [roomID]);

    const _openLeftDrawer = () => { }// navigation.dangerouslyGetParent().openDrawer();
    const _openRightDrawer = () => { }// navigation.openDrawer();

    const _onEnter = () => {
        localStorage.getItem('VisitedRooms').then((visitedRooms) => {
            let p;
            if (visitedRooms === null) {
                p = [roomID];
            } else {
                p = JSON.parse(visitedRooms).concat(roomID);
            }
            localStorage.setItem('VisitedRooms', JSON.stringify(p)).then(() =>
                setIsVisited(true),
            );
        });
    };

    const _onStressHubEnter = () => {
        database
            .ref('/Users/' + auth.currentUser.uid)
            .update({
                StressHubRoomID: roomID,
            })
            .then(() => {
                setIsStressHubFirstUser(false);
            })
            .catch((e) => console.log('Chat/index.jsx - onStressHubEnter', e));
    };

    const _onSendMessage = async (text, respType) => {
        try {
            let msgContent = text;
            if (respType === 'Audio') {
                // msgContent = await fileUpload(text, 'responses');
            }

            database.ref('/Chat/' + roomID + '/Messages/').push({
                Content: msgContent,
                Type: respType,
                Sender: auth.currentUser.uid,
                SentDate: Date.now(),
            });
        } catch (e) {
            console.log('chat/index.js - file upload failed:', e);
        }
    };

    const _getRoomContent = () => {
        // chatType is one of 1v1Chat or StressHub or <theme> name
        if (
            chatType !== '1v1Chat' &&
            chatType !== 'StressHub' &&
            isVisited === false
        ) {
            return <Rules _onEnter={_onEnter} />;
        }
        return <Content messages={messages} users={users} />;
    };

    const goBack = () => {
        // navigation.goBack();
        // _openLeftDrawer();
    };

    //   if (isLoading) {
    //     return <Loading />;
    //   }
    if (chatType === 'StressHub' && isStressHubFirstUser === true) {
        return (
            <></>
            //   <StressHubWelcome
            //     roomID={roomID}
            //     _onStressHubEnter={_onStressHubEnter}
            //     goBack={goBack}
            //   />
        );
    }

    return (
        <>
            <Content messages={messages} users={users} />
        </>
    );
};


export default Chat;
