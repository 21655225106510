import Grid from '@mui/material/Grid';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';

const DCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
    min-height: 500px;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.161));
`

const Bar = styled.div`
    background-color: ${theme.primary};
    height: 55px;
    width: 10px;
    position: absolute;
    top: 40px;
    left: 20px;
`

const Title = styled.h2`
    font-family: ${font.WorkSansMedium};
    font-size: 20px;
    color: ${theme.primary};
`

const DeliverableTitle = styled.h3`
    font-family: ${font.WorkSansSemiBold};
    font-size: 25px;
`

const SubTitle = styled.h4`
    font-family: ${font.WorkSansSemiBold};
    font-size: 14px;
    margin-top: 30px;
`

const Text = styled.p`
    font-family: ${font.CircularTTMedium};
    font-size: 18px;
    opacity: 69%;
`

export default function Deliverable({ location }) {

    const { setExpandHomeBase } = useContext(Context)

    useEffect(() => {
        setExpandHomeBase(false)
        if (location.state) {
            setExpandHomeBase(false)
        }
    }, [])

    return (
        location.state ? (
            <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="center"
                style={{ height: "100%" }}
            >
                <Grid item md={8}>

                    <DCard>
                        <Bar />
                        <Title>Workshop</Title>
                        <DeliverableTitle>FAQ</DeliverableTitle>
                        {location.state.map(({ Q, A }) => {
                            return (
                                <>
                                    <SubTitle>Q: {Q}</SubTitle>
                                    <Text>A: {A}</Text>
                                </>
                            )
                        }
                        )}
                    </DCard>
                </Grid>
            </Grid>
        ) : null
    )
}


