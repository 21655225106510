import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiUpdateStripeSubscription = async (authToken: string, cusId: string, subId: string, updateData: any) => {
    try {
        const url = `${REACT_APP_BACKEND_API_URL}/stripe/update-subscription`;
        const response = await axios.post(url, { subId, cusId, updateData }, { headers: { Authorization: `Bearer ${authToken}` } });
        return response?.data;
    } catch (e: any) {
        throw e?.response?.data ? e?.response?.data : e;
    }
};
