import styled from 'styled-components/macro'

// Container for holding NextButton. 

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 20%;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default ButtonContainer