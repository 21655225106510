import styled from 'styled-components/macro';

const Container = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 20px 0;
`;

export default Container;
