import { NotificationPopUp } from 'GlobalComponents';
import { Context } from 'providers/Context';
import { useContext } from 'react';
import { Outlet } from "react-router-dom";
import styled from 'styled-components/macro';
import TopNav from './Components/TopNav';
import Homebase from './Homebase';

const Container = styled.div`
  overflow-x: hidden;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;


const Office = () => {
  const { notification, closeNotification, notificationOpen } = useContext(Context);

  return (
    <Container>
      <Outlet />
      <TopNav />
      <Homebase />
      <NotificationPopUp
        close={closeNotification}
        open={notificationOpen}
        {...notification}
      />
    </Container>
  );
};

export default Office;
