import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import * as font from '../../../core/font';
import theme from '../../../core/theme';

const useStyles = makeStyles({
  root: {
    width: '100%',
    color: 'white',
    background: theme.secondary,
    zIndex: '2',
    fontFamily: font.WorkSansSemiBold,
    fontWeight: '15px',
    // $disabled is a reference to the local disabled
    // rule within the same style sheet.
    // By using &, we increase the specificity.
    '&$disabled': {
      color: 'white',
      boxShadow: 'none',
      fontFamily: font.WorkSansSemiBold,
      fontWeight: '15px',
    },
  },
  disabled: {},
});

export default function SingleChoice({ options, id, _onSendMessage }) {
  const classes = useStyles();

  function handleClick(choice) {
    // let chosenMessage = messages[messages.length - 1];
    // chosenMessage.options = [choice];
    // let newMessages = [...messages];
    // newMessages[messages.length - 1] = chosenMessage;
    // setMessages(newMessages);
    _onSendMessage(id, choice, 'SingleChoice');
  }

  return options.map((choice, i) => (
    <Button
      onClick={() => handleClick(choice)}
      key={choice}
      disabled={options.length === 1}
      style={{
        marginBottom: i === options.length - 1 ? '0px' : '10px',
      }}
      classes={{
        root: classes.root,
        disabled: options.length === 1 ? classes.disabled : null,
      }}
    >
      {choice}
    </Button>
  ));
}
