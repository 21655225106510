import styled from 'styled-components/macro';
import * as font from '../../../core/font';
import theme from '../../../core/theme';

export const Background = styled.div`
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: ${theme.background};
  z-index: -100;
`;

export const Paragraph = styled.div`
  font-size: 14px;
  color: #707070;
`;

export const Detail1 = styled.div`
  font-size: 12px;
`;

export const Detail2 = styled.div`
  font-size: 13px;
  width: 200px;
`;

export const Question = styled.div`
  font-size: 17px;
  color: #707070;
`;

export const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 50px);
`;

export const LeftContainer = styled.div`
  width: 45%;
  margin-left: 69.5px;
  margin-top: 42.25px;
`;

export const LeftTopContainer = styled.div`
  height: 15%;
  border-bottom: 1px solid #b9b9b9;
`;

export const LeftCenterContainer = styled.div`
  height: 65%;
  margin-top: 30px;
  margin-left: 60px;
  margin-right: 60px;
`;

export const LeftBottomContainer = styled.div`
  height: 10%;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
`;

export const BackButtonContainer = styled.div`
  margin-left: 30px;
  float: left;
`;

export const NextButtonContainer = styled.div`
  margin-right: 30px;
  float: right;
`;

export const RightContainer = styled.div`
  width: 48%;
  margin-top: 250px;
  display: flex;
`;

export const HorizontalDivider = styled.div`
  background-color: #b9b9b9;
  height: 0.5px;
`;

export const VerticalDivider = styled.div`
  background-color: #b9b9b9;
  width: 1px;
  margin-top: 40px;
  margin-left: 50px;
`;

export const BoldFont = styled.div`
  font-weight: bold;
`;

export const TitleLine1 = styled.div`
  font-size: 20px;
`;

export const TitleLine2 = styled.div`
  font-size: 25px;
`;

export const GreenFont = styled.div`
  color: ${theme.primary};
`;

// this is for cross button
export const OuterDivisionBox = styled.div`
  width: 120px;
  height: 120px;
  background: ${theme.backgroundColor};
  display: inline-flex;
  position: relative;
`;

export const DivisionBox = styled.form`
  width: 100px;
  height: 100px;
  background: white;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.button`
  background-color: #CFCF22;
  border: none;
  color: #4A4A4A;
  padding: 20px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  font-weight: bold;
  margin-bottom: 80px;
  cursor: pointer;
`;

export const BackArrowContainer = styled.button`
  align-items: center;
  background-color: #E4DFCF;
  outline: none;
  border-radius: 15px;
  box-shadow: 0 0 10px #E4DFCF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-left: auto;
`;

export const NextArrowContainer = styled.button`
  align-items: center;
  background-color: ${theme.secondary};
  outline: none;
  border-radius: 15px;
  box-shadow: 0 0 10px ${theme.secondary};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-left: auto;
`;

export const ToggleButtonContainer = styled.div`
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ToggleJoinButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.selected ? theme.secondary : `inherit`};
  outline: none;
  border-radius: 15px;
  box-shadow: ${(props) =>
    `0 0 ${props.selected ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 230px;
  animation: fadeIn ease .5s;
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
`;

export const ToggleButtonText = styled.span`
  color: ${(props) => props.selected ? `white` : `black`};
  opacity: ${(props) => props.selected ? `100%` : `49%`};
  font-family: ${font.WorkSansSemiBold};
  font-size: 20px;
  margin-right: 10px;
`;

export const ToggleButtonContainerMultiple = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ToggleJoinButtonMultiple = styled.button`
  align-items: center;
  background-color: ${(props) => props.selected ? theme.secondary : `inherit`};
  outline: none;
  border-radius: 15px;
  box-shadow: ${(props) =>
    `0 0 ${props.selected ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 300px;
  animation: fadeIn ease .5s;
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
`;

export const ToggleButtonTextMultiple = styled.span`
  color: ${(props) => props.selected ? `white` : `black`};
  opacity: ${(props) => props.selected ? `100%` : `49%`};
  font-family: ${font.WorkSansSemiBold};
  font-size: 15px;
`;

export const FreeTextContainer = styled.textarea`
  width: 100%;
  height: 180px;
  padding: 14px 17px 14px 17px;
  box-sizing: border-box;
  background: #FFFFFF;
  color: #707070;
  border: 0.5px solid #707070;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
`;

export const CategoryContainer = styled.div`
  width: 270px;
  height: 90%;
`;

export const DocumentContainer = styled.div`
  width: 250px;
  height: 65px;
  background: #FFFFFF;
  color: #707070;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
`;

export const DocumentTextContainer = styled.div`
  width: 180px;
  height: 50px;
  background: #FFFFFF;
  color: #707070;
  margin-left: 10px;
`;
