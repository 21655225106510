import styled from 'styled-components/macro';
import RaisingMoneyCase1 from "../../../assets/image/diagnostic_welcome/RaisingMoneyCase1.svg";
import RaisingMoneyCase2 from "../../../assets/image/diagnostic_welcome/RaisingMoneyCase2.svg";
import RaisingMoneyCase3 from "../../../assets/image/diagnostic_welcome/RaisingMoneyCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Finance";
const DiagnosticSub = "(Raising Money)";

const DiagnosticTime = "4";

const DiagnosticURL = "";

const VideoURL = "https://www.loom.com/embed/495d923ecd654a78bd220340c3335f18?sid=e8a736ca-76ab-4464-a01a-5e6f031eb706"

const QuoteContent = "\"Pissed off. I get it that it’s helpful to know the blocks in my way of getting money, but I wish I’d known these things before, or at least that people meant what they said. It makes me a little jaded with investors but I’m happy I know what I’m up against. It makes it a little better to handle moving forward.\"";

const ScrollTitleText = "Raising Money";

const ScrollContent = "There are countless articles, interviews, podcasts, resources, etc. on the internet about what it takes to get an investment for early-stage companies. They discuss things like your team, product, product-market fit, industry, barriers to entry, how to craft the right pitch deck, etc. Over 90% of early stage companies set off and use that information to develop their story, craft their pitch deck, and build relationships with investors. They follow the formula and they use the templates, however, less than 5% of companies ever get funded by Angels, VCs, etc.\n\n\
There has to be information that entrepreneurs are missing, there have to be things that we’re not being told. That’s what this diagnostic seeks to share, to provide you with more clarity, with the hopes of increasing your likelihood of getting the funding your business needs to launch, grow, or scale.\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const SecretHacks = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const BiasesInFundraising = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const FundraisingPlaybook = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of raising capital under changing market conditions.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='RaisingMoneyCase1' src={RaisingMoneyCase1} width={100} style={{margin: "auto"}}/>
          <a href={SecretHacks}>Download 'Secret Hacks from Funded Founders'</a>
        </Case>
        <Case>
          <img alt='RaisingMoneyCase2' src={RaisingMoneyCase2} width={100} style={{margin: "auto"}}/>
          <a href={BiasesInFundraising}>Download <br/> 'Biases in Fundraising'</a>
        </Case>
        <Case>
          <img alt='RaisingMoneyCase3' src={RaisingMoneyCase3} width={100} style={{margin: "auto"}}/>
          <a href={FundraisingPlaybook}>Download <br/> 'Fundraising Playbook'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function FinanceRaisingMoneyDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName}
    DiagnosticSub = {DiagnosticSub}
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollTitleText={ScrollTitleText} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
