import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiAvaGetAnswer = async (authToken: string, payload: any) => {
  try {
    const url = `${REACT_APP_BACKEND_API_URL}/ava/get-answer`;
    const response = await axios.post(url, payload, { headers: { Authorization: `Bearer ${authToken}` } });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};
