// Container to hold a form. Fills its parent and adds y margins at 10%

import styled from 'styled-components/macro';

const FormContainer = styled.form`
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
  width: 100%;
`;

export default FormContainer