
import { Spin } from 'antd';
import { apiUserSurveryFilledData } from 'api/account-setup/userSurveryFilledData';
import ArrowBack from "assets/image/back-arrow-black.png";
import { IFormStep } from "interface";
import { useAuth } from 'providers/AuthContext';
import { AppContext } from 'providers/app-context';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountSetupForms } from "utils/constants";
import BirthdateForm from '../AccountSetupForms/BirthdateForm';
import CompanyIndustryForm from '../AccountSetupForms/CompanyIndustryForm';
import CompanyOverviewForm from '../AccountSetupForms/CompanyOverviewForm';
import CompanyStageForm from '../AccountSetupForms/CompanyStageForm';
import FounderGenderForm from '../AccountSetupForms/FounderGenderForm';
import FounderRaceForm from '../AccountSetupForms/FounderRaceForm';
import MailingAddressForm from '../AccountSetupForms/MailingAddressForm';
import styles from "./index.module.scss";

const RightSideLoginComponent = () => {
  const { userAuthToken } = useAuth();
  const [currentStep, setCurrentStep] = useState<IFormStep>(AccountSetupForms.STEP1);
  const [surveryLoading, setSurveryLoading] = useState(false);
  const { setCompanyStage, setCompanyIndustry, setFounderGender, setFounderRace } = useContext(AppContext);
  const navigate = useNavigate();

  const navigateToFormStep = (currentForm: IFormStep, direction: "next" | "prev") => {
    if (direction === "next" && currentForm?.nextStep) {
      const formValueArrary = Object.values(AccountSetupForms);
      const nextStep = formValueArrary.find((value: IFormStep) => value?.index === currentForm?.nextStep);
      setCurrentStep(nextStep);
    } else if (direction === "prev" && currentForm?.prevStep) {
      const formValueArrary = Object.values(AccountSetupForms);
      const prevStep = formValueArrary.find((value: IFormStep) => value?.index === currentForm?.prevStep);
      setCurrentStep(prevStep);
    }
  };

  const getAndLoadSurveryQuestionData = async () => {
    setSurveryLoading(true);
    const userSurveryFilledQuestion = await apiUserSurveryFilledData(userAuthToken);
    if (userSurveryFilledQuestion?.data?.isComplete) {
      navigate('/choose-your-office');
    } else {
      const surverySteps = userSurveryFilledQuestion?.data?.step;
      setCompanyStage(surverySteps?.CompanyStage);
      setCompanyIndustry(surverySteps?.CompanyIndustry);
      setFounderGender(surverySteps?.Gender);
      setFounderRace(surverySteps?.FounderRace);

      if (surverySteps?.CompanyStage === undefined) {
        setCurrentStep(AccountSetupForms.STEP1);
      } else if (surverySteps?.CompanyIndustry === undefined) {
        setCurrentStep(AccountSetupForms.STEP2);
      } else if (surverySteps?.Gender === undefined) {
        setCurrentStep(AccountSetupForms.STEP3);
      } else if (surverySteps?.FounderRace === undefined) {
        setCurrentStep(AccountSetupForms.STEP4);
      } else {
        setCurrentStep(AccountSetupForms.STEP5);
      }
    }
    setSurveryLoading(false);
  }

  useEffect(() => {
    getAndLoadSurveryQuestionData();
  }, []);

  return (
    <div className={styles.rightSideDetailComponent}>
      {currentStep?.prevStep && 
      <div className={styles.backButton} onClick={()=>navigateToFormStep(currentStep, "prev")}>
        <img src={ArrowBack} alt="back" />
      </div>
      }
      <div className={styles.accountSetupContainer}>

        <div>
          {surveryLoading ? <>
            <div className={styles.accountSetupMainSectionLoaderContainer}>
              <Spin size='large'></Spin>
            </div>
          </> : <>

            {/* STEP1 */}
            {currentStep?.index === "01" && (
              <CompanyOverviewForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
            )}

            {currentStep?.index === "02" && (
              <CompanyStageForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
            )}

            {/* STEP2 */}
            {currentStep?.index === "03" && (
              <CompanyIndustryForm
                currentForm={currentStep}
                navigateToFormStep={navigateToFormStep}
              />
            )}

            {/* STEP3 */}
            {currentStep?.index === "04" && (
              <FounderGenderForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
            )}

            {/* STEP4 */}
            {currentStep?.index === "05" && (
              <FounderRaceForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} />
            )}

            {currentStep?.index === "06" && (
              <BirthdateForm
                currentForm={currentStep}
                navigateToFormStep={navigateToFormStep}
              />
            )}

            {/* STEP5 */}
            {currentStep?.index === "07" && (
              <MailingAddressForm
                currentForm={currentStep}
                navigateToFormStep={navigateToFormStep}
              />
            )}

          </>}
        </div>
      </div>
    </div>
  )
}

export default RightSideLoginComponent
