import styled from 'styled-components/macro';
import TeamCase1 from "../../../assets/image/diagnostic_welcome/TeamCase1.svg";
import TeamCase2 from "../../../assets/image/diagnostic_welcome/TeamCase2.svg";
import TeamCase3 from "../../../assets/image/diagnostic_welcome/TeamCase3.svg";
import TeamCase4 from "../../../assets/image/diagnostic_welcome/TeamCase4.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Team";

const DiagnosticTime = "6";

const DiagnosticURL = "/office/diagnostic/team/invitation";

const VideoURL = "https://www.loom.com/embed/300225bd026549b191a687e88033226b?sid=61cebd94-e6de-4cc7-b521-55fd1c942a2e"

const QuoteContent = "\"I feel like there are some things I wasn't expecting to learn about myself, like who I am as a leader, my management style, and what motivates me to be productive. More than that, I feel like I know how to motivate my team better and what types of people I should have on my team as we grow.\"";

const DescriptionContent = "Whether you're a company of one, or more; the Team Diagnostic will change the way you look at managing yourself and the individuals on your team, or the individuals you need as your team expands.\n\n\
Your team is the most important aspect of running your business. You never want the wrong people working in the wrong roles. Building a company based on a great idea with the wrong people is the same as (if not worse than) having a bad idea. Most of the challenges in working with people aren't because they're stupid or incompetent. It's likely because either us or them are simply in the wrong role or the wrong company culture.\
";

const ScrollContent = "The Team Diagnostic analyzes who you are as a leader and the dynamics of your team's personalities.\n\n\
For you, the Team Diagnostic:\n\
• Identifies your behavior style\n\
• Analyzes your management style\n\
• Synthesizes how you influence others\n\
• Provides personal development strategies\n\n\
For your team, the Team Diagnostic:\n\
• Identifies everyone's strongest behaviors\n\
• Analyzes what motivates different team members\n\
• Summarizes everyone's work styles and ways to work interact together better\n\n\
If you've never been taught how to build yourself up or your team, then you'll settle for second and third-rate performance. You'll find yourself rushing hiring decisions for the sake of speed and it will end up complicating things down the line. Time is rarely on your side, so it's important to move as quickly as possible. However, that can lead to making poor hiring decisions and problems that start out small with communication, clarity on roles, decision-making authority, etc. can compound into larger issues to deal with later.\
";

const BehaviorReport = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const ManagementReport = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const PersonalDevelopmentReport = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const WorkStylesReport = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 500px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 150px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;

const ScrollContainer = styled.div`
  width: 520px;
  margin-top: 30px;
  border-radius: 20px;
  background-color: #fbf8f0;
  box-shadow: 6px 6px 10px 7px #c7c2b5;
  padding: 15px 15px 20px 20px;
`;

const ScrollTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const ScrollText = styled.div`
  margin-top: 8px;
  font-size: 13px;
  font-weight: 300;
  overflow: auto;
  max-height: 180px;
  text-align: left;
`;

function RightBottomComponent () {
  return (
    <div>
      <center><CasesContainer>
        <Case>
          <img alt='teamCase1' src={TeamCase1} width={100} style={{margin: "auto"}}/>
          <a href={BehaviorReport}>View <br/> Behavior Report</a>
        </Case>
        <Case>
          <img alt='teamCase2' src={TeamCase2} width={100} style={{margin: "auto"}}/>
          <a href={ManagementReport}>View <br/> Management Report</a>
        </Case>
        <Case>
          <img alt='teamCase3' src={TeamCase3} width={100} style={{margin: "auto"}}/>
          <a href={PersonalDevelopmentReport}>View Personal <br/> Development Report</a>
        </Case>
        <Case>
          <img alt='teamCase4' src={TeamCase4} width={100} style={{margin: "auto"}}/>
          <a href={WorkStylesReport}>View Work Styles/ <br/> Interactions Report</a>
        </Case>
      </CasesContainer>
      <ScrollContainer>
        <ScrollTitle>The Truth About Teams</ScrollTitle>
        <ScrollText>
          About 13 percent of failed companies state that team conflicts were a reason they failed.<br/>
          • Every day isn't perfect, but understanding how to minimize team conflict and improve interactions can help boost your chances of reaching your goals.<br/>
          <a href="https://www.entrepreneur.com/leadership/a-new-study-reveals-the-20-factors-that-predict-startup/308447#:~:text=Your%20team%20is%20the%20driving,an%20ingredient%20in%20their%20failure.">Source</a><br/><br/>
          Team members spend 80% of their time in meetings, on the phone, and responding to emails.<br/>
          • That leaves “little time for all the critical work they must complete on their own.” It's important for that time to be productive.<br/>
          <a href="https://hbr.org/2016/01/collaborative-overload">Source</a><br/><br/>
          “Entrepreneurs spend about 40% of their working hours on tasks that do not generate income such as hiring, HR tasks, and payroll.”<br/>
          • Time is not on your side so the people you have on your team can either give you more, or take up more.<br/>
          <a href="https://www.embroker.com/blog/startup-statistics/">Source</a><br/><br/>
          27.1 million startups and small businesses out of a total of 33.2 million in the United States are run by one person and have no employees.<br/>
          • Think of Framewrk not only as the company to help you get to hiring more people, but as an extension of your team so you don't have to go through everything alone.<br/>
          <a href="https://www.forbes.com/advisor/business/small-business-statistics/">Source</a><br/><br/>
          Over the last 25 years, startups and small businesses have added over 12.9 million jobs<br/>
          • Don't let anyone tell you that what you and the team you build are unimportant. Companies like yours are growing and contributing to about two-thirds of the jobs in the United States economy. It's important that your team is aligned.<br/>
          <a href="https://www.forbes.com/advisor/business/small-business-statistics/">Source</a><br/><br/>
        </ScrollText>
      </ScrollContainer></center>
    </div>
  );
}

export default function TeamDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent}
    DescriptionContent={DescriptionContent}
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    RightBottomComponent={RightBottomComponent}
  />
}
