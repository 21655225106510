import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { ConfigProvider } from "antd";
import 'antd/dist/reset.css';
import axios from 'axios';
import { useAuth } from 'providers/AuthContext';
import { AppContextProvider } from 'providers/app-context';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import AccountPage from 'screens/Account/account-page';
import AccountSetupNew from 'screens/AccountSetupNew';
import ChooseOffice from 'screens/ConfigureOffice/ChooseOffice';
import BrandingDiagnostic from 'screens/Diagnostic/BrandingDiagnostic';
import CompetitorsDiagnostic from 'screens/Diagnostic/CompetitorsDiagnostic';
import CompetitorsDiagnosticSelection from 'screens/Diagnostic/CompetitorsDiagnostic/CompetitorsDiagnosticSelection';
import FinanceDiagnostic from 'screens/Diagnostic/FinanceDiagnostic';
import FinanceManagingTaxesDiagnostic from 'screens/Diagnostic/FinanceDiagnostic/ManagingTaxesWelcomePage';
import FinanceProfitabilityDiagnostic from 'screens/Diagnostic/FinanceDiagnostic/ProfitabilityWelcomePage';
import FinanceRaisingMoneyDiagnostic from 'screens/Diagnostic/FinanceDiagnostic/RaisingMoneyWelcomePage';
import LegalDiagnostic from 'screens/Diagnostic/LegalDiagnostic';
import MarketingDiagnostic from 'screens/Diagnostic/MarketingDiagnostic';
import OperationsDiagnostic from 'screens/Diagnostic/OperationsDiagnostic';
import OperationsDiagnosticQuestionnaire from 'screens/Diagnostic/OperationsDiagnostic/Questionnaire';
import RiskDiagnostic from 'screens/Diagnostic/RiskDiagnostic';
import SelfDiagnostic from 'screens/Diagnostic/SelfDiagnostic';
import TeamDiagnostic from 'screens/Diagnostic/TeamDiagnostic';
import TeamDiagnosticInvitation from 'screens/Diagnostic/TeamDiagnostic/Invitation';
import Home from 'screens/Home';
import Logout from 'screens/NewAuth/Logout';
import NewLogin from 'screens/NewAuth/NewLogin';
import NewSignUp from 'screens/NewAuth/NewSignup';
import Office from 'screens/Office';
import BackgroundVideo from 'screens/Office/Components/BackgroundVideo';
import Roadmap from 'screens/Roadmap';
import SignUpMobile from 'screens/SignUpMobile';
import VideoMeeting from 'screens/VideoMeeting';
import PrivateRoute from './GlobalComponents/PrivateRoute';
import theme from './core/theme';
import NotFound from './screens/404';
import Guest from './screens/Account/guest';
import LegalDiagnosticSelection from './screens/Diagnostic/LegalDiagnostic/Form';
import SelfDiagnosticSelection from './screens/Diagnostic/SelfDiagnostic/Form';
import UpdateProfile from './screens/UpdateProfile';
import PostMeetingSurvey from './screens/VideoMeeting/PostMeetingSurvey';
import ScheduleVideoMeeting from './screens/VideoMeeting/ScheduleVideoMeeting';

const TermsOfUse = () => {
  window.location.replace('https://www.framewrkagency.com/privacypolicy');
  return null;
};

// Theme factory
function createMyTheme(options) {
  return createTheme({
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    overrides: {
      MuiPickersBasePicker: {
        container: {
          backgroundColor: "#fff",
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: "#fff"
        }
      },
    },
    ...options
  })
};
// Initialize theme
const MuiTheme = createMyTheme({
  palette: {
    background: {
      default: theme.background,
    },
    primary: {
      main: theme.primary
    },
    secondary: {
      main: theme.secondary
    }
  },
});

// Mobile view of website
let handleScreenSize = null;
if (window.innerWidth <= 1375) {
  handleScreenSize = "signup-mobile"
} else {
  handleScreenSize = "signup"
}

/*if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    console.log("Using a Mobile Device");
    handleScreenSize = "/signup-mobile"
} else {
    console.log("Using Desktop");
    handleScreenSize = "/signup"
}
*/
function App() {
  const navigate = useNavigate();
  const { Logout: LogoutFunction } = useAuth();

  useEffect(() => {
    interceptAxiosResponse();
  }, []);

  const interceptAxiosResponse = () => {
    axios.interceptors.response.use((response) => response, async (error) => {
      const errorResponse = error.response;
      console.log('Axios intercepted error code ', errorResponse.status);
      if (errorResponse && errorResponse.status === 401) {
        await LogoutFunction();
        navigate('/login');
      }

      return Promise.reject(error);
    })
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#CFCF22",
          },
        }}
      >
        <AppContextProvider>
          <Routes>
            <Route exact path="/" element={<Navigate to={handleScreenSize} replace />} />
            <Route exact path="/signup" element={<NewSignUp />} />
            <Route exact path="/signup-mobile" element={<SignUpMobile />} />
            <Route exact path="/login" element={<NewLogin />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
            <Route exact path="/account/:email" element={<Guest />} />
            <Route path="/" element={<PrivateRoute />}>
              {/* <Route path="account-setup" element={<AccountSetup />} /> */}
              <Route path="account-setup" element={<AccountSetupNew />} />
              <Route exact path="/choose-your-office" element={<ChooseOffice />} />
              <Route exact path="/update-profile" element={<UpdateProfile />} />

              <Route path="/office" element={<Office />} >
                <Route path={"/office/roadmap/*"} element={<Roadmap />}></Route>
                <Route exact path="/office/diagnostic/operations" element={<OperationsDiagnostic />} />
                <Route exact path="/office/diagnostic/operations/questionnaire" element={<OperationsDiagnosticQuestionnaire />} />
                <Route exact path="/office/diagnostic/team" element={<TeamDiagnostic />} />
                <Route exact path="/office/diagnostic/team/invitation" element={<TeamDiagnosticInvitation />} />
                <Route exact path="/office/diagnostic/branding" element={<BrandingDiagnostic />} />
                <Route exact path="/office/diagnostic/competitors" element={<CompetitorsDiagnostic />} />
                <Route exact path="/office/diagnostic/competitors/form" element={<CompetitorsDiagnosticSelection />} />
                <Route exact path="/office/diagnostic/finance" element={<FinanceDiagnostic />} />
                <Route exact path="/office/diagnostic/finance/profitability" element={<FinanceProfitabilityDiagnostic />} />
                <Route exact path="/office/diagnostic/finance/raising-money" element={<FinanceRaisingMoneyDiagnostic />} />
                <Route exact path="/office/diagnostic/finance/managing-taxes" element={<FinanceManagingTaxesDiagnostic />} />
                <Route exact path="/office/diagnostic/legal" element={<LegalDiagnostic />} />
                <Route exact path="/office/diagnostic/legal/form" element={<LegalDiagnosticSelection />} />
                <Route exact path="/office/diagnostic/marketing" element={<MarketingDiagnostic />} />
                <Route exact path="/office/diagnostic/risk" element={<RiskDiagnostic />} />
                <Route exact path="/office/diagnostic/self" element={<SelfDiagnostic />} />
                <Route exact path="/office/diagnostic/operations" element={<OperationsDiagnostic />} />
                <Route exact path="/office/diagnostic/self/form" element={<SelfDiagnosticSelection />} />
              </Route>

              <Route path='/home' element={<Home />} >
                <Route exact path={"/home"} element={<BackgroundVideo />} />
              </Route>

              <Route path="/account" element={<AccountPage />} />
              <Route path="/video-meeting" element={<VideoMeeting />} />
              <Route path="/post-meeting-survey" element={<PostMeetingSurvey />} />
              <Route path="/schedule-weekly-meeting" element={<ScheduleVideoMeeting />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </AppContextProvider>
      </ConfigProvider>
    </ThemeProvider >
  );
}

export default App;
