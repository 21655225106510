import { Progress } from '../Progress';
import { BoldFont, LeftTopContainer, TitleLine1 } from "./style";

const FormTitle = ({ stepNumber, totalSteps }) => {
  return ( 
    <LeftTopContainer>        
      <BoldFont><center><TitleLine1> Self Diagnostic </TitleLine1></center></BoldFont>
      <br/>
      { Progress(stepNumber, totalSteps) }
    </LeftTopContainer>
  );
}
 
export default FormTitle;
