import { DialogContent } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';
import * as font from '../../../core/font';
import theme from '../../../core/theme';

const Header = styled.div`
  text-align: center;
  padding: 10px;
  color: ${theme.primary};
  font-family: ${font.WorkSansMedium};
  font-size: 26px;
`;

const Body = styled.div`
  padding: 10px 20px;
  font-family: ${font.CircularTTMedium};
  font-size: 16px;
`;

export default function FeatureUnavailable() {
  return (
    <DialogContent>
      <Header>Coming Soon!</Header>
      <Body>This feature will be available in a future update</Body>
    </DialogContent>
  );
}
