import FramewrkLogo from "assets/image/FwrBlackLineLogoHigh.png";
import styles from './index.module.scss';


const LeftSideDetailComponent = () => {
  return (
    <div className={styles.leftSideDetailComponent}>
      <div className={styles.logo}>
        <img src={FramewrkLogo} alt="logo" />
      </div>
      <div className={styles.desc}>
        Thank you so much for testing Framewrk and giving feedback. Honestly, our entire team is grateful and honored. Here we go:
      </div>
      <div className={styles.instructionsTitle}>Instructions</div>

      <div style={{ display: "flex", flexDirection: "column", width: "617px" }}>
        <div className={styles.instruction}>
          <div className={styles.instructionNumber}>1</div>
          <div className={styles.instructionDetail}>Sign-up</div>
        </div>

        <div className={styles.instruction}>
          <div className={styles.instructionNumber}>2</div>
          <div className={styles.instructionDetail}>Create your account</div>
        </div>

        <div className={styles.instruction}>
          <div className={styles.instructionNumber}>3</div>
          <div className={styles.instructionDetail}>Go through Framewrk as you want!</div>
        </div>

      </div>
      <div className={styles.footerText}>If you can screen record while you go through the experience the first time, that would be great.</div>

    </div>
  );
};

export default LeftSideDetailComponent;
