import { Button } from "antd";
import { apiCompanyIndustry } from "api/account-setup/companyIndustry";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";

import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import GenericProgressBar from "screens/GenericProgressBar";
import styles from "./../index.module.scss";
import { companyIndustriesList } from "./companyIndustriesList";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

interface ISubIndType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<any>();

const CompanyIndustryForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { companyIndustry, setCompanyIndustry, companySubIndustry, setCompanySubIndustry } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyIndustryUpdateLoading, setCompanyIndustryUpdateLoading] = useState(false);
  const [industryInputValue, setIndustryInputValue] = useState('');
  const [currentSubIndustries, setCurrentSubIndustries] = useState<ISubIndType[]>([]);
  const [value, setValue] = useState<ISubIndType | null>(null);

  const updateCompanyIndustry = async () => {
    try {
      setCompanyIndustryUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        industries: companyIndustry,
        subIndustry: companySubIndustry
      };
      await apiCompanyIndustry(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyIndustryUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainFormContainer}>
        <GenericProgressBar currentStep={4} totalSteps={9} pageTitle="Company Industry" />
        <div className={styles.formQuestionText}>What industry is your company in?</div>
        <div className={styles.formQuestionDetail}>
          We use this information to create your Mastermind Group!
        </div>

        <div className={`${styles.formInputContainer} ${styles.formTextInput}`} style={{ height: "60px", marginTop: "10px" }}>
          <Autocomplete
            value={companyIndustry}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) {
                setCompanyIndustry(newValue);
                const industrySubArray = companyIndustriesList.find((il) => il?.title === newValue)?.subIndustries;
                setCurrentSubIndustries(industrySubArray!);
              }
            }}
            id="free-solo-demo"
            freeSolo={true}
            inputValue={industryInputValue}
            onInputChange={(event, newInputValue) => {
              setIndustryInputValue(newInputValue);
            }}
            options={companyIndustriesList.map((companyIndustryOption) => companyIndustryOption?.title)}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>

        <div className={styles.formQuestionText} style={{ marginTop: "18px" }}>What sub-industry is your company in?</div>
        <div className={`${styles.formInputContainer} ${styles.formTextInput}`} style={{ height: "190px" }}>
          <Autocomplete
            disabled={!companyIndustry}
            value={value}
            onChange={(event, newValue: any) => {

              if (typeof newValue === 'string') {
                setValue({
                  title: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue({
                  title: newValue.inputValue,
                });
              } else {
                setValue(newValue);
              }
              setCompanySubIndustry(newValue?.inputValue ?? newValue?.title)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.title);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={currentSubIndustries}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} />
            )}
          />
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateCompanyIndustry}
            loading={companyIndustryUpdateLoading}
            disabled={companyIndustryUpdateLoading || !companyIndustry || !companySubIndustry}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>
          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigateToFormStep(currentForm, "next")}
            loading={companyIndustryUpdateLoading}
            disabled={companyIndustryUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyIndustryForm;
