import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components/macro';
import { ReactComponent as CloseSVG } from '../../../assets/image/close.svg';
import { ReactComponent as DownArrowSVG } from '../../../assets/image/down_arrow.svg';
import { ReactComponent as RightArrowSVG } from '../../../assets/image/right_arrow.svg';
import { ReactComponent as UserSVG } from '../../../assets/image/user.svg';
import theme from '../../../core/theme';

const OuterDivisionBox = styled.div`
  width: 120px;
  background: ${theme.backgroundColor};
  display: inline-grid;
  position: relative;
`;

const DivisionBox = styled.form`
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
  background: white;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const OuterTeamMemberBox = styled.div`
  width: 100px;
  height: 30px;
  margin-bottom: 8px;
  background: white;
  display: inline-flex;
`;

export default function DropContainer({ divisionName, divisionKey, setRelationshipRoles, relationshipRoles, draggedItems, acceptedItems }) {
  const [displayAssignment, setDisplayAssignment] = useState(true)

  const [, drop] = useDrop(
    () => ({
      accept: acceptedItems,
      drop: (item) => {
        let relationshipRole = []
        if (divisionKey in relationshipRoles) {
          relationshipRole = [...relationshipRoles[divisionKey]]
          relationshipRole.push(item['memberKey'])
        } else {
          relationshipRole = [item['memberKey']]
        }
        setRelationshipRoles({ ...relationshipRoles, [divisionKey]: relationshipRole })
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }), [setRelationshipRoles, relationshipRoles]
  )

  return <OuterDivisionBox ref={drop}>
    <DivisionBox>
      {divisionName}
    </DivisionBox>
    <button style={{ backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: '80px', right: '20px' }} type="button" onClick={() => {
      setDisplayAssignment(!displayAssignment)
    }}> { displayAssignment ? <DownArrowSVG />: <RightArrowSVG />} </button>
    {
      displayAssignment && relationshipRoles[divisionKey] ?
        <>
          {relationshipRoles[divisionKey].map((element, idx) => {
            if (element in draggedItems) {
              return <OuterTeamMemberBox>
                {acceptedItems === 'Team Members' ? <UserSVG style={{ marginLeft: '5px', marginTop: '5px' }} /> : <></>}
                <div style={{ marginLeft: '5px', marginTop: '5px' }}>
                  {draggedItems[element]}
                </div>
                <button style={{ backgroundColor: 'white', cursor: 'pointer', position: 'absolute', right: '25px' }} type="button" onClick={() => {
                  let relationshipRole = [...relationshipRoles[divisionKey]]
                  relationshipRole.splice(idx, 1)
                  setRelationshipRoles({ ...relationshipRoles, [divisionKey]: relationshipRole })
                }}> <CloseSVG /> </button>
              </OuterTeamMemberBox>
            } else { return null }
          })}
        </>
        : <></>
    }
    <br />
    <br />
  </OuterDivisionBox>
}
