import styled from 'styled-components/macro';
import theme from '../../../core/theme';

const EasterEgg = styled.div`
  background: ${theme.primary};
  height: 50px;
  width: 10px;
`;

export default EasterEgg
