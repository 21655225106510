// Contains body text for InfoPopUp

import styled from 'styled-components/macro';
import * as font from '../core/font';

const InfoPopUpBody = styled.div`
  font-family: ${font.WorkSansSemiBold};
  font-size: 14px;
  line-height: 20px;
`;

export default InfoPopUpBody;