import { Button } from "antd";
import { apiUpdateStripeSubscription } from "api/stripe/update-stripe-subscription";
import axios from "axios";
import { IStripePrice } from "interface";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { toast } from "utils/toast";

const { REACT_APP_BACKEND_API_URL: baseUrl } = process.env;

interface IProps {
    price: IStripePrice;
    allPrices: IStripePrice[];
    token: string;
    stripeSubscription: any;
    isSubscriptionUpdateLoading: boolean;
    setIsSubscriptionUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsProductLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckIcon = () => {
    return <>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Done">
                <path id="XMLID_89_" d="M3.24065 7.84256C3.33194 7.92351 3.45579 7.96898 3.5849 7.96898C3.71404 7.96898 3.83786 7.92349 3.92912 7.84256L10.5661 1.95776C10.6574 1.87681 10.7087 1.76703 10.7087 1.65255C10.7087 1.53805 10.6574 1.42826 10.5661 1.34731L9.18914 0.126421C8.99905 -0.0421259 8.69079 -0.0421547 8.50067 0.126421L3.58503 4.48501L2.20802 3.26406C2.11672 3.18311 1.99287 3.13762 1.86377 3.13762C1.73466 3.13762 1.61084 3.18311 1.51955 3.26406L0.142568 4.48498C-0.0475226 4.65356 -0.0475226 4.92685 0.142568 5.09543L3.24065 7.84256Z" fill="#1C2322" />
            </g>
        </svg>
    </>
}

const SubscriptionPlanCard = ({ price, allPrices, token, stripeSubscription, isSubscriptionUpdateLoading, setIsSubscriptionUpdateLoading, setIsProductLoading }: IProps) => {
    const { setStripeSubscription } = useAuth();
    const [currentProductPrice, setCurrentProductPrice] = useState(price);
    const [isCurrentSubUpdateLoading, setIsCurrentSubUpdateLoading] = useState(false);

    useEffect(() => {
        if (stripeSubscription?.stripeSubscription?.[0]?.priceId === currentProductPrice?.metadata?.yearlyPriceId) {
            const newPriceData = allPrices?.find((prz: IStripePrice) => prz?.id === currentProductPrice?.metadata?.yearlyPriceId);
            if (newPriceData) {
                setCurrentProductPrice(newPriceData);
            }
        }

    }, [stripeSubscription]);

    const switchYearlyMonthlyPrice = () => {
        const priceId = currentProductPrice?.metadata?.yearlyPriceId ?? currentProductPrice?.metadata?.monthlyPriceId;
        const newPriceData = allPrices?.find((prz: IStripePrice) => prz?.id === priceId);
        if (newPriceData) {
            setCurrentProductPrice(newPriceData);
        }
    }

    const handlePlanSubscription = async (planPriceId: string) => {
        try {
            setIsSubscriptionUpdateLoading(true);
            setIsCurrentSubUpdateLoading(true);
            const cusId = stripeSubscription?.stripeCustomer?.[0]?.stripeCustomerId;
            const subId = stripeSubscription?.stripeSubscription?.[0]?.id;
            const subItemId = stripeSubscription?.subscriptionItems?.[0]?.id;
            const updateData = {
                cancel_at_period_end: false,
                proration_behavior: 'create_prorations', // 'create_prorations | none'
                items: [{
                    id: subItemId,
                    price: planPriceId,
                }]
            };
            await apiUpdateStripeSubscription(token, cusId, subId, updateData);
        } catch (e: any) {
            console.log('Error : ', e);
            toast.error("Something went wrong while updating stripe subscription!");
        } finally {
            setIsProductLoading(true);
            setIsSubscriptionUpdateLoading(false);
            setIsCurrentSubUpdateLoading(false);
            const response = await refetchMeWithStripeSubscription();
            setStripeSubscription(response?.data?.subscription);
            setIsProductLoading(false);
        }
    }

    const refetchMeWithStripeSubscription = async () => {
        const url = `${baseUrl}/users/me`;
        const response = axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        return response;
    }


    return (
        <div className={`f-account-page-plans-card ${currentProductPrice?.metadata?.color === "orange" && "f-color-orange"} ${currentProductPrice?.metadata?.color === "yellow" && "f-color-yellow"} ${currentProductPrice?.metadata?.color === "cyan" && "f-color-cyan"}`} key={currentProductPrice?.id}>
            {(currentProductPrice?.metadata?.yearlyPriceId || currentProductPrice?.metadata?.monthlyPriceId) &&
                <div className="f-plan-type">
                    <button className={`${currentProductPrice?.metadata?.yearlyPriceId && "f-active"}`} onClick={switchYearlyMonthlyPrice}>MONTHLY</button>
                    <button className={`${currentProductPrice?.metadata?.monthlyPriceId && "f-active"}`} onClick={switchYearlyMonthlyPrice}>YEARLY</button>
                </div>
            }

            <div className="f-plan-card-title">
                {currentProductPrice?.metadata?.title}
                <div className="f-plan-card-seat-detail">
                    {currentProductPrice?.metadata?.seats > 0 ?
                        <><b>Up to {currentProductPrice?.metadata?.seats} seat -</b> ideal for aspiring and idea-stage founders</>
                        :
                        <b>Unlimited seats</b>
                    }
                </div>
            </div>

            <div className="f-plan-card-price-container">
                {currentProductPrice?.unitAmount / 100 === 0 ?
                    <div className="f-plan-card-price">
                        Free
                    </div>
                    :
                    <>
                        <div className="f-plan-card-dollar">$</div>
                        <div className="f-plan-card-price">{currentProductPrice?.unitAmount / 100}</div>
                        <div className="f-plan-card-pm">/m</div>
                    </>
                }
            </div>

            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.knowledgeCommunity === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Knowledge Community</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Knowledge Community</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.discussionForums === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Discussion Forums</div>
                    </> :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Discussion Forums</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.accessToDiceRoom === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Access to Dice Room</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Access to Dice Room</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.businessDiagnostics === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Business Diagnostics</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Business Diagnostics</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.accessToAva === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Access to AVA</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Access to AVA</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.framewrkGiftBox === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Framewrk Gift Box</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Framewrk Gift Box</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.mastermindGroups === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Mastermind Groups</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Mastermind Groups</div>
                    </>
                }
            </div>
            <div className="f-plan-card-feature">
                {currentProductPrice?.metadata?.accessToVentureLab === "true" ?
                    <>
                        <div><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-checked">Access to Venture Lab</div>
                    </>
                    :
                    <>
                        <div style={{ opacity: 0 }}><CheckIcon /></div>
                        <div className="f-plan-card-feature-title-unchecked">Access to Venture Lab</div>
                    </>
                }
            </div>
            <Button
                type="ghost"
                className="f-plan-card-choose-button"
                loading={isCurrentSubUpdateLoading}
                disabled={stripeSubscription?.stripeSubscription?.[0]?.priceId === currentProductPrice?.id || isSubscriptionUpdateLoading || isCurrentSubUpdateLoading}
                onClick={() => handlePlanSubscription(currentProductPrice?.id)}
            >
                {stripeSubscription?.stripeSubscription?.[0]?.priceId === currentProductPrice?.id ? "Active" : "Get Started Now"}
            </Button>
        </div>
    )
}

export default SubscriptionPlanCard;
