import { ReactComponent as ClockSVG } from '../../../assets/image/clock.svg';
import { ReactComponent as SmileSVG } from '../../../assets/image/smile.svg';
import { BoldFont, Detail1, Detail2, GreenFont, HorizontalDivider, TitleLine1, TitleLine2 } from './style';

const ThankYouPage = () => {
  return (  
    <>
      <BoldFont>
        <GreenFont> <TitleLine1> Thank You! </TitleLine1> </GreenFont>
        <TitleLine2> Legal Diagnostic </TitleLine2>
        <br/>
        <div>
          <Detail1> <ClockSVG/>  Average Completion Time </Detail1>
          <Detail2> <GreenFont> 10 minutes </GreenFont> </Detail2>
        </div>
        <br/>
        <div>
          <Detail1> <SmileSVG/> Average Completion Feeling </Detail1>
          <Detail2> <GreenFont> Refreshed </GreenFont> </Detail2>
        </div>
        <br/>
      </BoldFont>
      <text>
        You've completed the Legal Diagnostic. Now our team is reviewing your responses and formulating core recommendations to help support you. We'll be identifying what types of documentation would be helpful for you and if we can draft up initial versions of them for you at no cost. 
      </text>
      <br/>
      <br/>
      <text>
        Then we'll see what legal counsel we can refer you to if you'd like to have documentation reviewed or if you need support on more direct legal matters.
      </text>
      <br/>
      <br/>
      <HorizontalDivider></HorizontalDivider>
    </>
  );
}
 
export default ThankYouPage;
