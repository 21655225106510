import { useContext } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as MailSVG } from '../../../assets/image/mail.svg';
import plus from '../../../assets/image/plus.svg';
import remove from '../../../assets/image/remove.png';
import { ReactComponent as UserSVG } from '../../../assets/image/user.svg';
import { TeamContext } from './Invitation';

const NameBox = styled.form`
  width: 120px;
  height: 20px;
  justify-content: center;
  margin: 10px 10px 10px 30px;
  display: inline-flex;
`;

const EmailBox = styled.form`
  width: 200px;
  height: 20px;
  ${'' /* background: white; */}
  margin: 10px 10px 10px 10px;
  display: inline-flex;
`;

const AddBox = styled.form`
  width: 300px;
  height: 100px;
  margin: 10px 10px 10px 10px;
  display: inline-flex;
  text-align: center; 
  justify-content: center;
  align-items: center;
`;

export function Members() {
  const {names, setNames} = useContext(TeamContext)
  const {emails, setEmails} = useContext(TeamContext)
  const {IDCounter, setIDCounter} = useContext(TeamContext)

  const addOrganizationForm = () => {
    return Object.keys(names).map((key) => {
        const handleChange = (key, event) => {
          setNames({...names, [key]: event.target.value})
          console.log(names)
        }
        const handleChangeForEmails = (key, event) => {
            setEmails({...emails, [key]: event.target.value})
            console.log(emails)
        }
        const handleRemoveItem = (key, event) => {
          setNames(current => {
                const namesCopy = {...names};
                delete namesCopy[[key]];
                return namesCopy;
            });
            setEmails(current => {
                const emailsCopy = {...emails};
                delete emailsCopy[[key]];
                return emailsCopy;
              });
        }
        return <>
            <div>
            <NameBox> 
                <UserSVG/>&nbsp;&nbsp;
                <textarea style = {{width: '100%', height: '100%', border: '2px solid white', borderRadius: '8px'}} key = {key} placeholder='Name' value = {names[key]} onChange={(event) => handleChange(key, event)} />
            </NameBox>
            <EmailBox>
                <MailSVG/>&nbsp;&nbsp;
                <textarea style = {{width: '100%', height: '100%', border: '2px solid white', borderRadius: '8px'}} key = {key} placeholder='Email' value = {emails[key]} onChange={(event) => handleChangeForEmails(key, event)} />
            </EmailBox>
            <img src={remove} alt="delete" style={{cursor: 'pointer', width: 15, marginBottom: 8}} key = {key} onClick={(event) => handleRemoveItem(key, event)}/>
            <br/>
            </div>
        </>
      })
  }

    return <div>
      {addOrganizationForm()}
      <center>
      <AddBox>  
        <div style={{display: 'block'}}>
        <img src={plus} alt="plus" style={{cursor: 'pointer'}} type="button" onClick = {() =>{
          setNames({...names, [IDCounter]: ''})
          setEmails({...emails, [IDCounter]: ''})
          setIDCounter(IDCounter+1)
        }}/>
        <div style={{opacity: 0.3}}>  Add Team Members </div>  
        </div>
      </AddBox>
      </center>
    </div>
}
