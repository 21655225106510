import { Button, Checkbox, Col, Row } from "antd";
import { apiFounderGender } from "api/account-setup/founderGender";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const FounderGenderForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { founderGender, setFounderGender } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderGenderUpdateLoading, setFounderGenderUpdateLoading] = useState(false);

  const founderGenderUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderGender(updatedValue);
  };

  const updateFounderGender = async () => {
    try {
      setFounderGenderUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        gender: founderGender,
      };
      await apiFounderGender(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setFounderGenderUpdateLoading(false);
    }
  };

  return (
    <>
      <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.title}> Gender Identity </div>
      </div>

      <ProgressBar currentForm="05"/>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.subtitle}>Which best describes your gender identity?</div>
        <div className={styles.instructionText}>Check all that apply.</div>
        <Checkbox.Group style={{ display: 'block', width: "100%" , padding: 5}} onChange={founderGenderUpdated} value={[founderGender]}> 
          <Row>
            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Male" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Male</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Female" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Female</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Non-binary" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Non-binary</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Transgender" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Transgender</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Prefer not to say" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Prefer not to say</span>
            </Col>
            <Col span={1}></Col>

            <Col span={1}>
              <div className={styles.checkBoxContainer}>
                <Checkbox value="Other" autoFocus={true}></Checkbox>
              </div>
            </Col>
            <Col span={22}>
                <span className={styles.checkBoxText}>Other</span>
            </Col>
            <Col span={1}></Col>
          </Row>
        </Checkbox.Group>
        <div style={{height: "150px", marginTop: "40px"}}>
        <Button
          type="primary"
          size="large"
          className={styles.accoutSetupNextSectionButton}
          onClick={updateFounderGender}
          loading={founderGenderUpdateLoading}
          disabled={!founderGender || founderGenderUpdateLoading}
          style={{height:"70px", width:"50vw", position: "absolute", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
        >
          <div className={styles.buttonText}>Next</div>
        </Button>
        </div>
      </div>
    </>
  );
};

export default FounderGenderForm;
