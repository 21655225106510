import { Container } from "@mui/material";
import { Button, Spin } from "antd";
import { apiFetchStripeProducts } from "api/stripe/fetch-stripe-products";
import axios from "axios";
import { IStripePrice } from "interface";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import TopNav from "screens/Office/Components/TopNav";
import SideBar from "screens/Roadmap/SideBar";
import SubscriptionPlanCard from "./subscription-plan-card";

import './index.scss';

const { REACT_APP_BACKEND_API_URL: baseUrl } = process.env;

const AccountPage = () => {
    const { currentUser, stripeSubscription } = useAuth();
    const [product, setProduct] = useState<any>();
    const [token, setToken] = useState('');
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [isOpenPortalLoading, setIsOpenPortalLoading] = useState(false);
    const [isSubscriptionUpdateLoading, setIsSubscriptionUpdateLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [currentUser]);

    // Disabled for local development only
    // useEffect(() => {
    //     (window as any).gtag('event', events.ACCOUNT_SCREEN_VIEW, {
    //       userId: currentUser.uid
    //     });
    //   }, [currentUser.uid]);

    const fetchData = async () => {
        try {
            setIsProductLoading(true);
            const userToken = currentUser && (await currentUser.getIdToken());
            setToken(userToken);

            const stripeProductRes = await apiFetchStripeProducts();

            // getting correct subscription
            if (stripeProductRes?.length && stripeProductRes?.length !== 0) {
                const stripeProductPrices = stripeProductRes.find((price: any) => price?.metadata?.active === "true");
                stripeProductPrices?.prices?.sort((a: IStripePrice, b: IStripePrice) => a.unitAmount - b.unitAmount);
                setProduct(stripeProductPrices);
                setIsProductLoading(false);
            }
        } catch (e) {
            console.log("Error : ", e);
        }
    }

    const redirectToCustomerPortal = async () => {
        setIsOpenPortalLoading(true);
        // (window as any).gtag('event', events.ACCOUNT_REDIRECT_TO_STRIPE_MANAGE_PORTAL, {
        //     userId: currentUser.uid
        // });
        const url = `${baseUrl}/stripe/create-portal-link`;
        const response = await axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } });
        window.open(response.data.url, '_self');
        setIsOpenPortalLoading(false)
    };

    return (
        <div className="f-account-page-container">
            <TopNav />
            <SideBar />
            <Container className="f-account-page-plans-main-container">
                <div className="f-account-page-plans-page-title">
                    Join the movement!
                </div>
                <div className="f-account-page-plans-page-desc">
                    Subscripe to enjoy access to our value added services
                </div>
                <div>
                    <Button key="submit" type="primary" loading={isOpenPortalLoading} onClick={redirectToCustomerPortal}>
                        Open Payment Portal
                    </Button>
                </div>
                {isProductLoading ?
                    <div style={{ height: "500px" }} className="f-d-flex f-justify-center f-align-center">
                        <Spin />
                    </div>
                    :
                    <div className="f-account-page-plans-container">
                        {product?.prices?.filter((productPrice: IStripePrice) => !productPrice?.metadata?.monthlyPriceId).map((productPrice: IStripePrice) => {
                            return <SubscriptionPlanCard
                                price={productPrice}
                                allPrices={product?.prices}
                                stripeSubscription={stripeSubscription}
                                token={token}
                                isSubscriptionUpdateLoading={isSubscriptionUpdateLoading}
                                setIsSubscriptionUpdateLoading={setIsSubscriptionUpdateLoading}
                                setIsProductLoading={setIsProductLoading}
                            />
                        })}
                    </div>
                }
            </Container>
        </div >
    )
}

export default AccountPage
