// A chat-bubble style box that is placed at the bottom-right of the screen
// Use InfoPopUpHeader and InfoPopUp Body to add text.

import styled from 'styled-components/macro';
import { ReactComponent as CloseSVG } from '../assets/image/close.svg';
import { ReactComponent as TriangleSVG } from '../assets/image/triangle.svg';
import * as font from '../core/font';
// import { Highlight } from './TextStyles';

const InfoPopUpStyled = styled.div`
  background-color: ${props => props.dark ? "#585858" : "#ffffff"};
  height: max-content;
  max-width: 531px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.161));
  border-radius: 15px;
  padding: 15px 20px;
  position: fixed;
  bottom: 50px;
  ${props => props.position}: 50px;
  @media screen and (max-width: 700px) {
    width: 85vw;
    right: 7.5vw;
    bottom: 7.5vw;
  }
  z-index: 9999;
`;

const InfoPopUpHeader = styled.h3`
  font-family: ${font.WorkSansSemiBold};
  font-size: 18px;
  color: ${props => props.dark ? "white" : "black"};
`;

const InfoPopUpBody = styled.div`
  font-family: ${font.WorkSansSemiBold};
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.dark ? 'white' : 'black'};
`;

const Triangle = styled(TriangleSVG)`
  position: absolute;
  bottom: -26px;
  left: 30px;
  & #Path_165-2 {
    fill: ${props => props.dark ? "#585858" : "#ffffff"};
  };
`

const Close = styled(CloseSVG)`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  & #Line_280, #Line_281 {
    stroke: ${props => props.dark ? "#fff" : "#777373"}
  }
`

function NotificationPopUp({ open, close, position = 'left', title, body, dark = false }) {

  return (
    open ? (
      <InfoPopUpStyled position={position} dark={dark} id="notification">
        <InfoPopUpHeader dark={dark}>{title}</InfoPopUpHeader>
        <InfoPopUpBody dark={dark}>{body}</InfoPopUpBody>
        <Triangle dark={dark} />
        <Close onClick={close} dark={dark} />
      </InfoPopUpStyled>
    ) : (
      null
    )
  )
}

export default NotificationPopUp;
