import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import { Button } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/styles';
import React, { useState } from 'react';
import styled from 'styled-components';

const theme = createTheme({
    palette: {
        primary: {
            main: "#4EBA9A",
        },
    },
});

const WorkshopSurveyContainer = styled.div`
    background-color:#f8f3e5;
    height:100vh;
    
`
const Header = styled.div`
    text-align:center;
    font-size:32px;
`


const Font = styled.div`
    margin: ${props => props.margin || "5px"};
    font-size:${props => props.fontSize};
    color:${props => props.color || "black"};
`

const Divider = styled.div`
  border-top: 1px solid #b9b9b9;
  margin-right: 25px;
  margin-left: 25px;

`;

const DividerRight = styled.div`
  border-right: 1px solid #b9b9b9;
  margin-right: 15px;

`;

const IconHeaderWrapper = styled.div`
    display:flex;
    align-items:space-between;
    margin:5px;
`

const LeftContainer = styled.div`

  width:700px;

`

const RightContainer = styled.div`

    border-left: 1px solid #b9b9b9;
    margin-top: 100px;
    width:800px;
    height:100vh;

`

function WorkshopSurvey(props) {
    const [step, setStep] = useState(2)
    return (
        <WorkshopSurveyContainer>
            <div style={{ display: "flex" }}>
                <LeftContainer>
                    <div>
                        <LeftIcon style={{ position: "relative", top: "10px" }} />
                        <RightIcon style={{ position: "relative", top: "10px" }} />
                        <Header style={{ position: "relative", top: "-30px" }}>Brand Positioning Workshop</Header>

                    </div>


                    <ThemeProvider theme={theme}>
                        <Stepper style={{ backgroundColor: "#f8f3e5" }} activeStep={step}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((label, index) => {

                                return (
                                    <Step key={label} >
                                        <StepLabel></StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </ThemeProvider>
                    <Divider />


                    <div style={{ display: "flex", margin: "20px" }}>
                        <InfoIcon />
                        <div>
                            <Font color="#4EBA9A">{step}/10 Pre-workshop Survey</Font>

                            <Font fontSize="24px">What is your brand's top 3 principles?</Font>

                        </div>


                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{ margin: "40px", width: "60px", height: "60px", borderRadius: "50px" }} variant="outlined">
                                <SettingsVoiceIcon />
                            </Button>
                        </div>

                        <div style={{ position: "relative", top: "250px", display: "flex", justifyContent: "center" }}>
                            <TextField style={{ width: "560px" }} variant="filled" />
                        </div>
                    </div>

                    <div style={{ position: "relative", top: "480px", display: "flex", width: "700px" }}>
                        <Button onClick={() => setStep(step - 1)} style={{ position: "relative", left: "50px", top: "-176px" }}>
                            <ArrowBackIcon />
                        </Button>
                        <Button onClick={() => setStep(step + 1)}>
                            <Font style={{ position: "relative", left: "466px", top: "-176px" }}>Skip</Font>

                        </Button>
                        <Button onClick={() => setStep(step + 1)} style={{ position: "relative", left: "470px", top: "-176px", border: "1px solid black", height: "60px", width: "60px", borderRadius: "4px", backgroundColor: "#CFCF22" }}>
                            <ArrowForwardIcon />
                        </Button>
                    </div>




                </LeftContainer>

            </div>


        </WorkshopSurveyContainer>
    )
}

export default WorkshopSurvey
