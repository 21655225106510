import { apiAvaGetPrompts } from 'api/ava/getPrompts';
import { apiAvaUpdateConfig } from 'api/ava/updateConfig';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import { useAuth } from "../../../providers/AuthContext";
import { Context } from '../../../providers/Context';
import { IndustryList, StageList } from './CategoryList';

const DefaultPromptsNumber = 3

const SettingsContainer = styled.div`
  padding: 25px 20px 20px 20px;
  position: relative;
  background: white;
  width: 400px;
  height: 430px;
  margin: auto;
  border-radius: 20px;
`;

const LineContainer = styled.div`
  background: white;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

const EnableContainer = styled.button`
  background: ${theme.background};
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  color: black;
  padding: 5px;
  margin-top: 30px;
`;

const CategoryContainer = styled.div`
  background: ${theme.background};
  width: 120px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  color: black;
  padding: 5px;
  margin-top: 50px;
`;

const ToggleContainer = styled.select`
  background: ${theme.background};
  width: 230px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  color: black;
  padding: 5px;
  margin-top: 50px;
  margin-left: 10px;
`;

const TextContainer = styled.textarea`
  background: ${theme.background};
  width: 230px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  color: black;
  padding: 7px;
  margin-top: 50px;
  margin-left: 10px;
`;

const SaveContainer = styled.button`
  background: ${theme.secondary};
  width: 120px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  color: black;
  padding: 5px;
  margin-top: 60px;
`;

export const BoldFont = styled.div`
  font-weight: bold;
`;

export const TitleLine = styled.div`
  font-size: 20px;
  text-align: center;
`;

export const GreenFont = styled.div`
  color: ${theme.primary};
`;

const StageSetting = ({ configuration, setConfiguration }) => {
  return (
    <ToggleContainer onChange={ e => {
      let newConfig = configuration
      newConfig.stage = e.target.value
      setConfiguration(newConfig)
    }}>
      { StageList.map( (stage, i) => (
        stage===configuration.stage?
        <option key={i} value={ stage } selected>{ stage }</option>:
        <option key={i} value={ stage }>{ stage }</option>
      ))}
    </ToggleContainer>
  )
}

const IndustrySetting = ({ configuration, setConfiguration }) => {
  return (
    <ToggleContainer defaultValue={configuration.industry} onChange={ e => {
      let newConfig = configuration
      newConfig.industry = e.target.value
      setConfiguration(newConfig)
    }}>
      { IndustryList.map( (industry, i) => (
        industry.title===configuration.industry?
        <option key={i} value={ industry.title } selected>{ industry.title }</option>:
        <option key={i} value={ industry.title }>{ industry.title }</option>
      ))}
    </ToggleContainer>
  )
}

const SubIndustrySetting = ({ configuration, setConfiguration }) => {
  return (
    <TextContainer defaultValue={configuration.subIndustry} onChange={ e => {
      let newConfig = configuration
      newConfig.subIndustry = e.target.value
      setConfiguration(newConfig)
    }}/>
  )
}

const TopicSetting = ({ configuration, setConfiguration }) => {
  return (
    <TextContainer defaultValue={configuration.topic} onChange={ e => {
      let newConfig = configuration
      newConfig.topic = e.target.value
      setConfiguration(newConfig)
    }}/>
  )
}

const AvaSettings = () => {
  const { userAuthToken } = useAuth()
  const { isAvaPromptsEnable, setAvaPromptsEnable } = useContext(Context)
  const { avaConfiguration, setAvaConfiguration } = useContext(Context)
  const { setAvaPrompts } = useContext(Context) 
  
  const handleClickEnable = async () => {
    if (!isAvaPromptsEnable) {
      // get answers
      let payload = {
        n: DefaultPromptsNumber
      }
      try {
        let avaPrompts = await apiAvaGetPrompts(userAuthToken, payload)
        setAvaPrompts(avaPrompts)
      } catch (e) {
        alert('Framewrk', 'An error is occured. Please contact the developer.');
        console.log(e);
      }
    }
    else {
      // delete answers
      setAvaPrompts([])
    }
    setAvaPromptsEnable(!isAvaPromptsEnable)
  }

  const handleClickSave = async () => {
    // update new config in Firebase
    let payload = {
      stage: avaConfiguration.stage, 
      industry: avaConfiguration.industry, 
      subIndustry: avaConfiguration.subIndustry, 
      topic: avaConfiguration.topic,
      isEnable: isAvaPromptsEnable
    }
    try {
      let response = await apiAvaUpdateConfig(userAuthToken, payload)
      console.log(response)
    } catch (e) {
      alert('Framewrk', 'An error is occured. Please contact the developer.');
      console.log(e);
    }

    // if enable: get new Prompts
    if (isAvaPromptsEnable) {
      let payload = {
        n: DefaultPromptsNumber
      }
      try {
        let avaPrompts = await apiAvaGetPrompts(userAuthToken, payload)
        setAvaPrompts(avaPrompts)
      } catch (e) {
        alert('Framewrk', 'An error is occured. Please contact the developer.');
        console.log(e);
      }
    }
  }

  return (  
    <SettingsContainer>
        <BoldFont><TitleLine>AVA Settings</TitleLine></BoldFont>
        <LineContainer>
          <EnableContainer onClick={handleClickEnable} style={{backgroundColor: isAvaPromptsEnable? theme.secondary: "#BDBDBD"}}>Enable AVA Prompts</EnableContainer>
        </LineContainer>
        <LineContainer>
          <CategoryContainer><GreenFont><BoldFont>Stage</BoldFont></GreenFont></CategoryContainer>
          <StageSetting configuration={avaConfiguration} setConfiguration={setAvaConfiguration}/>
        </LineContainer>
        <LineContainer>
          <CategoryContainer><GreenFont><BoldFont>Industry</BoldFont></GreenFont></CategoryContainer>
          <IndustrySetting configuration={avaConfiguration} setConfiguration={setAvaConfiguration}/>
        </LineContainer>
        <LineContainer>
          <CategoryContainer><GreenFont><BoldFont>Sub-Industry</BoldFont></GreenFont></CategoryContainer>
          <SubIndustrySetting configuration={avaConfiguration} setConfiguration={setAvaConfiguration}/>
        </LineContainer>
        <LineContainer>
          <CategoryContainer><GreenFont><BoldFont>Topic</BoldFont></GreenFont></CategoryContainer>
          <TopicSetting configuration={avaConfiguration} setConfiguration={setAvaConfiguration}/>
        </LineContainer>
        <LineContainer>
          <SaveContainer onClick={handleClickSave}>Save</SaveContainer>
        </LineContainer>
    </SettingsContainer>
  );
}
 
export default AvaSettings;
