import { Slider } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as AudioOnIcon } from '../../../assets/image/audio_on.svg';
import theme from '../../../core/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AudioOn = styled(AudioOnIcon)`
  height: 15px;
  width: 17px;
  margin-right: 7px;
`;

export default function HorizontalVolume({ value, onChange }) {
  const MySlider = withStyles({
    root: {
      height: 2,
      padding: '15px 0px',
      width: '50px',
    },
    thumb: {
      height: 8,
      width: 8,
      marginTop: -3,
      marginLeft: -4,
      backgroundColor: '#000',
      boxShadow: 'none',
      '&:focus, &:hover, &$active': {
        boxShadow: 'none',
      },
    },
    active: {},
    track: {
      height: 4,
      marginTop: -0.8,
      borderRadius: 4,
      backgroundColor: theme.primary,
    },
    rail: {
      height: 3,
      opacity: 1,
      backgroundColor: '#E4DFD1',
      borderRadius: 4,
    },
    mark: {
      backgroundColor: theme.primary,
      height: 1,
      width: 1,
      marginTop: 0,
    },
    markActive: {
      opacity: 1,
      backgroundColor: theme.background,
    },
  })(Slider);

  return (
    <Container>
      <AudioOn />
      <MySlider value={value} onChange={onChange} max={1} step={0.01} />
    </Container>
  );
}
