// Contains right side content for pages with a left and right side, such as Auth and 404 pages

import styled from 'styled-components/macro';
import theme from '../core/theme';

const RightSideContainer = styled.div`
  background-color: ${theme.background};
  height: 100%;
  max-width: 700px;
  min-width: 450px;
  padding-top: 80px;
  width: 28%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export default RightSideContainer;