import { useContext } from 'react';
import { TeamContext } from './Invitation';
import { Q1ColumnOne, Q1ColumnThree, Q1ColumnTwo } from "./data";

export const PiQ1 = () => {

  return <div>
    <h1> Question 1 </h1>
    <p>Please read the words in the list to the right and check those that you feel describe the way you are expected to act by others at work.</p>
  </div>
}

export const PiQ2 = () => {

  return <div>
    <h1> Question 2 </h1>
    <p>Please read the words in the list to the right and check those that you  yourself believe really describe you as you are.</p>
  </div>
}

export function PiOptions() {

  const { stepNumber } = useContext(TeamContext)
  const { cs11, setCs11 } = useContext(TeamContext)
  const { cs12, setCs12 } = useContext(TeamContext)
  const { cs13, setCs13 } = useContext(TeamContext)
  const { cs21, setCs21 } = useContext(TeamContext)
  const { cs22, setCs22 } = useContext(TeamContext)
  const { cs23, setCs23 } = useContext(TeamContext)

  const handleOnChange = (state, setState, position) => {
    const updatedCheckedState = state.map((item, index) =>
      index === position ? !item : item
    );
    setState(updatedCheckedState);
  };

  const column = (data, state, setState) => {
    return (
      <div>
        <ul className="options-list">
          {data.map(({ name }, index) => {
            return (
              <p key={index}>
                <div className="options-list-item" style={{ cursor: "pointer" }} onClick={() => handleOnChange(state, setState, index)}>
                  <div className="left-section">
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name={name}
                      value={name}
                      checked={state[index]}
                    />
                    <label style={{ color: '#4EBA9A', marginLeft: '4px', cursor: "pointer" }} htmlFor={`custom-checkbox-${index}`}>{name}</label>
                  </div>
                </div>
              </p>
            );
          })}
        </ul>
      </div>
    )
  }

  const Question = () => {
    console.log("stepNumber", stepNumber)
    if (stepNumber === 1) {
      return <QuestionOne />
    } else if (stepNumber === 2) {
      return <QuestionTwo />
    }
  }

  const QuestionOne = () =>
    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
      {column(Q1ColumnOne, cs11, setCs11)}
      {column(Q1ColumnTwo, cs12, setCs12)}
      {column(Q1ColumnThree, cs13, setCs13)}
    </div>

  const QuestionTwo = () =>
    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
      {column(Q1ColumnOne, cs21, setCs21)}
      {column(Q1ColumnTwo, cs22, setCs22)}
      {column(Q1ColumnThree, cs23, setCs23)}
    </div>

  return (
    <div className="App">
      {Question()}
    </div>
  );
}
