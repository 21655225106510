import { ReactComponent as FlagSVG } from './../../../../../assets/image/flag.svg';
import styles from "./index.module.scss";


const Step2 = () => {
    console.log(styles);

    return (
        <div className={styles.step2MainContainer}>
            <p className={styles.titleText}>Rules of the</p>
            <p className={styles.titleRoomText}>Room</p>

            <div className={styles.flagSvgCotnainer}>
                <FlagSVG className={styles.flagSvg} />
            </div>

            <p className={styles.descText}>
                By entering this group, you agree: what happends in the room stays in the room, to be respectful, to challage yourself and others in ways that inspire growth
            </p>
        </div>
    )
}

export default Step2;
