import styled from 'styled-components/macro';
import CompetitorsCase1 from "../../../assets/image/diagnostic_welcome/CompetitorsCase1.svg";
import CompetitorsCase2 from "../../../assets/image/diagnostic_welcome/CompetitorsCase2.svg";
import CompetitorsCase3 from "../../../assets/image/diagnostic_welcome/CompetitorsCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Competitors";

const DiagnosticTime = "8";

const DiagnosticURL = "/office/diagnostic/competitors/form";

const VideoURL = "https://www.loom.com/embed/be22837734624ec7a80d3dc35405053f?sid=3e501678-b3f7-4413-ab93-fccde1639069";

const QuoteContent = "\"I feel like I have a choice. Whether I want to keep the moderate route of competing, whether I want to dominate my competition, or just partner with them to lift the tide for everyone. This Diagnostic was helpful in getting me to understand how I want to compete.\"";

const ScrollContent = "Have you ever played the game Telephone? It’s a game where one person whispers a word or phrase to another, and that person has to whisper it to another, and another. By the time the word or phrase gets to the end of the line, it’s completely different from what the first person said. This is also called…life.\n\n\
We speak more than we listen. Listening has transformational POWER. Have you ever truly been listened to? Someone hanging on to your every word, their eyes aren’t fleeting, and they’re not searching their brain for a response. One of the best things that you can do for your business starts with practicing how to be a better listener, and then embed systems into your business that support that.\n\n\
While every customer opinion shouldn’t be acted on, they should be listened to. Understanding your customers will challenge your assumptions, sometimes it will confirm your biases, and other times it may give you completely new ways of thinking about things. Taking this one layer deeper, practicing better listening can also influence your team. Your customers are customers of the business. Your team are customers of you.\n\n\
That’s what this Diagnostic is all about and walks you through more effective ways to engage listening and embed it into your culture as a company.\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const ActiveListening = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const PartneringWithCompetitors = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const CompetitivePlaybook = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how powerful listening can be.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='CompetitorsCase1' src={CompetitorsCase1} width={100} style={{margin: "auto"}}/>
          <a href={ActiveListening}>Download <br/> 'Active Listening'</a>
        </Case>
        <Case>
          <img alt='CompetitorsCase2' src={CompetitorsCase2} width={100} style={{margin: "auto"}}/>
          <a href={PartneringWithCompetitors}>Download <br/> 'Partnering with Competitors'</a>
        </Case>
        <Case>
          <img alt='CompetitorsCase3' src={CompetitorsCase3} width={100} style={{margin: "auto"}}/>
          <a href={CompetitivePlaybook}>Download <br/> 'Competitive Playbook'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function CompetitorsDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
