import CheckIcon from '@mui/icons-material/Check';
import Popover from '@mui/material/Popover';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import AvaPNG from '../../../assets/image/avaIcon.png';
import downloadIcon from '../../../assets/image/onboarding/download.svg';
import flagIcon from '../../../assets/image/onboarding/flag.svg';
import mobileQR from '../../../assets/image/onboarding/mobileQRcode.svg';
import settingsIcon from '../../../assets/image/onboarding/settings.svg';
import userIcon from '../../../assets/image/onboarding/user.svg';
import usersIcon from '../../../assets/image/onboarding/users.svg';
import theme from '../../../core/theme';
import { auth, database } from '../../../firebase/firebase';
import { Context } from '../../../providers/Context';

const OnboardingContainer = styled.div`
  box-shadow: 0px 3px 40px 0px #888888;
  width: 550px;
  position: absolute;
  top: 90px;
  left: 280px;
  border-radius: 20px;
`;

const OnboardingHeader = styled.div`
  height: 70px;
  text-align: center;
  font-size: 23px;
  background-color: ${theme.primary};
  color: white;
  border-radius: 20px 20px 0px 0px;
  padding-top: 20px;
`;

const OnboardingItemOdd = styled.div`
  height: 70px;
  border-bottom: 1px;
  display: flex;
  background-color: ${theme.background};
  &:hover{
    background-color: #DFDFDF;
    cursor:pointer;
    transition-delay:.08s;
  }
`;

const OnboardingItemEven = styled.div`
  height: 70px;
  border-bottom: 1px;
  display: flex;
  background-color: white;
  &:hover{
    background-color:#DFDFDF;
    cursor:pointer;
    transition-delay:.08s;
  }
`;

const OnboardingFooter = styled.div`
  height: 80px;
  display: flex;
  border-radius: 0px 0px 20px 20px;
  background-color: white;
  &:hover{
    background-color:#DFDFDF;
    cursor:pointer;
    transition-delay:.08s;
  }
`;

const Icons = styled.img`
  height: 35px;
  width: 30px;
  margin: auto;
`;

const Text = styled.div`
  width: 390px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0px;
`;

const TextTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const TextDescription = styled.div`
  font-size: 13px;
  line-height: 15px;
`;

const GreenDot = styled.div`
  height: 25px;
  width: 25px;
  background-color: #4EBA9A;
  box-shadow: 0px 0px 6px #4EBA9A;
  border-radius: 50%;
  margin: auto;
  display: inline-block;
`;

const WhiteDot = styled.div`
  height: 25px;
  width: 25px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px #4EBA9A;
  border-radius: 50%;
  margin: auto;
  display: inline-block;
`;

const mastermindDetail = {
  name: "General",
  description: "Join General Mastermind Group"
}


const OnboardingChecklist = () => {
  const navigate = useNavigate();
  const { 
    setExpandHomeBase, 
    setMastermindState,
    onboardingProfile,
    setOnboardingProfile,
    onboardingOperations,
    setOnboardingOperations,
    onboardingTeam,
    setOnboardingTeam,
    onboardingAva,
    setOnboardingAva,
    onboardingMastermind,
    setOnboardingMastermind,
    onboardingMobile,
    setOnboardingMobile,
    isOnboardingDone,
    setIsOnboardingDone
  } = useContext(Context);
  const [userName, setUserName] = useState("New FRAMEWRKer");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // Check UserName
    database.ref('/Users/' + auth?.currentUser?.uid + '/BasicInfo/Username/').get().then((snapshot)=>{
      if (snapshot.exists()) {
        setUserName(snapshot.val())
      }
    })

    // Check User Profile Goals
    database.ref('/Users/' + auth?.currentUser?.uid + '/Goals/').get().then((snapshot)=>{
      if (snapshot.exists()) {
        setOnboardingProfile(true)
      } else {
        setOnboardingProfile(false)
      }
    })

    // Check Operations Diagnostic
    database.ref('/Diagnostics/' + auth?.currentUser?.uid + '/operations/').get().then((snapshot)=>{
      if (snapshot.exists()) {
        setOnboardingOperations(true)
      } else {
        setOnboardingOperations(false)
      }
    })

    // Check Team Diagnostic
    database.ref('/Diagnostics/' + auth?.currentUser?.uid + '/team/').get().then((snapshot)=>{
      if (snapshot.exists()) {
        setOnboardingTeam(true)
      } else {
        setOnboardingTeam(false)
      }
    })

    // Check Ava
    database.ref('/AvaChat/' + auth?.currentUser?.uid).get().then((snapshot)=>{
      if (snapshot.exists()) {
        setOnboardingAva(true)
      } else {
        setOnboardingAva(false)
      }
    })

    // Check Mastermind Group (need to test with an account that has joined mastermind)
    database.ref('/Users/' + auth?.currentUser?.uid + '/isMatchMakeDone/').get().then((snapshot)=>{
      if (snapshot.exists() && snapshot.val()===true) {
        setOnboardingMastermind(true)
      } else {
        setOnboardingMastermind(false)
      }
    })

    // Check Mobile App (need to set a param on mobile end)
    database.ref('/Users/' + auth?.currentUser?.uid + '/isMobileSigned/').get().then((snapshot)=>{
      if (snapshot.exists() && snapshot.val()===true) {
        setOnboardingMobile(true)
      } else {
        setOnboardingMobile(false)
      }
    })

    // Set overall onboarding status
    if (onboardingProfile && onboardingOperations && onboardingTeam && onboardingAva && onboardingMastermind && onboardingMobile) {
      setIsOnboardingDone(true)
    }
  }, []);
  
  const handleProfileClick = () => {
    navigate('/update-profile');
    console.log(onboardingProfile)
  };

  const handleOperationsClick = () => {
    navigate('/office/diagnostic/operations');
  };

  const handleTeamClick = () => {
    navigate('/office/diagnostic/team');
  };

  const handleAvaClick = () => {
    navigate('/office/roadmap');
  };

  const handleMastermindClick = () => {
    setExpandHomeBase(true);
    setMastermindState({ groupName: mastermindDetail?.name, firstVisit: true });
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  return ( 
    !isOnboardingDone && <OnboardingContainer>
        <OnboardingHeader>Hey {userName}! Take a tour around.</OnboardingHeader>
      
        <OnboardingItemOdd onClick={handleProfileClick}>
          <Icons src={userIcon}/>
          <Text>
            <TextTitle>Complete Your Profile</TextTitle>
            <TextDescription>State your upcoming goals to set off in the right direction.</TextDescription>
          </Text>
          {onboardingProfile && <GreenDot><CheckIcon sx={{ color: "white" }}/></GreenDot>}
          {!onboardingProfile && <WhiteDot/>}
        </OnboardingItemOdd>

        <OnboardingItemEven onClick={handleOperationsClick}>
          <Icons src={settingsIcon}/>
          <Text>
            <TextTitle>Complete Operations Diagnostic</TextTitle>
          </Text>
          {onboardingOperations && <GreenDot><CheckIcon sx={{ color: "white" }}/></GreenDot>}
          {!onboardingOperations && <WhiteDot/>}
        </OnboardingItemEven>

        <OnboardingItemOdd onClick={handleTeamClick}>
          <Icons src={flagIcon}/>
          <Text>
            <TextTitle>Complete Team Diagnostic</TextTitle>
          </Text>
          {onboardingTeam && <GreenDot><CheckIcon sx={{ color: "white" }}/></GreenDot>}
          {!onboardingTeam && <WhiteDot/>}
        </OnboardingItemOdd>

        <OnboardingItemEven onClick={handleAvaClick}>
          <Icons src={AvaPNG}/>
          <Text>
            <TextTitle>Meet Your Consultant, AVA</TextTitle>
            <TextDescription>AVA is your AI business consultant. Ask AVA questions!</TextDescription>
          </Text>
          {onboardingAva && <GreenDot><CheckIcon sx={{ color: "white" }}/></GreenDot>}
          {!onboardingAva && <WhiteDot/>}
        </OnboardingItemEven>

        <OnboardingItemOdd onClick={handleMastermindClick}>
          <Icons src={usersIcon}/>
          <Text>
            <TextTitle>Join Your Mastermind Group</TextTitle>
          </Text>
          {onboardingMastermind && <GreenDot><CheckIcon sx={{ color: "white" }}/></GreenDot>}
          {!onboardingMastermind && <WhiteDot/>}
        </OnboardingItemOdd>

        <OnboardingFooter onClick={handleDownloadClick}>
          <Icons src={downloadIcon}/>
          <Text>
            <TextTitle>Download Mobile App</TextTitle>
            <TextDescription>Click here and snap a picture of the QR code to get access to mobile-only features.</TextDescription>
          </Text>
          {onboardingMobile && <GreenDot><CheckIcon sx={{ color: "white" }}/></GreenDot>}
          {!onboardingMobile && <WhiteDot/>}
        </OnboardingFooter>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleDownloadClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <img src={mobileQR} width={150}/>
        </Popover>

    </OnboardingContainer>
  );
}
 
export default OnboardingChecklist;
