import { apiAvaGetAnswer } from 'api/ava/getAnswer';
import { apiAvaGetPrompts } from 'api/ava/getPrompts';
import { apiAvaInitConfig } from 'api/ava/initConfig';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { auth, database } from '../../../firebase/firebase';
import { useAuth } from "../../../providers/AuthContext";
import { Context } from '../../../providers/Context';
import { toast } from "../../../utils/toast";
import MessengerInput from './input';
import MessagesContainer from './message';
import AvaNavigation from './navigation';
import AvaPrompts from './prompts';

const DefaultPromptsNumber = 3

const Wrapper = styled.div`
  margin-top: 40px;
  margin-left: 0px;
  width: 100%;
  height: 100%;
`;

const MsgContainer = styled.div`
  padding: 10px 15px 10px 20px;
  display: flex;
  flex-direction: column;
  top: 0px;
  background: white;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 0px 0px 20px 20px;
`;

const PromptInputContainer = styled.div`
  display: flex;
`;

export default function AvaChat(props) {
  const { 
    setMessengerState, 
    setAvaPromptsEnable, 
    setAvaConfiguration, 
    setAvaPrompts 
  } = useContext(Context);
  
  const [messages, setMessages] = useState([]);
  const [maxLength, setMaxLength] = useState(-1);
  const [inputType, setInputType] = useState('text'); // text | audio | video | attachment | picture
  const componentIsMounted = useRef(true);
  const { userAuthToken } = useAuth();
  const [isTyping, setIsTyping] = useState(false);
  const [inputPrompt, setInputPrompt] = useState('');

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setMessengerState({
      id: 1,
      display: "ava",
      name: 'AVA',
      title: 'Consultant',
      chatType: '1v1Chat',
      group: false,
      roomColor: '#4EBA9A',
      users: [{ Username: 'Framewrk', uID: '1' }],
      online: true,
    });
  }, [])

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Firebase call, not connected yet...
        let _msgs = [];
        _msgs.push({
          data: {
          Content: 'Hi! I\'m AVA, your Artifically Intelligent Consultant. You can ask me questions that would help your business and I\'ll do my best to answer them.'
          +'\n \n You can ask me research questions like \'how big is the ecommerce market?\', strategy questions like \'how do I raise money\', and so much more.'
          +'The more you ask, the better I\'ll become at helping you.',
          Sender: 'Framewrk',
          SentDate: Date.now(),
          isUser: false,    
        }});
        const snapshot = await database
          .ref('/AvaChat/' + auth.currentUser.uid + '/Messages')
          .orderByChild('timestamp')
          .once('value');
        snapshot.forEach((msg) => {
          var _msg = { id: msg.key, data: msg.val() }
          if (msg.val().isUser) {
            _msg.data.Sender = auth.currentUser.uid
          } else {
            _msg.data.Sender = 'Framewrk'
          }
          _msg.data.SentDate = new Date(_msg.data.timestamp)
          _msg.data.Content = _msg.data.content
          _msgs.push(_msg);
        });
        console.log(_msgs)

        setMessages(_msgs)
      } catch (e) {
        alert('Framewrk', 'An error is occured. Please contact the developer.');
        console.log(e);
      }
    };

    loadInitialData();

    const loadInitialConfig = async () => {
      try {
        let snapshot = await database
          .ref(`/AvaChat/${auth.currentUser.uid}/Config/`)
          .once('value')
        if (!snapshot.exists()) {
          const response = await apiAvaInitConfig(userAuthToken)
          console.log(response)
          snapshot = await database
          .ref(`/AvaChat/${auth.currentUser.uid}/Config/`)
          .once('value')
        }
        setAvaConfiguration({stage: snapshot.val().stage, industry: snapshot.val().industry, subIndustry: snapshot.val().subIndustry, topic: snapshot.val().topic})
        setAvaPromptsEnable(snapshot.val().isEnable)
        if (snapshot.val().isEnable) {
          let payload = {
            n: DefaultPromptsNumber
          }
          let avaPrompts = await apiAvaGetPrompts(userAuthToken, payload)
          setAvaPrompts(avaPrompts)
        }
      } catch (e) {
        alert('Framewrk', 'An error is occured. Please contact the developer.');
        console.log(e)
      }
    };

    loadInitialConfig();
  }, []);

  const avaGetAnswer = async(payload) => {
    try {
      setIsTyping(true)
      let response = await apiAvaGetAnswer(userAuthToken, payload)
      let body = {
        Content: response.message,
        Sender: 'Framewrk',
        SentDate: Date.now(),
        isUser: false,
      };
      setMessages((prev) => prev.concat({ data: body }));
    } catch (e) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setIsTyping(false)
    }
  }

  const _onSendMessage = (text) => {
    let previous_messages = []
    messages.forEach((message) => {
      if (message.data.isUser) {
        previous_messages.push({role: 'user', content: message.data.Content})
      } else {
        previous_messages.push({role: 'system', content: message.data.Content})
      }
    })

    let body = {
      Content: text,
      Sender: auth.currentUser.uid,
      SentDate: Date.now(),
      isUser: true,
    };
    setMessages((prev) => prev.concat({ data: body }));

    let payload = {
      content: text,
      previousMessage: previous_messages.slice(1),
      userID: auth.currentUser.uid,
    }
    avaGetAnswer(payload)
  }
  
  return (
    <Wrapper>
      <AvaNavigation/> 
      <MsgContainer>
        <MessagesContainer
          userID={auth.currentUser.uid}
          messages={messages}
          maxLength={
            maxLength === undefined ? Number.MAX_SAFE_INTEGER : maxLength
          }
          users={[auth.currentUser]}
          isTyping={isTyping}
          />
      
        <PromptInputContainer>
          <AvaPrompts setInputPrompt={setInputPrompt}/>
          <MessengerInput
            setInputType={setInputType}
            _onSendMessage={_onSendMessage}
            inputPrompt={inputPrompt}
          />
        </PromptInputContainer>
      </MsgContainer>
    </Wrapper>
  )
}
