import styled from 'styled-components/macro';
import theme from '../../../core/theme';

//Need a function that sets this color based on online=true
const OnlineIndicator = styled.div `
    height: 10px;
    width: 10px;
    background-color: ${(props) => props.online ? theme.secondary : theme.disabled};
    border-radius: 50%;
    margin: 10px;
`;

export default OnlineIndicator;

