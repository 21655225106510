import SettingIcon from "@mui/icons-material/Settings";
import { IconButton, Popover } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import styled from 'styled-components/macro';
import AvaPNG from '../../../assets/image/avaIcon.png';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import AvaSettings from "./setting";

const AvaNavContainer = styled.div`
  background: ${theme.primary};
  height: 40px;
  border-radius: 20px 20px 0px 0px;
  display: flex;
`;

const AvaIconContainer = styled.div`
  margin-left: 10px;
  margin-top: 5px;
`;

const AvaTitleContainer = styled.div`
  width: 87%;
  margin-left: 10px;
  margin-top: 8px;
  font-family: ${font.WorkSansSemiBold};
  font-size: 20px;
  color: white;
  text-align: left;
`;

const AvaNavigation = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return ( 
    <AvaNavContainer>
        <AvaIconContainer>
          <img src={AvaPNG} alt={"AvaIconPng"} height={30} />
        </AvaIconContainer>
        
        <AvaTitleContainer>
          AVA
        </AvaTitleContainer>
        
        <IconButton aria-describedby={id} onClick={handleClick} size="small">
          <SettingIcon/>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography>
            <AvaSettings/>
          </Typography>
        </Popover>
        
      </AvaNavContainer>
  );
}
 
export default AvaNavigation;
