import React from 'react';
import { ReactComponent as AudioOnSVG } from '../../../assets/image/audio_on.svg';
import styled from 'styled-components/macro';

const AudioOn = styled(AudioOnSVG)`
  & #volume-1 {
    opacity: ${(props) => props.active ? `100%` : `32%`};
  }
`

const AudioOnIcon = (props) => (
  <AudioOn active={props.active} />
);

export default AudioOnIcon;
