import { Button, Checkbox, Col, Row } from "antd";
import { apiCompanyStage } from "api/account-setup/companyStage";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import GenericProgressBar from "screens/GenericProgressBar";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const CompanyStageForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { companyStage, setCompanyStage } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyStageUpdateLoading, setCompanyStageUpdateLoading] = useState(false);

  const companyStageChanged = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setCompanyStage(updatedValue);
  };

  const updateCompanyStage = async () => {
    try {
      setCompanyStageUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        stages: companyStage,
      };
      await apiCompanyStage(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyStageUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainFormContainer}>
        <GenericProgressBar currentStep={3} totalSteps={9} pageTitle="Company Stage" />
        <div className={styles.formQuestionText}>What stage is your company?</div>
        <div className={styles.formQuestionDetail}>
          We use this information to create your Mastermind Group!
        </div>
        <div className={`${styles.formInputContainer} ${styles.formCheckboxInput}`} style={{ height: "300px" }}>
          <Checkbox.Group style={{ width: "100%" }} onChange={companyStageChanged} value={[companyStage]}>
            <Row>
              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Ideation" autoFocus={true}></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Ideation</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Validation"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Validation</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Operational"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Operational</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Growth"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Growth</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Establishing"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Establishing</span>
              </Col>

              <Col span={2}>
                <Checkbox className={styles.formCheckbox} value="Exiting"></Checkbox>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Exiting</span>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateCompanyStage}
            loading={companyStageUpdateLoading}
            disabled={companyStageUpdateLoading || !companyStage}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next</span>
          </Button>
          <Button
            type="text"
            size="small"
            className={styles.accoutSetupNextSectionButton}
            onClick={() => navigateToFormStep(currentForm, "next")}
            loading={companyStageUpdateLoading}
            disabled={companyStageUpdateLoading}
          >
            <span className={styles.accoutSetupSkipSectionButtonText}>Skip</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyStageForm;
