import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as MailSVG } from '../../../assets/image/mail.svg';
import { ReactComponent as UserSVG } from '../../../assets/image/user.svg';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import { emailValidator, passwordValidator } from '../../../core/utils';
import * as GlobalSC from '../../../GlobalComponents';
import { SpinnerIcon, StartButton, TextInput } from '../../../GlobalComponents';
import { useAuth } from '../../../providers/AuthContext';

const { REACT_APP_PROJECT_ID } = process.env

const UserIcon = () => (
  <UserSVG height={40} style={{ marginLeft: 13, marginRight: 20 }} />
);

const Container = styled.div`
  position: absolute;
  top: 150px;
  background-color: ${theme.background};
  border-radius: 5px;
  height: 500px;
  width: 91%;
  z-index: 1000;
  padding: 10px;
  filter: drop-shadow(0 0 5px grey);
`

const LoadingIcon = styled(SpinnerIcon)`
  position: absolute;
  filter: drop-shadow(0 0 20px grey);
  bottom: 10px;
  right: 10px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Message = styled.span`
  position: absolute;
  bottom: 20px;
  width: 80%;
  text-align: center;
  color: ${props => props.color};
  font-family: ${font.CircularTTMedium};
  font-size: 20px;
`

const MailIcon = () => (
  <MailSVG height={40} style={{ marginLeft: 13, marginRight: 20 }} />
);

export default function InvitePartner({ setDisplayInvitePartner }) {
  const { currentUser } = useAuth()
  const placeholder =
    "Hey, I'm trying out a new app to help me build my business. Right now, I'm answering questions and I'd love your feedback on my responses. Here's an invitation and it's free to use. You can watch the 2 minute video on how it works and then download the app.";
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(placeholder);
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState({ value: '', error: '' });
  const [name, setName] = useState({ value: '', error: '' });
  const [error, setError] = useState("")

  const _onNameChange = (e) => setName({ value: e.target.value, error: '' });
  const _onEmailChange = (e) => setEmail({ value: e.target.value, error: '' });
  const _onMessageChange = (e) => setMessage(e.target.value);
  const sendEmail = () => {
    const emailError = emailValidator(email.value);
    const nameError = passwordValidator(name.value);
    if (emailError || nameError) {
      setEmail((prev) => ({ ...prev, error: emailError }));
      setName((prev) => ({ ...prev, error: nameError }));
      return;
    } else {
      let url = `https://us-central1-${REACT_APP_PROJECT_ID}.cloudfunctions.net/sendInvite?`
      setIsLoading(true)
      fetch(
        url +
        new URLSearchParams({
          senderID: currentUser.uid,
          senderName: currentUser.displayName,
          receiverName: name.value,
          receiverEmail: email.value,
          message: message,
        }),
      )
        .then((res) => res.json())
        .then((json) => {
          // json.code
          // 200 - okay
          // 201 - already your partner || or waiting for his reply
          // 202 - already helping others
          // 203 - invited yourself
          // 400 - another error
          let inviteError
          switch (json.code) {
            case 200:
              break;
            case 201:
              inviteError = "Invite already sent"
              setError(inviteError)
              throw (inviteError)
            case 202:
              inviteError = "This user is already helping others"
              setError(inviteError)
              throw (inviteError)
            case 203:
              inviteError = "You cannot invite yourself"
              setError(inviteError)
              throw (inviteError)
            case 400:
              inviteError = "Error inviting partner"
              setError(inviteError)
              throw (inviteError)
            default:
              break;
          }
          setEmailSent(true)
          setTimeout(() => {
            setDisplayInvitePartner(false)
          }, 1000)
        })
        .catch((e) => console.log('Invite/index.jsx - Send Invite: ', e))
        .finally(() => {
          setIsLoading(false)
        });
    };
  }

  return (
    <Container>
      <h3 style={styles.title}>Invite</h3>
      <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => setDisplayInvitePartner(false)}><CloseIcon /></IconButton>
      <InputContainer>
        <GlobalSC.Label>Name</GlobalSC.Label>
        <TextInput
          startAdornment={<InputAdornment><UserIcon /></InputAdornment>}
          value={name.value}
          onChange={_onNameChange}
          error={name.error !== ''}
        />
        <GlobalSC.Label>Email</GlobalSC.Label>
        <TextInput
          startAdornment={<InputAdornment><MailIcon /></InputAdornment>}
          value={email.value}
          onChange={_onEmailChange}
          error={email.error !== ''}
        />
        <p style={styles.desc}>
          Invite them here to give feedback. We'll send an email and get
          them setup. Here's a sample:
        </p>
        <GlobalSC.Label>Message</GlobalSC.Label>
        <TextInput
          multiline={true}
          value={message}
          onChange={_onMessageChange}
          label="Message"
          rows={6}
          rowsMax={6}
        />
        {isLoading
          ? <LoadingIcon />
          : emailSent
            ? <Message color={theme.primary}>Email Sent!</Message>
            : <StartButton onClick={sendEmail} style={{ position: 'absolute', bottom: '10px', right: '10px' }} />
        }
        {error && <Message color="red">{error}</Message>}
      </InputContainer>
    </Container>
  );
}

const styles = {
  background: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    alignItems: 'center',
    backgroundColor: theme.background,
    flex: 1,
  },
  desc: {
    color: "grey",
    fontFamily: font.CircularTTMedium,
    fontSize: 18,
    paddingBottom: 5,
    paddingHorizontal: 30,
  },
  input: {
    backgroundColor: "white",
    borderRadius: 9,
    color: 'black',
    marginHorizontal: 30,
    minHeight: 35,
    paddingLeft: 10,
  },
  label: {
    color: "black",
    fontFamily: font.CircularTTMedium,
    paddingLeft: 35,
    paddingVertical: 10,
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  loadingText: {
    color: theme.primary,
    fontSize: 20,
    paddingTop: 20,
  },
  title: {
    fontFamily: font.WorkSansSemiBold,
    fontSize: 25,
    textAlign: 'center',
    color: theme.primary
  },
};
