import { apiAddDivision } from 'api/diagnostic/operations/addDivision';
import { apiAddProcess } from 'api/diagnostic/operations/addProcess';
import { apiAddRole } from 'api/diagnostic/operations/addRole';
import { apiAddSubDivision } from 'api/diagnostic/operations/addSubDivision';
import { apiAddTech } from 'api/diagnostic/operations/addTech';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TopNav from 'screens/Office/Components/TopNav';
import styled from 'styled-components/macro';
import { NotificationPopUp } from '../../../GlobalComponents';
import arrowBack from '../../../assets/image/arrow_back.png';
import arrowNext from '../../../assets/image/arrow_next.png';
import { ReactComponent as CloseSVG } from '../../../assets/image/close.svg';
import { ReactComponent as DownArrowSVG } from '../../../assets/image/down_arrow.svg';
import { ReactComponent as PlusSVG } from '../../../assets/image/plus.svg';
import { ReactComponent as RightArrowSVG } from '../../../assets/image/right_arrow.svg';
import { ReactComponent as UserSVG } from '../../../assets/image/user.svg';
import theme from '../../../core/theme';
import { auth, database } from '../../../firebase/firebase';
import { useAuth } from "../../../providers/AuthContext";
import { Context } from '../../../providers/Context';
import { toast } from "../../../utils/toast";
import SideBar from '../../Roadmap/SideBar';
import { Progress } from '../Progress';
import DropContainer from "./DivisionDropContainer";
import SubDivision from './SubDivision';
import TeamMember from './TeamMember';
import Technology from './Technology';

const Background = styled.div`
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: ${theme.background};
    z-index: -100;
`

const Container = styled.div`
    display: flex;
    min-height: calc(100vh - 50px);
`

const LeftContainer = styled.div`
    width: 45%;
    margin-left: 69.5px;
    margin-top: 42.25px;
`

const RightContainer = styled.div`
    width: 48%;
    margin-top: 250px;
`

const VerticalDivider = styled.div`
    background-color: #b9b9b9;
    width: 1px;
    margin: 15px;
`;

const BoldFont = styled.div`
    font-weight: bold;
`;

const TitleLine1 = styled.div`
    font-size: 20px;
`;

const TitleLine2 = styled.div`
    font-size: 25px;
`;

const GreenFont = styled.div`
    color: ${theme.primary};
`;

// this is for cross button
const OuterDivisionBox = styled.div`
  width: 120px;
  background: ${theme.backgroundColor};
  display: inline-grid;
  position: relative;
`;

const DivisionBox = styled.form`
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
  background: white;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const WeekdayBox = styled.div`
  width: 150px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 20px;
  background: white;
  display: flex;
  position: relative;
`;

const OuterTeamMemberBox = styled.div`
  width: 100px;
  height: 30px;
  margin-bottom: 8px;
  background: white;
  display: inline-flex;
`;

export default function Questionnaire(props) {
  let {
    setExpandHomeBase,
    closeNotification,
    notification,
    notificationOpen,
  } = useContext(Context);

  useEffect(() => {
    setExpandHomeBase(false)
  })

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Firebase call, not connected yet...
        const snapshot = await database
          .ref('/Diagnostics/' + auth.currentUser.uid)
          .once('value');

        if (snapshot.val()) {
          const diagnostic = snapshot.val()['operations']
          Object.keys(diagnostic['processes']).map((day)=> {
            let newprocesses = []
            Object.keys(diagnostic['processes'][day]['process']).map((process)=> {
              newprocesses.push(diagnostic['processes'][day]['process'][process]['name'])
            })
            let newWeeklyProcesses={...weeklyProcesses}
            newWeeklyProcesses[day]['processes'] = newprocesses
            setWeeklyProcesses(newWeeklyProcesses)
          })

          let newDivisions = {}
          let newMembers = {}
          let newMemberRoles = {}
          let newSubDivisions = {}
          let newSubDivisionRoles = {}
          let newTechnologies = {}
          let newTechnologyRoles = {}
          Object.keys(diagnostic['divisions']).map((divisionKey) => {
            newDivisions[divisionKey]=diagnostic['divisions'][divisionKey]['name']
            if (diagnostic['divisions'][divisionKey]['members']) {
              newMemberRoles[divisionKey] = []
              Object.keys(diagnostic['divisions'][divisionKey]['members']).map((memberKey)=>{
                newMembers[memberKey] = diagnostic['divisions'][divisionKey]['members'][memberKey]['member_name']
                newMemberRoles[divisionKey].push(memberKey)
              })
            }
            if (diagnostic['divisions'][divisionKey]['technologies']) {
              newTechnologyRoles[divisionKey] = []
              Object.keys(diagnostic['divisions'][divisionKey]['technologies']).map((technologyKey)=>{
                newTechnologies[technologyKey] = diagnostic['divisions'][divisionKey]['technologies'][technologyKey]['name']
                newTechnologyRoles[divisionKey].push(technologyKey)
              })
            }
            if (diagnostic['divisions'][divisionKey]['subDivisions']) {
              newSubDivisionRoles[divisionKey] = []
              Object.keys(diagnostic['divisions'][divisionKey]['subDivisions']).map((subDivisionKey)=>{
                newSubDivisions[subDivisionKey] = diagnostic['divisions'][divisionKey]['subDivisions'][subDivisionKey]['name']
                newSubDivisionRoles[divisionKey].push(subDivisionKey)
              })
            }
          })
          setDivisions(newDivisions)
          setTeamMembers(newMembers)
          setTeamRoles(newMemberRoles)
          setSubDivisions(newSubDivisions)
          setSubDivisionRoles(newSubDivisionRoles)
          setTechnologies(newTechnologies)
          setTechnologyRoles(newTechnologyRoles)
          setStepNumber(6)
        } else {
          return
        }
      } catch (e) {
        alert('Framewrk', 'An error is occured. Please contact the developer.');
        console.log(e);
      }
    }
    loadInitialData();
  }, []);

  const [stepNumber, setStepNumber] = useState(0)
  const [divisions, setDivisions] = useState({})
  const [divisionCount, setDivisionCount] = useState(1)
  const [teamMembers, setTeamMembers] = useState({})
  const [teamMemberCount, setTeamMemberCount] = useState(1)
  const [teamRoles, setTeamRoles] = useState({})
  const [subDivisions, setSubDivisions] = useState({})
  const [subDivisionCount, setSubDivisionCount] = useState(1)
  const [subDivisionRoles, setSubDivisionRoles] = useState({})
  const [technologies, setTechnologies] = useState({})
  const [technologyCount, setTechnologyCount] = useState(1)
  const [technologyRoles, setTechnologyRoles] = useState({})
  const [weeklyProcesses, setWeeklyProcesses] = useState({ 'Monday': { 'display': true, 'processes': [] }, 'Tuesday': { 'display': false, 'processes': [] }, 'Wednesday': { 'display': false, 'processes': [] }, 'Thursday': { 'display': false, 'processes': [] }, 'Friday': { 'display': false, 'processes': [] } })

  const { userAuthToken } = useAuth();

  const totalSteps = 5

  const getDivisionsForm = () => {

    const divisionsForms = () => {
      return Object.keys(divisions).map((key) => {
        const handleChange = (key, event) => {
          setDivisions({ ...divisions, [key]: event.target.value })
        }
        const handleClick = (key) => {
          let newDivisions = { ...divisions }
          delete newDivisions[key]
          setDivisions(newDivisions)
        }
        return <OuterDivisionBox>
          <DivisionBox>
            <textarea placeholder='Type division name here' value={divisions[key]} onChange={(event) => handleChange(key, event)} />
          </DivisionBox>
          <button style={{ backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: '0px', right: '20px' }} type="button" onClick={() => {
            handleClick(key)
          }}> <CloseSVG /> </button>
        </OuterDivisionBox>
      })
    }

    return <div>
      <div style={{ opacity: 0.4, marginTop: 40, fontSize: 20 }}> Company Divisions </div>
      <div style={{ opacity: 0.9, marginBottom: 30, fontSize: 13 }}> What major divisions is your company divided into i.e. Product, Marketing, Engineering, Finance, etc.? </div>
      {divisionsForms()}
      <OuterDivisionBox>
        <DivisionBox>
          <div style={{ display: 'block' }}>
            <button style={{ backgroundColor: 'white', cursor: 'pointer' }} type="button" onClick={() => {
              setDivisions({ ...divisions, [divisionCount]: '' })
              setDivisionCount(divisionCount + 1)
            }}>
              <PlusSVG />
            </button>
            <div style={{ opacity: 0.3 }}>  Add Division </div>
          </div>
        </DivisionBox>
      </OuterDivisionBox>
    </div>
  }

  const getTeamRoles = () => {

    const teamMembersForms = () => {
      return <> {Object.keys(teamMembers).map((key) =>
        <TeamMember teamMembers={teamMembers} setTeamMembers={setTeamMembers} teamMemberKey={key} />
      )}</>
    }

    return <>
      <div style={{ opacity: 0.4, marginTop: 40, fontSize: 20 }}> Team Roles </div>
      <div style={{ opacity: 0.9, marginBottom: 30, fontSize: 13 }}> Drag and drop the people on your team into the Divisions boxes on the right that they play a major role in. </div>
      {teamMembersForms()}
      <div style={{ display: 'block' }}>
        <button style={{ backgroundColor: theme.background, cursor: 'pointer', marginLeft: '50px' }} type="button" onClick={() => {
          setTeamMembers({ ...teamMembers, [teamMemberCount]: '' })
          setTeamMemberCount(teamMemberCount + 1)
        }}>
          <PlusSVG />
        </button>
        <div style={{ opacity: 0.3, marginLeft: '10px' }}>  Add Teammate </div>
      </div>
    </>
  }

  const getSubDivisions = () => {
    const subDivisionsForms = () => {
      return <> {Object.keys(subDivisions).map((key) =>
        <SubDivision subDivisions={subDivisions} setSubDivisions={setSubDivisions} subDivisionKey={key} />
      )}</>
    }

    return <div>
      <div style={{ opacity: 0.4, marginTop: 40, fontSize: 20 }}> Company Sub-divisions </div>
      <div style={{ opacity: 0.9, marginBottom: 30, fontSize: 13 }}> What sub-divisions under each of your main divisions is your company divided into i.e. under Marketing: Social Media Marketing, Word of Mouth, Marketing etc. Type the name then drag and drop into the major division. </div>
      {subDivisionsForms()}
      <OuterDivisionBox>
        <DivisionBox>
          <div style={{ display: 'block', marginTop: '17px'}}>
            <button style={{ backgroundColor: 'white', cursor: 'pointer' }} type="button" onClick={() => {
              setSubDivisions({ ...subDivisions, [subDivisionCount]: '' })
              setSubDivisionCount(subDivisionCount + 1)
            }}>
              <PlusSVG />
            </button>
            <div style={{ opacity: 0.3 }}>  Add Sub-Division </div>
          </div>
        </DivisionBox>
      </OuterDivisionBox>
    </div>
  }

  const getTechnologies = () => {
    const technologiesForms = () => {
      return <> {Object.keys(technologies).map((key) =>
        <Technology technologies={technologies} setTechnologies={setTechnologies} technologyKey={key} />
      )}</>
    }

    return <>
      <div style={{ opacity: 0.4, marginTop: 40, fontSize: 20 }}> Technology </div>
      <div style={{ opacity: 0.9, marginBottom: 30, fontSize: 13 }}> Share what technology you use in regular processes like communicating with your team or finance. Please see some examples below. </div>
      {technologiesForms()}
      <div style={{ display: 'block' }}>
        <button style={{ backgroundColor: theme.background, cursor: 'pointer', marginLeft: '50px' }} type="button" onClick={() => {
          setTechnologies({ ...technologies, [technologyCount]: '' })
          setTechnologyCount(technologyCount + 1)
        }}>
          <PlusSVG />
        </button>
        <div style={{ opacity: 0.3, marginLeft: '10px' }}>  Add Technology </div>
      </div>
    </>
  }

  const getWeeklyProcesses = () => {
    const weekdayProcessesForms = (weekday) => {
      return <div style={{ marginLeft: '160px' }}>
        {
          weeklyProcesses[weekday]['processes'].map((element, idx) => {
            return <WeekdayBox>
              <form style={{ width: '100%', height: '22px', marginTop: '6px', marginLeft: '8px' }}>
                <textarea placeholder='Type process here' value={element} onChange={(event) => {
                  let newWeeklyProcesses = { ...weeklyProcesses }
                  newWeeklyProcesses[weekday]['processes'][idx] = event.target.value
                  setWeeklyProcesses(newWeeklyProcesses)
                }} style={{ width: '100%', height: '100%' }} />
              </form>
              <button style={{ backgroundColor: 'white', cursor: 'pointer', marginLeft: '5px', marginBottom: '12px' }} type="button" onClick={() => {
                let newWeeklyProcesses = { ...weeklyProcesses }
                newWeeklyProcesses[weekday]['processes'].splice(idx, 1)
                setWeeklyProcesses(newWeeklyProcesses)
              }}> <CloseSVG /> </button>
            </WeekdayBox>
          })
        }
        <button style={{ backgroundColor: theme.background, cursor: 'pointer', marginLeft: '40px' }} type="button" onClick={() => {
          let newWeeklyProcesses = { ...weeklyProcesses }
          newWeeklyProcesses[weekday]['processes'].push('')
          setWeeklyProcesses(newWeeklyProcesses)
        }}>
          <PlusSVG />
        </button>
        <div style={{ opacity: 0.3, marginLeft: '20px' }}>  Add item </div>
      </div>
    }

    return <>
      <div style={{ opacity: 0.4, marginTop: 40, fontSize: 20 }}> Weekly Processes </div>
      <div style={{ opacity: 0.9, marginBottom: 30, fontSize: 13 }}> What processes does your company have each week i.e. on Monday’s, there is an all-team meeting and a weekly kickoff, on Tuesday’s there is an Engineering meeting, etc. </div>
      {Object.keys(weeklyProcesses).map((weekday) => {
        return <div> <WeekdayBox>
          <UserSVG style={{ marginLeft: '5px', marginTop: '4px' }} />
          <b style={{ marginLeft: '12px', marginTop: '5px' }}>{weekday}</b>
          <button style={{ backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: '10px', right: '5px' }} type="button" onClick={() => {
            let newWeeklyProcesses = { ...weeklyProcesses }
            newWeeklyProcesses[weekday]['display'] = !newWeeklyProcesses[weekday]['display']
            setWeeklyProcesses(newWeeklyProcesses)
          }}>
            { weeklyProcesses[weekday]['display'] ? <DownArrowSVG />: <RightArrowSVG />}
          </button>
        </WeekdayBox>
          {weeklyProcesses[weekday]['display'] ? weekdayProcessesForms(weekday) : <></>}
        </div>
      })}
    </>

  }

  const addDivision = async (payload) => {
    try {
      let response = await apiAddDivision(userAuthToken, payload)
      return response.division_id
    } catch (e) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    }
  }

  const addSubDivision = (payload) => {
    try {
      let response = apiAddSubDivision(userAuthToken, payload)
      return response.division_id
    } catch (e) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    }
  }

  const addProcess = (payload) => {
    try {
      apiAddProcess(userAuthToken, payload)
    } catch (e) {
      toast.error(e?.message ?? "Something Went Wrong!");
    }
  }

  const addRole = (payload) => {
    try {
      apiAddRole(userAuthToken, payload)
    } catch (e) {
      toast.error(e?.message ?? "Something Went Wrong!");
    }
  }

  const addTech = (payload) => {
    try {
      apiAddTech(userAuthToken, payload)
    } catch (e) {
      toast.error(e?.message ?? "Something Went Wrong!");
    }
  }

  const finalScreen = () => {
    const displayRoles = (divisionKey, relationshipRoles, roles) => {
      return <>
        {relationshipRoles[divisionKey] ? 
          <>
            {relationshipRoles[divisionKey].map((element)=>{
              if (element in roles) {
                return <OuterTeamMemberBox>
                  <div style={{ marginLeft: '5px', marginTop: '5px' }}>
                    {roles[element]}
                  </div>
                </OuterTeamMemberBox>
              } else { return null }
            })}
          </>
          :<><br/></>
        }
      </>
      }

    return <div>
      <br/><br/><br/>
      {Object.keys(divisions).map((key) =>{
        return <OuterDivisionBox>
          <DivisionBox>
            {divisions[key]}
          </DivisionBox>
          Team Members
          {displayRoles(key, teamRoles, teamMembers)}
          <br/>
          Subdivisions
          {displayRoles(key, subDivisionRoles, subDivisions)}
          <br/>
          Technologies
          {displayRoles(key, technologyRoles, technologies)}
          <br/>
        </OuterDivisionBox>
      })}
      <div>
      Weekly Processes
      </div>
      <br/>
      {Object.keys(weeklyProcesses).map((weekday) => {
        if (weeklyProcesses[weekday]['processes'].length > 0){
        return <div>
          <WeekdayBox>
            <UserSVG style={{ marginLeft: '5px', marginTop: '4px' }} />
            <b style={{ marginLeft: '12px', marginTop: '5px' }}>{weekday}</b>
          </WeekdayBox>
          <div style={{ marginLeft: '160px' }}>
          {
            weeklyProcesses[weekday]['processes'].map((element) => {
              return <WeekdayBox> {element} </WeekdayBox>
            })
          }
          </div>
        </div>
        } else {return null}
      })}
    </div>
  }
 
  const getOperationsForm = () => {
    if (stepNumber === 0) {
      return getDivisionsForm()
    } else if (stepNumber === 1) {
      return getTeamRoles()
    } else if (stepNumber === 2) {
      return getSubDivisions()
    } else if (stepNumber === 3) {
      return getWeeklyProcesses()
    } else if (stepNumber === 4) {
      return getTechnologies()
    } else if (stepNumber === 5) {
      Object.keys(weeklyProcesses).map((day) => {
        weeklyProcesses[day]['processes'].map((process) => {
          addProcess({"day": day, "name": process})
        })
      })
      
      Object.keys(divisions).map((key) => {
        const division = divisions[key]
        addDivision({"name": division}).then(division_id=>{
          if (key in teamRoles) {
            teamRoles[key].map((teamMemberID)=> {
              addRole({"division_id": division_id, "member_name": teamMembers[teamMemberID]})
            })
          }
          if (key in subDivisionRoles) {
            subDivisionRoles[key].map((subDivisionID)=>{
              addSubDivision({"division_id": division_id, "name": subDivisions[subDivisionID]})
            })
          }
          if (key in technologyRoles) {
            technologyRoles[key].map((technologyID) => {
              addTech({"division_id": division_id, "name": technologies[technologyID]})
            })
          }
        })
      })
      setStepNumber(6)
    }

    return finalScreen()
  }

  const getFormButtons = () => {
    if (stepNumber>=5) {
      return null
    }
    else if (stepNumber > 0) {
      return <center>
        <button style={{ backgroundColor: theme.primary, cursor: 'pointer', marginRight: 100 }} type="button" onClick={() => {
          setStepNumber(stepNumber - 1)
        }} > <img src={arrowBack} alt={"arrowBack"} width={30} /> </button>
        <button style={{ backgroundColor: theme.primary, cursor: 'pointer' }} type="button" onClick={() => {
          setStepNumber(Math.min(stepNumber + 1, totalSteps))
        }} > <img src={arrowNext} alt={"arrowNext"} width={30} /> </button>
      </center>
    } else {
      return <center> <button style={{ backgroundColor: theme.primary, cursor: 'pointer', marginLeft: 130 }} type="button" onClick={() => {
        setStepNumber(stepNumber + 1)
      }} > <img src={arrowNext} alt={"arrowNext"} width={30} /> </button> </center>
    }
  }

  const getDropContainers = () => {
    if (stepNumber === 1) {
      return <>
        {Object.keys(divisions).map((key) =>
          <DropContainer divisionName={divisions[key]} divisionKey={key} setRelationshipRoles={setTeamRoles} relationshipRoles={teamRoles} draggedItems={teamMembers} acceptedItems='Team Members' />
        )}
      </>
    } else if (stepNumber === 2) {
      return <>
        {Object.keys(divisions).map((key) =>
          <DropContainer divisionName={divisions[key]} divisionKey={key} setRelationshipRoles={setSubDivisionRoles} relationshipRoles={subDivisionRoles} draggedItems={subDivisions} acceptedItems='Sub Divisions' />
        )}
      </>
    } else if (stepNumber === 4) {
      return <>
        {Object.keys(divisions).map((key) =>
          <DropContainer divisionName={divisions[key]} divisionKey={key} setRelationshipRoles={setTechnologyRoles} relationshipRoles={technologyRoles} draggedItems={technologies} acceptedItems='Technologies' />
        )}
      </>
    }
    return <></>
  }

  return (
    <>
      <Background />
      <Container >
        <Outlet />
        <TopNav />
        <SideBar />
        <NotificationPopUp
          close={closeNotification}
          open={notificationOpen}
          {...notification}
        />
        <LeftContainer>
          <BoldFont>
            <GreenFont> <TitleLine1> Welcome to the </TitleLine1> </GreenFont>
            <TitleLine2> Operations Diagnostic </TitleLine2>
          </BoldFont>
          {Progress(stepNumber, totalSteps)}
          {getOperationsForm()}
          <div style={{ marginTop: 50 }}> {getFormButtons()} </div>
        </LeftContainer>
        <VerticalDivider />
        <RightContainer>
          {getDropContainers()}
        </RightContainer>
      </Container>
    </>
  )

}
