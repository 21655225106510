import DeleteIcon from '@mui/icons-material/HighlightOffSharp';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrowSharp';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as GoBackIcon } from '../../../assets/image/goBack.svg';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';
import SpinnerIcon from '../../ConfigureOffice/Components/SpinnerIcon';
import HorizontalVolume from '../Components/HorizontalVolume';
import Seeker from './Seeker';
import UseAudioPlayer from './UseAudioPlayer';

const Container = styled.div`
  background-color: "inherit";
  position: relative;
  width: ${(props) => (props.narrow ? '80%' : '100%')};
  padding: ${(props) => (props.narrow ? '10px' : '0px')};
  border-radius: 10px;
  text-align: center;
  ${(props) =>
    props.narrow ? 'margin: auto 0px 25px 20px; align-self: flex-start' : null}
`;

const SpanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5;
  padding-left: 10;
  padding-right: 5;
  padding-top: 5;
`;

const Text = styled.span`
  color: ${theme.primary};
  font-family: ${font.CircularTTMedium};
  font-size: 13;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const VolumeContainer = styled.div`
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-right: 10;
`;

const AudioPlayer = ({
  uri,
  onDelete,
  messages,
  setMessages,
  setInputType,
  id
}) => {
  const {
    currentTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    volume,
    handleVolumeChange,
  } = UseAudioPlayer({ id: id });

  const {
    setHomeBaseMessage,
    setHomeBaseSendToggle,
    setHomeBaseTextInputToggle,
    setHomeBaseAudioInputToggle,
  } = useContext(Context);

  let [loaded, setLoaded] = useState(false);

  const StyledIconButton = withStyles({
    root: {
      padding: 7,
    },
  })(IconButton);

  useEffect(() => {
    if (duration && duration !== Infinity) {
      // Delay just enough to happen after currentTime is reset to 0
      setTimeout(() => {
        setLoaded(true);
      }, 0);
    }
    if (onDelete !== undefined) {
      setHomeBaseSendToggle(true);
      setHomeBaseMessage({ contents: uri, type: 'Audio' });
      setHomeBaseAudioInputToggle(false);
      setHomeBaseTextInputToggle(false);
    }
    return () => {
      setLoaded(false);
      setHomeBaseMessage({ contents: '', type: null });
      setHomeBaseSendToggle(false);
      setHomeBaseAudioInputToggle(true);
      setHomeBaseTextInputToggle(true);
    };
  }, [duration]);

  const onPlay = () => {
    playing ? setPlaying(false) : setPlaying(true);
  };

  const onBack = () => {
    setClickedTime(currentTime - 15);
  };

  const handleSeekerChange = (event, value) => {
    let curTime = duration * (value / 100);
    setClickedTime(curTime);
  };

  const sendAudio = () => {
    let newMessages = [...messages];
    let messagePayload = {
      incoming: false,
      sender: 'Pam',
      messageType: 'audio',
      avatarColor: 'green',
      sentDate: '3/1/2021',
      message: uri,
      timeStamp: new Date().toLocaleTimeString('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      }),
    };
    newMessages.push(messagePayload);
    setMessages(newMessages);
    setInputType('text');
  };

  const _time = (secs) => {
    if (secs === -1 || secs === undefined) {
      return '00:00';
    }
    if (secs === Infinity) {
      return '00:00';
    }
    const minutes = Math.floor(secs / 60);
    const seconds = (secs % 60).toFixed(0);
    return `${(minutes < 10 ? '0' : '') + minutes}:${seconds < 10 ? '0' : ''
      }${seconds}`;
  };

  return (
    <Container narrow={onDelete !== undefined}>
      <audio id={id} src={uri} />
      {loaded ? (
        <>
          <SpanContainer>
            <Text>Voice Recording</Text>
            <Text>
              {_time(currentTime)} / {_time(duration)}
            </Text>
          </SpanContainer>
          <Seeker
            value={(currentTime / duration) * 100}
            onChange={handleSeekerChange}
          />
          <FlexRow>
            <FlexRow>
              <StyledIconButton
                onClick={onPlay}
                style={{ backgroundColor: 'transparent' }}
              >
                {!playing ? (
                  <PlayIcon style={{ color: '#000' }} />
                ) : (
                  <PauseIcon style={{ color: '#000' }} />
                )}
              </StyledIconButton>
              <StyledIconButton
                onClick={onBack}
                style={{ backgroundColor: 'transparent' }}
              >
                <GoBackIcon style={{ height: 18, width: 18 }} />
              </StyledIconButton>
              <span
                style={{
                  marginLeft: '-5px',
                  color: '#000',
                }}
              >
                15
              </span>
            </FlexRow>
            <VolumeContainer>
              <HorizontalVolume value={volume} onChange={handleVolumeChange} />
            </VolumeContainer>
          </FlexRow>
          {onDelete !== undefined && (
            <>
              <StyledIconButton
                onClick={onDelete}
                style={{ position: 'absolute', top: '0px', right: '-40px' }}
              >
                <DeleteIcon style={{ color: theme.primary }} />
              </StyledIconButton>
            </>
          )}
        </>
      ) : (
        <SpinnerIcon />
      )}
    </Container>
  );
};

export default AudioPlayer;
