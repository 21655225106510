import DocumentIcon from '../../../assets/image/document.png';
import data from './Questions.json';
import { BoldFont, CategoryContainer, Detail1, Detail2, DocumentContainer, DocumentTextContainer, TitleLine1 } from './style';

const DocumentTracker = (props) => {
  var operationQuestions = []
  var teamQuestions = []
  var financeQuestions = []
  for (var i = 0; i < props.stepNumber - 1; i++){
    if (i < 3){
      operationQuestions.push(data.Questions[i])
    } else if (i < 4) {
      teamQuestions.push(data.Questions[i])
    } else {
      financeQuestions.push(data.Questions[i])
    }
  }
  return (
    <>
      <CategoryContainer>
        <BoldFont><center><TitleLine1> Customer Understanding </TitleLine1></center></BoldFont>
        { operationQuestions.map((question, i) => (
          <DocumentContainer key={i}>
            <img src={DocumentIcon} alt={"DocumentIcon"} width={30} />
            <DocumentTextContainer>
              <BoldFont><Detail2>{question.keyword}</Detail2></BoldFont>
              <Detail1>{question.choice[props.stepFlag[i]]}</Detail1>
            </DocumentTextContainer>
          </DocumentContainer>
        ))}
      </CategoryContainer>
      <CategoryContainer>
        <BoldFont><center><TitleLine1> Barriers to Entry </TitleLine1></center></BoldFont>
        { teamQuestions.map((question, i) => (
          <DocumentContainer key={i}>
            <img src={DocumentIcon} alt={"DocumentIcon"} width={30} />
            <DocumentTextContainer>
              <BoldFont><Detail2>{question.keyword}</Detail2></BoldFont>
              <Detail1>{question.choice[props.stepFlag[i+3]]}</Detail1>
            </DocumentTextContainer>
          </DocumentContainer>
        ))}
      </CategoryContainer>
      <CategoryContainer>
        <BoldFont><center><TitleLine1> Input Control </TitleLine1></center></BoldFont>
        { financeQuestions.map((question, i) => (
          <DocumentContainer key={i}>
            <img src={DocumentIcon} alt={"DocumentIcon"} width={30} />
            <DocumentTextContainer>
              <BoldFont><Detail2>{question.keyword}</Detail2></BoldFont>
              <Detail1>{question.choice[props.stepFlag[i+4]]}</Detail1>
            </DocumentTextContainer>
          </DocumentContainer>
        ))}
      </CategoryContainer>
    </>
  );
}
 
export default DocumentTracker;
