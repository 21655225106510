// A chat-bubble style box that is placed at the bottom-right of the screen
// Use InfoPopUpHeader and InfoPopUp Body to add text.

import styled from 'styled-components/macro';
import React, { useState } from 'react';
import { ReactComponent as TriangleSVG } from '../assets/image/triangle.svg';
import { ReactComponent as CloseSVG } from '../assets/image/close.svg';

const InfoPopUpStyled = styled.div`
  background-color: #ffffff;
  height: max-content;
  width: 531px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.161));
  border-radius: 15px;
  padding: 20px 35px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  @media screen and (max-width: 700px) {
    width: 85vw;
    right: 7.5vw;
    bottom: 7.5vw;
  }
`;

const Triangle = styled(TriangleSVG)`
  position: absolute;
  bottom: -26px;
  left: 30px;
`

const Close = styled(CloseSVG)`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`

function InfoPopUp(props) {
  const [open, setOpen] = useState(true)
  function close() {
    setOpen(false)
  }

  return (
    open ? (
      <InfoPopUpStyled>
        {props.children}
        <Triangle />
        <Close onClick={close} />
      </InfoPopUpStyled>
    ) : (
        null
      )
  )
}

export default InfoPopUp;