import { useAuth } from 'providers/AuthContext';
import styled from 'styled-components/macro';
import { useMyContext } from '../../../providers/Context';
import MessengerPane from '../MessengerPane';
import Chat from '../MessengerPane/Chat';
import MastermindFirstVisit from '../MessengerPane/MastermindFirstVisit';
import MastermindOnboardings from '../MessengerPane/MastermindOnboardings';
import MessengerThreadsPane from './MessengerThreadsPane';

const Main = styled.div`
  width: 441px;
  min-height: 600px;
  background-color: #ffffff;
  box-shadow: 0px 60px 70px -30px #888888;
  border-radius: 0px 0px 20px 20px;
`;

function HomebaseBottom() {
  const { messengerState, mastermindState } = useMyContext();
  const { mastermindOnboardingGroups } = useAuth();

  return (
    <Main>
      {messengerState.display === "operations" ?
        <MessengerPane />
        :
        messengerState.display === "mastermind"
          ? < Chat roomID={messengerState.id} />
          : mastermindOnboardingGroups?.length > 0
            ? <MastermindOnboardings />
            : mastermindState.firstVisit
              ? <MastermindFirstVisit />
              : <MessengerThreadsPane />}
    </Main>
  );
}

export default HomebaseBottom;
