import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import VoiceIcon from '@mui/icons-material/KeyboardVoice';
import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const WorkshopInviteContainer = styled.div`
    background-color:#f8f3e5;
    height:100vh;
`



const Font = styled.div`
    padding: 5px;
    margin: ${props => props.margin || "5px"};
    font-size:${props => props.fontSize};
    color:${props => props.color || "black"};
`

const Divider = styled.div`
  border-top: 1px solid #b9b9b9;
  margin-right: 15px;
  margin-left: 15px;
`;

const SurveyButton = styled(Button)`

`

const IconHeaderWrapper = styled.div`
    display:flex;
    align-items:space-between;
    margin:5px;
`

const SurveyButtonWrapper = styled.div`
    position:relative;
    left:20px;
    margin:20px;
    background-color:white;
    width:355px;
    border-radius:2px;
`

const InfoWrapper = styled.div`

    margin:20px;
`

function WorkshopInvite(props) {
    return (
        <WorkshopInviteContainer>
            <IconHeaderWrapper>
                <LeftIcon />
                <RightIcon />
            </IconHeaderWrapper>
            <IconHeaderWrapper>
                <InfoIcon style={{ fontSize: 60 }} />
                <div>
                    <div>
                        <Font fontSize="24px">
                            Pre-Workshop Materials
                        </Font>
                    </div>
                    <div>
                        <Font fontSize="12px">
                            Learn how to develop your brand
                        </Font>
                    </div>
                </div>

            </IconHeaderWrapper>


            <Divider />
            <InfoWrapper>
                {/* title necessary */}
                <iframe title="work-shop-invite-iframe"
                    style={{
                        margin: "5px",
                        width: "300px",
                        height: "168.75px",
                        borderRadius: "10px",
                    }}
                    src="https://www.youtube.com/embed/tgbNymZ7vqY"
                />

                <Font fontSize="18px">
                    Workshop Overview
                </Font>
                <Font fontSize="12px">
                    What you will learn in the presentation
                </Font>
                <Font fontSize="16px">
                    Average Time
                </Font>
                <Font fontSize="12px" color="#4EBA9A">
                    10 minutes
                </Font>
                <Font fontSize="16px">
                    Average Feeling
                </Font>
                <Font fontSize="12px" color="#4EBA9A">
                    Excellent
                </Font>

            </InfoWrapper>
            <Divider />
            <IconHeaderWrapper>
                <InfoIcon style={{ fontSize: 32 }} />
                <Font fontSize="18px">
                    Pre-workshop Materials
                </Font>
            </IconHeaderWrapper>


            <SurveyButtonWrapper>
                <Button startIcon={<VoiceIcon />} endIcon={<RightIcon />} variant="outlined">
                    Survey
                    <Font color="#4EBA9A">Brand Personality</Font>
                    View
                </Button>
            </SurveyButtonWrapper>
            <SurveyButtonWrapper>
                <SurveyButton startIcon={<VoiceIcon />} endIcon={<RightIcon />} variant="outlined">
                    Survey
                    <Font color="#4EBA9A">Brand Personality</Font>
                    View
                </SurveyButton>
            </SurveyButtonWrapper>
            <SurveyButtonWrapper>
                <SurveyButton startIcon={<VoiceIcon />} endIcon={<RightIcon />} variant="outlined">
                    Survey
                    <Font color="#4EBA9A">Brand Personality</Font>
                    View
                </SurveyButton>
            </SurveyButtonWrapper>
            <SurveyButtonWrapper>
                <SurveyButton startIcon={<VoiceIcon />} endIcon={<RightIcon />} variant="outlined">
                    Survey
                    <Font color="#4EBA9A">Brand Personality</Font>
                    View
                </SurveyButton>
            </SurveyButtonWrapper>


        </WorkshopInviteContainer>
    )
}

export default WorkshopInvite
