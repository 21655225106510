// Guest Account 
export const GUEST_ACCOUNT_SCREEN_VIEW = 'guest_account_screen_view'
export const GUEST_ACCOUNT_PICK_PRICE = 'guest_account_pick_price'
export const GUEST_ACCOUNT_CHECKOUT_PRESSED = 'guest_account_checkout_pressed'
export const GUEST_ACCOUNT_REDIRECT_TO_STRIPE = 'guest_account_redirect_to_stripe'

// Account 
export const ACCOUNT_SCREEN_VIEW = 'account_screen_view'
export const ACCOUNT_PICK_PRICE = 'account_pick_price'
export const ACCOUNT_REDIRECT_TO_STRIPE = 'account_redirect_to_stripe'
export const ACCOUNT_CHECKOUT_PRESSED = 'account_checkout_pressed'
export const ACCOUNT_REDIRECT_TO_STRIPE_MANAGE_PORTAL = 'account_redirect_to_stripe_manage_portal'
