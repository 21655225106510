import React from 'react';

export default function VideoMessage({URL}) {

  return (
    <iframe
    src={URL}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
    title="video"
    style={{
      width: "300px",
      height: "168.75px",
      borderRadius: "10px",
    }}
  />
  )
}