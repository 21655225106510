import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import expandIcon from '../../../assets/image/Expand.svg';
import contractIcon from '../../../assets/image/Contract.svg';
import { Context } from '../../../providers/Context';

const Container = styled.div``;

const Icon = styled.img`
  padding: 5px;
  &:hover {
    cursor: pointer;
  }
`;

function ContractController() {
  const { expandHomeBase, setExpandHomeBase } = useContext(Context);
  return (
    <Container onClick={() => setExpandHomeBase(!expandHomeBase)}>
      {expandHomeBase ? <Icon src={contractIcon} /> : <Icon src={expandIcon} />}
    </Container>
  );
}

export default ContractController;
