import { FormControl, InputBase } from "@mui/material";
import { makeStyles } from '@mui/styles';
import theme from "../core/theme";

const useStyles = makeStyles({
  input: {
    borderRadius: '8px',
    fontSize: (props) => props.multiline ? null : '20px',
    padding: (props) => props.multiline ? '5px' : null,
    caretColor: (props) => props.caretColor || theme.secondary,
    backgroundColor: "#fff",
    margin: "0px 15px 15px 0px",
    '&:focus': {
      boxShadow: `0 0 0 2px rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0),
      inset 0 0 0 2px ${theme.primary}, inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2)`,
    },
    //theres a redundency here pick which width assignment is the one you want to use
    border: (props) => props.error ? "2px solid red" : "none",
    minWidth: '250px',
    height: (props) => props.multiline ? null : '37px',
    maxWidth: '100%',
  },
})

function TextInput(props) {

  const classes = useStyles(props)

  const { error, ...rest } = props;

  return (
    <FormControl>
      <InputBase
        {...rest}
        variant="outlined"
        className={classes.input}
      />
    </FormControl>
  );
}

export default TextInput;
