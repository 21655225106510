import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './assets/Fonts/CircularTT-Medium.ttf';
import './assets/Fonts/WorkSans-Medium.ttf';
import './assets/Fonts/WorkSans-SemiBold.ttf';
import './global.scss';
import './index.css';
import AuthProvider from './providers/AuthContext';
import ContextProvider from './providers/Context';



const root = ReactDOM.createRoot(document.getElementById('root'));
const localStorageToken = localStorage.getItem("framewrk_user_token");

root.render(
  <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
        <AuthProvider localStorageToken={localStorageToken}>
            <ContextProvider>
                <App />
            </ContextProvider>
        </AuthProvider>
    </BrowserRouter>
    </DndProvider>
);
