import styled from 'styled-components/macro';
import BrandingCase1 from "../../../assets/image/diagnostic_welcome/BrandingCase1.svg";
import BrandingCase2 from "../../../assets/image/diagnostic_welcome/BrandingCase2.svg";
import BrandingCase3 from "../../../assets/image/diagnostic_welcome/BrandingCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Branding";

const DiagnosticTime = "5";

const DiagnosticURL = "";

const VideoURL = "https://www.loom.com/embed/53bf6ea423864725a82dbc73e3527eee?sid=7660b571-d25a-4128-9003-14d0ec8c2f18";

const QuoteContent = "\"I feel ready to go! I understand more about how powerful our brand can be for achieving our financial goals. Brands are really interesting and I’d never really considered how intentional we needed to be with ours.\"";

const ScrollContent = "Branding is the challenge of positioning your company’s essence: saying the right things to the right people in the right places. In the 1980’s, people sold pet rocks for $4 to over 1 million people. While many burgers are similar, billions are sold each year due to branding. The same is true for household products like appliances, bags, and cuisines. Brands command power: different prices, sales volume, and affinity. This Diagnostic walks you through how to develop greater impact from your brand by saying the right things to the right people in the right places.\n\n\
Sometimes products and services don’t take off because the right thing was said to the wrong people. Other times, the wrong things were said to the right people. The challenge is to figure out the right things to say to the right people…before you run out of resources. This is the experiment that entrepreneurs are always running in the laboratory, trying to discover and refine. That’s what this Diagnostic is all about.\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const PowerfulBrands = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const BrandingAtEarlyStages = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const BrandingPlaybook = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how powerful branding can be.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='BrandingCase1' src={BrandingCase1} width={100} style={{margin: "auto"}}/>
          <a href={PowerfulBrands}>Download <br/> 'Powerful Brands'</a>
        </Case>
        <Case>
          <img alt='BrandingCase2' src={BrandingCase2} width={100} style={{margin: "auto"}}/>
          <a href={BrandingAtEarlyStages}>Download <br/> 'Branding at Early Stages'</a>
        </Case>
        <Case>
          <img alt='BrandingCase3' src={BrandingCase3} width={100} style={{margin: "auto"}}/>
          <a href={BrandingPlaybook}>Download <br/> 'Branding Playbook'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function BrandingDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
