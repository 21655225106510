import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from 'styled-components/macro';
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Self";

const DiagnosticTime = "4";

const DiagnosticURL = "/office/diagnostic/self/form";

const VideoURL = "https://www.loom.com/embed/da8e1fc4fc2d4ff591fea1378d14aac0?sid=a0a195a7-d646-46da-a3e2-c05e6aa83900"

const QuoteContent = "\"I feel supported. Building a business is about a lot more than just the business. I’m the one who’s running the machine and if I’m not doing well, my business will suffer. This Diagnostic helped me identify very clear and consistent ways to keep my mind and emotions more balanced as the company grows. I know it’s not a perfect science, but I already feel more supported.\"";

const ScrollContent = "There are days where you won’t need any support and will just naturally feel great. Regardless of which day it is, the Self Diagnostic walks through six different aspects of yourself, asking questions, and formulating ideas for how to keep you sane.\n\n\
This Diagnostic evolves over time to learn more about what drives you and how to support you. It is coupled with the mobile app’s check-ins to understand how you’re feeling each week and automatically creates suggestions and experiences to help you along the way.";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const AccordionContainer = styled.div`
  width: 80%;
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 60px;
  border-radius: 8px;
  box-shadow: 0px 3px 2px -1px #ded9cc;
`;

const styles = {
  upper: {
    backgroundColor: "#fbf8f0",
    margin: "auto", 
    boxShadow: 'none',
    summary: {
      fontWeight: '600',
      boxShadow: "0px 3px 2px -1px #ded9cc"
    },
    details: {
      fontSize: "13px", 
      fontWeight: "300"
    }
  },
  middle: {
    backgroundColor: "#ffffff",
    margin: "auto", 
    boxShadow: 'none',
    summary: {
      fontWeight: '600',
      boxShadow: "0px 3px 2px -1px #ded9cc"
    },
    details: {
      fontSize: "13px", 
      fontWeight: "300"
    }
  },
  footer: {
    backgroundColor: "#fbf8f0",
    margin: "auto", 
    boxShadow: 'none',
    summary: {
      fontWeight: '600',
      boxShadow: "0px 7px 2px -4px #ded9cc"
    },
    details: {
      fontSize: "13px", 
      fontWeight: "300"
    }
  }
};

function RightBottomComponent () {
  return (
    <AccordionContainer>
      <Accordion style={styles.upper}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.upper.summary}>
          The Dice Room
        </AccordionSummary>
        <AccordionDetails style={styles.upper.details}>
          Every Founder who joins Framewrk receives a free gift in the mail. A part of that gift is a six-sided die to keep with you as you go throughout your day. Whenever you’re feeling stressed, roll the die, then plug the number into the Framewrk mobile app. You’ll receive an activity to complete that helps de-stress you and get your day back on track.
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.middle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.middle.summary}>
          The Stress Hub
        </AccordionSummary>
        <AccordionDetails style={styles.middle.details}>
          If you don’t have time to complete the Dice Room activity, use the stress hub. It is a 100% confidential chat thread for you to scream, yell, curse, type frustrations out, and more. It’s your private channel to let your emotions out and feel better.
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.footer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.footer.summary}>
          User Manual
        </AccordionSummary>
        <AccordionDetails style={styles.footer.details}>
          Now this is super fun! You know how appliances you purchase at the store come with user manuals? Ideas on how to best utilize those appliances, what to do when it's not working to troubleshoot it, etc. We thought it would be a fun idea to craft one for you. Now, while this takes us a bit longer to produce for you, you’ll receive your very own ‘User Manual’, which serves as a guide for self-understanding and optimizing yourself. We hope you enjoy!
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  );
}

export default function SelfDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
