import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import * as font from '../../../../core/font';
import theme from '../../../../core/theme';
import { database } from "../../../../firebase/firebase";
import { SpinnerIcon } from '../../../../GlobalComponents';
import { useAuth } from '../../../../providers/AuthContext';
import { useMyContext } from '../../../../providers/Context';

const Container = styled.div`
    align-items: center;
    background-color: ${theme.background};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
`

const Title = styled.h4`
    font-family: ${font.WorkSansMedium};
    font-size: 25px;
    text-align: center;
`

const MatchLoading = (props) => {

    const { currentUser } = useAuth();
    const { setMastermindState } = useMyContext()

    const [timerID, setID] = useState(-1);

    useEffect(() => {
        database
            .ref('/Users/' + currentUser.uid + '/Recommended/')
            .once('value')
            .then((snapshot) => {
                let recomArr = [];
                snapshot.forEach((snap) => {
                    recomArr.push(snap.val().roomKey);
                });
                setID(
                    setTimeout(() => {
                        setMastermindState((prev) => ({ ...prev, recommended: recomArr }))
                    }, 5 * 1000),
                );
            });
    }, []);

    return (
        <Container>
            <Title >
                Matching you with your Mastermind Group...
            </Title>
            <SpinnerIcon />
        </Container>
    );
};

export default MatchLoading;
