import { useDrag } from 'react-dnd';
import styled from 'styled-components/macro';
import { ReactComponent as CloseSVG } from '../../../assets/image/close.svg';
import theme from '../../../core/theme';

const OuterTechnologyBox = styled.div`
  width: 40%;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 20px;
  background: white;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export default function Technology({ technologies, setTechnologies, technologyKey }) {
  const [, drag] = useDrag(() => ({
    type: 'Technologies',
    item: { 'memberKey': technologyKey },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const handleChange = (key, event) => {
    setTechnologies({ ...technologies, [key]: event.target.value })
  }
  const handleClick = (key) => {
    let newTechnologies = { ...technologies }
    delete newTechnologies[key]
    setTechnologies(newTechnologies)
  }
  return <div style={{ display: 'flex' }}>
    <OuterTechnologyBox ref={drag}>
      <form style={{ width: '100%', height: '22px', marginTop: '6px', marginLeft: '8px' }}>
        <textarea placeholder='Type technology name here' value={technologies[technologyKey]} onChange={(event) => handleChange(technologyKey, event)} style={{ width: '100%', height: '100%' }} />
      </form>
    </OuterTechnologyBox>
    <button style={{ backgroundColor: theme.background, cursor: 'pointer', marginLeft: '5px', marginBottom: '12px' }} type="button" onClick={() => {
      handleClick(technologyKey)
    }}> <CloseSVG /> </button>
  </div>
}
