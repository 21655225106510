import { NotificationPopUp } from 'GlobalComponents';
import { Context } from 'providers/Context';
import { useContext } from 'react';
import { Outlet } from "react-router-dom";
import TopNav from 'screens/Office/Components/TopNav';
import Homebase from 'screens/Office/Homebase';
import SideBar from 'screens/Roadmap/SideBar';
import styled from 'styled-components/macro';
import OnboardingChecklist from './OnboardingChecklist';

const Container = styled.div`
  overflow-x: hidden;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const Home = () => {
  const { notification, closeNotification, notificationOpen } = useContext(Context);

  return (
    <Container>
      <Outlet />
      <TopNav />
      <SideBar />
      <OnboardingChecklist/>
      <Homebase/>
      <NotificationPopUp
        close={closeNotification}
        open={notificationOpen}
        {...notification}
      />
    </Container>
  );
};

export default Home;
