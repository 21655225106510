// A square button used for advancing to the next screen
// Place within NextButtonContainer for proper placement on the page

import styled from 'styled-components/macro';
import theme from '../core/theme';

const NextButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.bgColor || `${theme.disabled}`};
  border-radius: 15px;
  box-shadow: ${(props) =>
    `0 0 ${props.active ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  margin-left: 20px;
  width: 70px;
`;

export default NextButton;
