import React from 'react';
import { ReactComponent as SpinnerSVG } from '../assets/image/spinner.svg';
import styled from 'styled-components/macro';

let Spinner = styled(SpinnerSVG)`
  animation: rotation 1.25s infinite linear;
  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const SpinnerIcon = (props) => (
  <Spinner {...props}/>
);

export default SpinnerIcon;
