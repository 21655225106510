import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { FormControl, MenuItem, Select } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers'; // TODO: FIX THIS  DATE PICKER
import 'date-fns';
import format from "date-fns/format";
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as dateSVG } from '../../../assets/image/dateIcon.svg';
import { ReactComponent as timeSVG } from '../../../assets/image/timeIcon.svg';
import * as font from "../../../core/font";
import theme from '../../../core/theme';
import { StartButton, TextInput } from '../../../GlobalComponents';
import { Context } from '../../../providers/Context';

const DateIcon = styled(dateSVG)`
    margin-left: 13px;
    margin-right: 20px;
`

const TimeIcon = styled(timeSVG)`
    margin-left: 13px;
    margin-right: 20px;
`

const Title = styled.h2`
    font-family: ${font.WorkSansSemiBold};
    font-size: 18px;
    opacity: 31%;
    margin-top: 100px;
`

const Subtitle = styled.h3`
    font-family: ${font.CircularTTMedium};
    font-size: 12px;
    opacity: 51%; 
    margin-bottom: 10px;
`

const FlexColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const VerticalDivider = styled.div`
  background-color: #b9b9b9;
  width: 1px;
  margin: 15px;
  margin-right: 20%;
  @media screen and (max-width: 1630px) {
    display: none;
  }
`;

const useStyles = makeStyles({
    select: {
        height: "25px",
        borderRadius: "8px",
    },
})


export default function SchedulePane({ location }) {

    const classes = useStyles()
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [time, setTime] = useState("")
    let calendarClicked = useRef(false);
    let calendarOpen = false;

    const { setExpandHomeBase } = useContext(Context)

    useEffect(() => {
        setExpandHomeBase(false)
    })

    function handleTimeSelect(event) {
        setTime(event.target.value)
    }

    const hours = () => {
        let hoursArray = [];
        for (let i = 9; i <= 17; i++) {
            hoursArray.push(i)
        }
        return hoursArray;
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const renderInput = (props) => (
        <TextInput
            startAdornment={<InputAdornment><DateIcon style={{ color: theme.primary }} /></InputAdornment>}
            endAdornment={<InputAdornment>{calendarOpen ? <ArrowDropUpIcon style={{ color: "#757575" }} /> : <ArrowDropDownIcon style={{ color: "#757575" }} />}</InputAdornment>}
            value={props.value}
            onClick={props.onClick}
            onChange={props.onChange}
            inputRef={props.inputRef}
            caretColor="transparent"
        />
    );

    function displayTime(hour) {
        return (
            hour < 12
                ? hour + ":00AM"
                : hour === 12
                    ? hour + ":00PM"
                    : (hour - 12) + ":00PM"
        )
    }

    function toPlaceholderOrNotToPlaceholder() {
        return calendarClicked.current ? format(selectedDate, "MMMM do") : "Date"
    }

    return (
        <div style={{ display: 'flex', height: '100%' }}>

            <FlexColumn>
                <div>

                    <Title>Schedule</Title>
                    <Subtitle>Enter your available dates to host the workshop. Invited users will enter their compatible dates. </Subtitle>
                    <DatePicker
                        disableToolbar
                        animateYearScrolling
                        autoOk={true}
                        value={selectedDate}
                        variant="inline"
                        onChange={handleDateChange}
                        TextFieldComponent={renderInput}
                        onOpen={() => {
                            calendarOpen = true
                            calendarClicked.current = true;
                        }}
                        onClose={() => {
                            calendarOpen = false
                        }}
                        labelFunc={toPlaceholderOrNotToPlaceholder}
                        disablePast
                    />
                    <FormControl>

                        <Select
                            className={classes.select}
                            input={
                                <TextInput
                                    startAdornment={<InputAdornment><TimeIcon style={{ color: theme.primary }} /></InputAdornment>}
                                />
                            }
                            value={time}
                            onChange={handleTimeSelect}
                            displayEmpty
                            renderValue={(val) => val ? displayTime(val) : "Time"}
                        >
                            {hours().map(hour => <MenuItem value={hour}>{displayTime(hour)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <StartButton />
            </FlexColumn>
            <VerticalDivider />
        </div>
    )
}
