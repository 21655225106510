import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as sendSvg } from '../../../assets/image/sendIcon.svg';
import * as font from '../../../core/font';
import theme from '../../../core/theme';

const SendIcon = styled(sendSvg)`
  & #Rectangle_46 {
    fill: ${(props) => (props.enabled ? theme.secondary : theme.disabled)};
    ${(props) => (props.enabled ? null : `opacity: 38%`)};
  }
  ${(props) =>
    props.enabled
      ? `filter: drop-shadow(0px 0px 5px ${theme.secondary})`
      : null};
`;

export default function MessengerInput({
  setInputType,
  _onSendMessage,
  inputPrompt
}) {
  const [input, setInput] = useState('');

  function handleChange(event) {
    let value = event.target.value;
    setInput(value);
  }

  useEffect(() => {
    setInput(inputPrompt)
  }, [inputPrompt]);

  function handleSubmit(event) {
    event.preventDefault();

    let message = input;
    _onSendMessage(message);

    setInput('');
    setInputType('text');
  }

  return (
    <form onSubmit={handleSubmit} style={{
      display: 'flex',
      height: '60px',
      alignItems: 'center',
      borderRadius: '10px',
      width: '93%',
      marginTop: '10px',
    }}>
      <InputBase
        sx={{
          flex: 1,
          backgroundColor: theme.background,
          fontFamily: font.CircularTTMedium,
          fontSize: '14px',
          borderRadius: '10px',
          marginLeft: '5px',
          padding: '10px 10px 10px 10px'
        }}
        placeholder="Type Response"
        inputProps={{ 'aria-label': 'Type Response' }}
        value={input}
        onChange={handleChange}
        multiline={true}
        rows={2}
      />
      <IconButton
        type="submit"
        sx={{
          padding: '0px',
          margin: '10px'
        }}
        disabled={!input}
        aria-label="send"
        color='white'
      >
        <SendIcon enabled={input} />
      </IconButton>
    </form>
  );
}
