import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiLoginAndAccountSetupStep = async (payload: any) => {
  try {
    const url = `${REACT_APP_BACKEND_API_URL}/login`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};
