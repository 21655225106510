// Contains Header text for InfoPopUp

import styled from 'styled-components/macro';
import * as font from '../core/font';

const InfoPopUpHeader = styled.h3`
  font-family: ${font.WorkSansSemiBold};
  font-size: 18px;
`;

export default InfoPopUpHeader