import styled from 'styled-components/macro';
import LegalCase1 from "../../../assets/image/diagnostic_welcome/LegalCase1.svg";
import LegalCase2 from "../../../assets/image/diagnostic_welcome/LegalCase2.svg";
import LegalCase3 from "../../../assets/image/diagnostic_welcome/LegalCase3.svg";
import WelcomePage from "../WelcomePage";

const DiagnosticName = "Legal";

const DiagnosticTime = "7";

const DiagnosticURL = "/office/diagnostic/legal/form";

const VideoURL = "https://www.loom.com/embed/c4f316b0272a468a8d10e59b04643a52?sid=7de448d6-9c09-4c8e-8c48-b0e5972c9e2b"

const QuoteContent = "\"I feel like I can breathe a bit easier, I’m less anxious. Legal is one of those things that gets confusing and stressful. It’s hard to know the right documents to have to protect me and my business or who to ask advice from, and lawyers are expensive. Right now though, I feel more at ease with a legal strategy, some document drafts, and a better understanding of legal counsel.\"";

const ScrollContent = "Every company needs a legal strategy to support and protect it. When you secure investors, documents need to be in place for how their investment is structured. When you hire new team members, you’ll need documents that detail their agreements. It’s important to have legal counsel review those documents to ensure they protect you and your business as best as possible to have a proper legal strategy. A legal strategy is a documented plan with the primary purpose of protection and it is made up of three elements:\n\n\
1. A documented plan explaining rules and results for if those rules are followed or broken;\n\
2. Legally binding documents establishing the rules for people and processes in relation to what happens if those rules are not followed; and\n\
3. Clear details on the appropriate counsel to engage with if the defined outcome occurs or is likely to occur.\n\n\
The Legal Diagnostic walks through core areas of your business such as Operations, Team, Marketing, and Finance to discover what documented protections you currently have in place and which ones you don’t. After you complete the Diagnostic, Framewrk will:\n\n\
• Develop drafts of documents that aren’t in place yet, that you can review and edit; and take to legal counsel for further development;\n\
• Deliver a legal strategy for ways to increasingly protect yourself and your business; and\n\
• Provide you with suggestions for connecting with the appropriate legal counsel who can help increase protection of your business\n\
";

const ReportLink = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const RunningIntoLawsuits = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const InvestorDocs = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";
const HiringFiring = "https://drive.google.com/file/d/132_8uwu-vrMNz68S6fYWoKxdOPkjTJ-x/view?usp=sharing";

const CaseStudyTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const CaseStudyDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 400px;
  margin-top: 10px;
`;

const Case = styled.div`
  width: 140px;
  height: 100%;
  font-size: 12px;
  text-decoration: underline;
`;


function RightBottomComponent () {
  return (
    <div>
      <CaseStudyTitle>
        Case Studies
      </CaseStudyTitle>
      <CaseStudyDescription>
        Examples of how powerful legal strategies can be.
      </CaseStudyDescription>
      <center><CasesContainer>
        <Case>
          <img alt='LegalCase1' src={LegalCase1} width={100} style={{margin: "auto"}}/>
          <a href={RunningIntoLawsuits}>Download <br/> 'Running into Lawsuits'</a>
        </Case>
        <Case>
          <img alt='LegalCase2' src={LegalCase2} width={100} style={{margin: "auto"}}/>
          <a href={InvestorDocs}>Download <br/> 'Investor Docs'</a>
        </Case>
        <Case>
          <img alt='LegalCase3' src={LegalCase3} width={100} style={{margin: "auto"}}/>
          <a href={HiringFiring}>Download <br/> 'Hiring + Firing'</a>
        </Case>
      </CasesContainer></center>
    </div>
  );
}

export default function LegalDiagnostic () {
  return <WelcomePage 
    DiagnosticName={DiagnosticName} 
    DiagnosticTime={DiagnosticTime} 
    QuoteContent={QuoteContent} 
    ScrollContent={ScrollContent} 
    DiagnosticURL={DiagnosticURL} 
    VideoURL={VideoURL} 
    ReportLink={ReportLink} 
    RightBottomComponent={RightBottomComponent}
  />
}
