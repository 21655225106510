import { Button, Input } from 'antd';
import { apiPostMeetingSurvet } from 'api/post-meeting-survey';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import frameWrkLogo from './../../../assets/image/FramewrkLogo.png';
import bg from './../../../assets/image/bg.png';

import './index.scss';

const PostMeetingSurvey = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [firstGoal, setFirstGoal] = useState("");
    const [secondGoal, setSecondGoal] = useState("");
    const [thirdGoal, setThirdGoal] = useState("");
    const meetingGlobalId = searchParams.get("id");
    const wherebyRoomId = searchParams.get("wherebyid");
    const agendaText = searchParams.get("agenda");

    const updatePostMeetingSurvey = async () => {
        try {
            setIsUpdateLoading(true);
            const payloadData = {
                goals: [firstGoal, secondGoal, thirdGoal],
                agenda: agendaText,
                meetingRoomId: wherebyRoomId,
                chatRoomId: meetingGlobalId
            };
            await apiPostMeetingSurvet(payloadData);
        } catch (e: any) {
            console.log(e);
        } finally {
            setIsUpdateLoading(false);
            navigate("/office");
        }
    };

    return (
        <div className="post-meeting-survey-page">
            <div className="post-meeting-survey-page-left-side">
                <img className='post-meeting-survey-page-left-side-bg' src={bg} alt="fireSpot" />
                <img className='post-meeting-survey-page-left-side-logo' src={frameWrkLogo} alt="framewrk-logo" />
                <div className='post-meeting-survey-page-left-side-line'></div>
                <div className='post-meeting-survey-page-left-side-quote'>"If you do not knowhow to ask the right question, you discover nothing."</div>
                <div className='post-meeting-survey-page-left-side-quote-creator'>
                    W. Edwards Deming
                    <hr />
                </div>
            </div>

            <div className="post-meeting-survey-page-right-side">
                <div className="post-meeting-survey-page-right-side-form">
                    <h1>Post-meeting Survey</h1>
                    <h4>This survey will be used to determine next week's agenda.</h4>
                    <h3 className='titlee'>What are the top three goals you'd like to accomplish by our next meeting?</h3>

                    <h3 className='goal-title'>First Goal</h3>
                    <Input
                        disabled={isUpdateLoading}
                        className='inputt'
                        placeholder="Write your 1st goal here"
                        value={firstGoal}
                        onChange={(e) => setFirstGoal(e?.target?.value)}
                        autoFocus={true}
                    />
                    <h3 className='goal-title'>Second Goal</h3>
                    <Input
                        disabled={isUpdateLoading}
                        className='inputt'
                        placeholder="Write your 2st goal here"
                        value={secondGoal}
                        onChange={(e) => setSecondGoal(e?.target?.value)}
                        autoFocus={true}
                    />
                    <h3 className='goal-title'>Third Goal</h3>
                    <Input
                        disabled={isUpdateLoading}
                        className='inputt'
                        placeholder="Write your 3st goal here"
                        value={thirdGoal}
                        onChange={(e) => setThirdGoal(e?.target?.value)}
                        autoFocus={true}
                    />
                    <div className='postMeetingSurveyButtonContainer'>
                        <Button
                            type="primary"
                            size="large"
                            className="postMeetingSurvey"
                            onClick={updatePostMeetingSurvey}
                            loading={isUpdateLoading}
                            disabled={isUpdateLoading || !firstGoal || !secondGoal || !thirdGoal}
                        >
                            <span className="postMeetingSurveyText">Submit</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostMeetingSurvey;
