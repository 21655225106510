import styles from "./index.module.scss";

interface IProps2 {
  currentForm: string;
}

const ProgressBar = ({ currentForm }: IProps2) => {
    console.log(currentForm)

    if (currentForm == "01"){
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "3.5%"}}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>

            <div className={styles.greenBar}></div>

            <div className={styles.greenDot} style={{boxShadow: "0px 0px 6px #4EBA9A"}}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    } 

    if (currentForm == "02"){
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "14.5%"}}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }

    if (currentForm == "03"){
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "25.5%"}}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "20.5%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "19.5%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }

    if (currentForm == "04"){
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "36.5%"}}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "20.5%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "29%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "19.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "29%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }

    if (currentForm == "05"){
        return (
            <>
            <div className={styles.bar}></div>
            
            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "47.5%"}}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "20.5%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "29%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "37.4%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "19.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "29%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "38.5%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }

    if (currentForm == "06"){
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "58.5%"}}></div>
            <div className={styles.dot}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "20.5%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "29%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "37.4%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "46%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "19.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "29%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "38.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "48%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }

    if (currentForm == "07"){
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>
            <div className={styles.dot} style={{marginLeft: "69.5%"}}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "20.5%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "29%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "37.4%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "46%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "54.5%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "19.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "29%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "38.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "48%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "58.5%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }

    else {
        return (
            <>
            <div className={styles.bar}></div>

            <div className={styles.dot} style={{marginLeft: "2.5%"}}></div>

            <div className={styles.greenBar}></div>
            <div className={styles.greenBar} style={{ marginLeft: "12%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "20.5%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "29%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "37.4%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "46%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "54.5%" }}></div>
            <div className={styles.greenBar} style={{ marginLeft: "63%" }}></div>

            <div className={styles.greenDot}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "10%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "19.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "29%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "38.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "48%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "58.5%" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            <div className={styles.greenDot} style={{ marginLeft: "68%", boxShadow: "0px 0px 6px #4EBA9A" }}>
                <div className={styles.whiteCheck}>&#x2714;</div>
            </div>
            </>
        )
    }
};

export default ProgressBar;

