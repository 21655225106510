import { CalendarOutlined, EditOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { apiUserUpdateGoals } from "api/account-setup/userUpdateGoals";
import { apiUserUpdateProfile } from "api/account-setup/userUpdateProfile";
import { useAuth } from 'providers/AuthContext';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'utils/toast';
import { auth, database } from './../../../firebase/firebase';
import styles from './index.module.scss';

const RightSideLoginComponent = () => {
  const navigate = useNavigate();
  const { currentUser, userAuthToken } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [updateLoading, setUserUpdateLoading] = useState(false);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [goalFirst, setGoalFirst] = useState('');
  const [goalSecond, setGoalSecond] = useState('');
  const [goalThird, setGoalThird] = useState('');
  const [searchParams] = useSearchParams();
  const meetingGlobalId = searchParams.get("id");

  //show hide goals
  const [showButton, setShowButton] = useState(false);
  const toggleButton = () => {
    setShowButton(!showButton);
  };

  const userUpdateProfile = async () => {
    try {
      setUserUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        username: name,
        company_name: companyName,
      };
      const payload2 = { "firstGoal": goalFirst, "secondGoal": goalSecond, "thirdGoal": goalThird };
      await database.ref('/Meeting/' + meetingGlobalId + "/" + auth?.currentUser?.uid).update(payload2);
      await apiUserUpdateProfile(userAuthToken!, payload);
      await apiUserUpdateGoals(userAuthToken!, payload2);
      navigate('/home');
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setUserUpdateLoading(false);
    }
  };

  return (
    <div className={styles.rightSideDetailComponent}>
      <div style={{backgroundColor: "#4EBA9A", width: "11px", height:"52px", position:"absolute", marginTop:"6vh", marginRight: "34vw"}}></div>
        
          <div className={styles.loginContainer}>
          
          <div className={styles.title}>Update Profile</div>
          <div className={styles.titleSecondary}>Create your account</div>
          
          <div style={{height:"4vh"}}></div>
          
          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Your Name</div>
            <div className={styles.loginInput}>
              <Input
                value={name}
                onChange={(e) => setName(e?.target?.value)}
                size="large"
                placeholder="Enter Name"
                prefix={<UserOutlined />}
              />
            </div>
            {/* <div>{nameError}</div> */}
          </div>

          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Company Name</div>
            <div className={styles.loginInput}>
              <Input
                value={companyName}
                onChange={(e) => setCompanyName(e?.target?.value)}
                size="large"
                placeholder="Enter Company"
                prefix={<UserOutlined />}
              />
            </div>
            {/* <div>{companyNameError}</div> */}
          </div>

          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Email</div>
            <div className={styles.loginInput}>
              <Input
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                type="email"
                size="large"
                placeholder="Enter Email"
                prefix={<MailOutlined />}
              />
              {/* <div>{emailError}</div> */}
            </div>
          </div>

          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Password</div>
            <div className={styles.loginInput}>
              <Input
                value={password}
                onChange={(e) => setPassword(e?.target?.value)}
                type="password"
                size="large"
                placeholder="Enter Password"
                prefix={<LockOutlined />}
              />
              {/* <div>{passwordError}</div> */}
            </div>
          </div>


          <div className="f-d-flex f-justify-center f-py-24">
            <Button
              size="large"
              className={styles.goalsButton}
              onClick={toggleButton}
            >
              <CalendarOutlined />
              <span className={styles.goalsButtonText}>
                Goals
              </span>
              <EditOutlined />
              
            </Button>
          </div>

          {showButton && 
          <>
          <div className={styles.loginInputContainer}>
            <div className={styles.loginInput} style={{marginLeft:"4vw", marginRight:"2vw"}}>
              <Input
                value={goalFirst}
                onChange={(e) => setGoalFirst(e?.target?.value)}
                size="large"
                placeholder="Write goal here"
              />
            </div>
          </div>

          <div className={styles.loginInputContainer}>
            <div className={styles.loginInput} style={{marginLeft:"4vw", marginRight:"2vw"}}>
              <Input
                value={goalSecond}
                onChange={(e) => setGoalSecond(e?.target?.value)}
                size="large"
                placeholder="Write goal here"
              />
            </div>
          </div>

          <div className={styles.loginInputContainer}>
            <div className={styles.loginInput} style={{marginLeft:"4vw", marginRight:"2vw"}}>
              <Input
                value={goalThird}
                onChange={(e) => setGoalThird(e?.target?.value)}
                size="large"
                placeholder="Write goal here"
              />
            </div>
          </div>
          </>
          }

          <div className="f-d-flex f-justify-center f-py-24">
            <Button
              type="primary"
              size="large"
              className={styles.saveChangesButton}
              disabled={updateLoading}
              loading={updateLoading}
              onClick={userUpdateProfile}
            >
              <span className={styles.saveChangesButtonText}>
                Save Changes
              </span>
            </Button>
          </div>
        </div>
    </div>
  );
};

export default RightSideLoginComponent;
