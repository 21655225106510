import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import theme from '../../core/theme';
import SideBar from '../Roadmap/SideBar';
import WorkshopInvite from '../Roadmap/WorkshopInvite';
import WorkshopSurvey from '../Roadmap/WorkshopSurvey';
import AvaChat from './AvaChat';
import StartWorkshop from './StartWorkshop';
import Deliverable from './StartWorkshop/Deliverable';
import FAQ from './StartWorkshop/FAQ';
import SchedulePane from './StartWorkshop/SchedulePane';

const Background = styled.div`
background-size: cover;
height: 100%;
width: 100%;
position: fixed;
background-color: ${theme.background};
z-index: -100;
`;

const Container = styled.div`
    display: flex;
    margin: 25px;
    min-height: calc(100vh - 50px);
`
const LeftContainer = styled.div`
    width: 50%;
`

const RightContainer = styled.div`
    width: 48%;
`

const AvaContainer = styled.div`
    width: 55%;
    height: 100%;
`

const VerticalDivider = styled.div`
  background-color: #b9b9b9;
  width: 1px;
  margin: 15px;
`;

export default function Roadmap(props) {
    const location = useLocation();

    const getLeftContainerComponent = () => {
        switch (location?.pathname) {
            case "/office/roadmap/workshop":
                return <StartWorkshop />;
            case "/office/roadmap/workshopinvite":
                return <WorkshopInvite />;
            case "/office/roadmap/workshopsurvey":
                return <WorkshopSurvey />;
            default:
                return <AvaChat />;
        }
    }

    const getRightContainerComponent = () => {
        switch (location?.pathname) {
            case "/office/roadmap/workshop/invite":
                return <SchedulePane location={props.location} />;
            case "/office/roadmap/workshop/infopane/deliverable":
                return <Deliverable location={props.location} />;
            case "/office/roadmap/workshop/infopane/FAQ":
                return <FAQ location={props.location} />;
            default:
                return null;
        }
    }

    const getPageContent = () => {
        if (location?.pathname==="/office/roadmap") {
            return (
                <AvaContainer>
                    <AvaChat/>
                </AvaContainer>
            )
        } else {
            return (
                <>
                    <LeftContainer>
                        { getLeftContainerComponent() }
                    </LeftContainer>
                    <VerticalDivider />
                    <RightContainer>
                        { getRightContainerComponent() }
                    </RightContainer>
                </>
            )
        }  
    }

    return (
        <>
            <Background />
            <Container >
                <SideBar />
                { getPageContent() }
            </Container>
        </>
    )
}
