import { Button, Input } from "antd";
import { apiBirthDate } from "api/account-setup/birthDate";
import { IFormStep } from "interface";
import { useAuth } from "providers/AuthContext";
import { AppContext } from "providers/app-context";
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import logoDark from '../../../../assets/image/logoDark.png';
import ProgressBar from "../../ProgressBar";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
}

const BirthdateForm = ({ currentForm, navigateToFormStep }: IProps) => {
  const { birthdate, setBirthdate } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [birthdateUpdateLoading, setBirthdateUpdateLoading] = useState(false);

  const updateBirthdate = async () => {
    try {
      setBirthdateUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        birthdate: birthdate,
      };
      await apiBirthDate(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setBirthdateUpdateLoading(false);
    }
  };

  return (
    <>
      <img src={logoDark} alt="fixme" style={{height: "200px", marginLeft:"15%"}}/>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.title}> Birthdate </div>
      </div>

      <ProgressBar currentForm="07"/>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.subtitle}> What is your date of birth? </div>
        <div className={styles.instructionText} style={{marginTop:"5px", marginBottom:"15px"}}> We use this information to create your Mastermind Group! </div>
        
        <div className={styles.inputContainer}>
          <Input
            id="date"
            type="date"
            value={birthdate}
            onChange={(e) => setBirthdate(e?.target?.value)}
            // placeholder="MM/DD/YYYY"
          />
        </div>

        <div style={{height: "150px", marginTop: "40px"}}>
        <Button
          type="primary"
          size="large"
          id="signButton"
          name="signButton"
          onClick={updateBirthdate}
          disabled={birthdateUpdateLoading}
          loading={birthdateUpdateLoading}
          style={{height:"70px", width:"50vw", position: "fixed", bottom: "50px", marginLeft:"15%", borderRadius:"18px"}}
        >
        <div className={styles.buttonText}> Next </div>
      </Button> 
      </div>
      </div>
    </>
  );
};

export default BirthdateForm;
