import { createContext, Dispatch, SetStateAction, useState } from "react";

export interface IAppContext {
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  companyStage: string;
  setCompanyStage: Dispatch<SetStateAction<string>>;
  companyIndustry: string;
  setCompanyIndustry: Dispatch<SetStateAction<string>>;
  companySubIndustry: string;
  setCompanySubIndustry: Dispatch<SetStateAction<string>>;
  founderRole: string;
  setFounderRole: Dispatch<SetStateAction<string>>;
  founderGender: string;
  setFounderGender: Dispatch<SetStateAction<string>>;
  founderRace: string;
  setFounderRace: Dispatch<SetStateAction<string>>;
  birthdate: string;
  setBirthdate: Dispatch<SetStateAction<string>>;
  mailingAddress: string;
  setMailingAddress: Dispatch<SetStateAction<string>>;
  mailingAddressCity: string;
  setMailingAddressCity: Dispatch<SetStateAction<string>>;
  mailingAddressState: string;
  setMailingAddressState: Dispatch<SetStateAction<string>>;
  mailingAddressZipcode: string;
  setMailingAddressZipcode: Dispatch<SetStateAction<string>>;
  mailingAddressAptNumber: string;
  setMailingAddressAptNumber: Dispatch<SetStateAction<string>>;
  mailingAddressCountry: string;
  setMailingAddressCountry: Dispatch<SetStateAction<string>>;
  companyRole: string[];
  setCompanyRole: Dispatch<SetStateAction<string[]>>;
  othersCompanyRole: string;
  setOthersCompanyRole: Dispatch<SetStateAction<string>>;
  othersGender: string;
  setOthersGender: Dispatch<SetStateAction<string>>;
  birthDate: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

export const AppContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [companyName, setCompanyName] = useState("");
  const [companyRole, setCompanyRole] = useState<string[]>([]);

  const [companyStage, setCompanyStage] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companySubIndustry, setCompanySubIndustry] = useState("");
  const [founderRole, setFounderRole] = useState("");
  const [founderGender, setFounderGender] = useState("");
  const [founderRace, setFounderRace] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [mailingAddressCity, setMailingAddressCity] = useState("");
  const [mailingAddressState, setMailingAddressState] = useState("");
  const [mailingAddressZipcode, setMailingAddressZipcode] = useState("");
  const [mailingAddressAptNumber, setMailingAddressAptNumber] = useState("");
  const [mailingAddressCountry, setMailingAddressCountry] = useState("");
  
  const [othersCompanyRole, setOthersCompanyRole] = useState("");
  const [othersGender, setOthersGender] = useState("");
  const [birthDate, setBirthDate] = useState("");

  return (
    <AppContext.Provider
      value={{
        companyName,
        companyStage,
        companyIndustry,
        companySubIndustry,
        setCompanyName,
        setCompanyStage,
        setCompanyIndustry,
        setCompanySubIndustry,
        founderRole,
        setFounderRole,
        founderGender,
        setFounderGender,
        founderRace,
        setFounderRace,
        birthdate,
        setBirthdate,
        mailingAddress,
        setMailingAddress,
        mailingAddressCity,
        setMailingAddressCity,
        mailingAddressState,
        setMailingAddressState,
        mailingAddressZipcode,
        setMailingAddressZipcode,
        mailingAddressAptNumber,
        setMailingAddressAptNumber,
        mailingAddressCountry,
        setMailingAddressCountry,
        companyRole,
        setCompanyRole,
        othersCompanyRole,
        setOthersCompanyRole,
        othersGender,
        setOthersGender,
        birthDate,
        setBirthDate
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
