import axios from "axios";

const { REACT_APP_BACKEND_API_URL } = process.env;

export const apiFetchStripeProducts = async () => {
    try {
        const url = `${REACT_APP_BACKEND_API_URL}/stripe/products`;
        const response = await axios.get(url);
        return response?.data;
    } catch (e: any) {
        throw e?.response?.data ? e?.response?.data : e;
    }
};
