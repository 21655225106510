import { Autocomplete, TextField } from "@mui/material";
import { Button, Spin } from "antd";
import { apiGetGroupList } from "api/match-make/apiGetGroupList";
import { updateGroupTimeSchedule } from "api/match-make/updateGroupTimeSchedule";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { toast } from "utils/toast";
import "./index.scss";

const weekDayList = [
    { id: '1', title: 'Monday' },
    { id: '2', title: 'Tuesday' },
    { id: '3', title: 'Wednesday' },
    { id: '4', title: 'Thursday' },
    { id: '5', title: 'Friday' }
];

const timeSlotList = [
    { id: '00:01', title: '00:00 to 01:00' },
    { id: '01:02', title: '01:00 to 02:00' },
    { id: '02:03', title: '02:00 to 03:00' },
    { id: '03:04', title: '03:00 to 04:00' },
    { id: '04:05', title: '04:00 to 05:00' },
    { id: '05:06', title: '05:00 to 06:00' },
    { id: '06:07', title: '06:00 to 07:00' },
    { id: '07:08', title: '07:00 to 08:00' },
    { id: '08:09', title: '08:00 to 09:00' },
    { id: '09:10', title: '09:00 to 10:00' },
    { id: '10:11', title: '10:00 to 11:00' },
    { id: '11:12', title: '11:00 to 12:00' },
    { id: '12:13', title: '12:00 to 13:00' },
    { id: '13:14', title: '13:00 to 14:00' },
    { id: '14:15', title: '14:00 to 15:00' },
    { id: '15:16', title: '15:00 to 16:00' },
    { id: '16:17', title: '16:00 to 17:00' },
    { id: '17:18', title: '17:00 to 18:00' },
    { id: '18:19', title: '18:00 to 19:00' },
    { id: '19:20', title: '19:00 to 20:00' },
    { id: '20:21', title: '20:00 to 21:00' },
    { id: '21:22', title: '21:00 to 22:00' },
    { id: '22:23', title: '22:00 to 23:00' },
    { id: '23:24', title: '23:00 to 24:00' },
];

interface IRoomGroup {
    title: string;
    id: string;
}

const ScheduleVideoMeeting = () => {
    const { userAuthToken } = useAuth()
    const [selectedGroupId, SelectedGroupId] = useState<string | undefined>(undefined);
    const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
    const [selectedTime, setSelectedTime] = useState<string | undefined>(undefined);

    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [isGroupFetching, setIsGroupFetching] = useState(false);
    
    const [roomGroupList, setRoomGroupList] = useState<IRoomGroup[]>([]);

    useEffect(() => {
        fetchGroupList();
    }, []);

    const fetchGroupList = async () => {
        try {
            setIsGroupFetching(true);
            const groupListRes = await apiGetGroupList(userAuthToken!);
            const parsedRoomMeetingObject = Object.entries(groupListRes?.data).map((e: any) => ({ id: e[0], title: e[1].toString() }));
            setRoomGroupList(parsedRoomMeetingObject);
        } catch (e: any) {
            console.log("Error : ", e);
            toast.error(e?.message ?? "Something Went Wrong!");
        } finally {
            setIsGroupFetching(false);
        }
    }

    const scheduleVideoMeetingUpdate = async () => {
        try {
            setIsUpdateLoading(true);
            const roomID = roomGroupList.find((r) => r?.title === selectedGroupId)?.id;
            const weekDay = weekDayList?.find((w) => w?.title === selectedDay)?.id;
            const timeSlot = timeSlotList?.find((t) => t?.title === selectedTime)?.id;
            const payload = { roomID, weekDay, timeSlot };
            await updateGroupTimeSchedule(userAuthToken!, payload);
            toast.success("Time slot for schedule updated successfully!");
        } catch (e: any) {
            console.log("Error : ", e);
            toast.error(e?.message ?? "Something Went Wrong!");
        } finally {
            setIsUpdateLoading(false);
        }
    };

    return (
        <div className="schedule-meeting-page">
            {isGroupFetching ?
                <div className="schedule-meeting-page-loader">
                    <Spin size="large" />
                </div>
                :
                <div className="schedule-meeting-form">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h1>Schedule Video Meeting</h1>
                        <h4>Select meeting group and enter decided day and time slot in below inputs</h4>
                    </div>
                    <h3 className='goal-title'>Select Group</h3>
                    <Autocomplete
                        value={selectedGroupId}
                        id="video-meeting-groups"
                        freeSolo={true}
                        onInputChange={(event, newInputValue) => {
                            SelectedGroupId(newInputValue);
                        }}
                        options={roomGroupList.map((roomGroup) => roomGroup?.title)}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <h3 className='goal-title'>Select Week Day</h3>
                    <Autocomplete
                        value={selectedDay}
                        id="video-meeting-groups"
                        freeSolo={true}
                        onInputChange={(event, newInputValue) => {
                            setSelectedDay(newInputValue);
                        }}
                        options={weekDayList.map((weekDay) => weekDay?.title)}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <h3 className='goal-title'>Select 1 Hour Time Slot</h3>
                    <Autocomplete
                        value={selectedTime}
                        id="video-meeting-groups"
                        freeSolo={true}
                        onInputChange={(event, newInputValue) => {
                            setSelectedTime(newInputValue);
                        }}
                        options={timeSlotList.map((timeSlot) => timeSlot?.title)}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <Button
                        type="primary"
                        size="large"
                        className='meetingScheduleButtonContainer'
                        onClick={scheduleVideoMeetingUpdate}
                        loading={isUpdateLoading}
                        disabled={isUpdateLoading || !selectedGroupId || !selectedDay || !selectedTime}
                    >
                        <span className="postMeetingSurveyText">Schedule</span>
                    </Button>
                </div>
            }
        </div>
    );
}

export default ScheduleVideoMeeting
