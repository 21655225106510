import React from 'react';
import styled from 'styled-components/macro';
import arrow from '../assets/image/arrow_next.png';
import theme from '../core/theme';

const Button = styled.button`
  align-items: center;
  background-color: ${theme.secondary};
  outline: none;
  border-radius: 15px;
  box-shadow: 0 0 10px ${theme.secondary};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-left: auto;
`;

export default function StartButton(props) {
  return (
    <Button {...props}>
      {props.children ? props.children : <img src={arrow} alt={"arrow"} width={30} />}
    </Button>
  )
}